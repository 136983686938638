var _ = require('lodash');
var Config = require('../../Config')
DetailTransform = require ('./DetailTransform');
FeedTransform = require ('./FeedTransform');
var Transforms = function() {

	DetailTransform.setFeedTransform(FeedTransform);
	FeedTransform.setDetailTransform(DetailTransform);
	return({
		DetailTransform: DetailTransform,
		FeedTransform:FeedTransform
	})

}();
module.exports = Transforms;