
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  reportYChange: function(y) {
    AppDispatcher.handleViewAction({
      type: "Y_CHANGE",
      y: y
    });
  }
};