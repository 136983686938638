var _ = require('lodash');
var Config = require('../../Config');

var Interleaver = function() {

	var _moreAvailable = false;
    var _feeds = {
        "fw": {
            offset: 0,
            totalOffset:0,
            type: "work"
        },
        "w": {
            offset: 0,totalOffset:0,
            type: "work"
        },
        "fn": {
            offset: 0,totalOffset:0,
            type: "news"
        },
        "n": {
            offset: 0,totalOffset:0,
            type: "news"
        },
        "fk": {
            offset: 0,totalOffset:0,
            type: "knowledge"
        },
        "k": {
            offset: 0,totalOffset:0,
            type: "knowledge"
        },
        "fbu": {
            offset: 0,totalOffset:0,
            type: "bbdo-u"
        },
        "bu": {
            offset: 0,totalOffset:0,
            type: "bbdo-u"
        },
        "fbk": {
            offset: 0,totalOffset:0,
            type: "bbdo-knows"
        },
        "bk": {
            offset: 0,totalOffset:0,
            type: "bbdo-knows"
        },
        "bbdoworldwide": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
        "thedigitallab": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
        "amv_bbdo": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
        "bbdony": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "clemsmelb": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "colensobbdo": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "almapbbdo": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "ovationbbdo": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "formitasbbdo": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
         "bbdozagreb": {
            offset: 0,totalOffset:0,
            type: "tweet"
        },
        "m": {
            offset: 0,totalOffset:0,
            type: "marquee"
        }
    };
   var  _interleaves = {
        all: {
            col6: {
               order: ["fk", "fw", "w", "w", "fk", "fw", "fn",
							"fn", "w", "w", "fw", "w", "w",
							"w", "fn", "w", "fw", "w", "w", "fk", "w", 'w'],
               // order: ["fk", "fw", "bbdoworldwide","w" ],
                passes: 1,
                hasMarquee: true
            },
            mobile: {
                order: ["fk", "fw", "w", "w", "w", "fw", "fn",
								"fk", "w", "fn", "fw", "w", "w", "w",
								"w", "fw", "w", "w"],
                passes: 2,
                hasMarquee: true
            }
        },
        cannes: {
            col6: {
                order: ["fw", "bbdoworldwide", "w", "w", "fn", "bbdoworldwide",
								"w", "n", "fw", "bbdoworldwide", "w", "w", "w", "fw", "fn", "w",
								"bbdoworldwide", "w", "n", "fw", "w", "bbdoworldwide", "w"],
                passes: 100,
                hasMarquee: true
            },
            mobile: {
                order: ["fw", "fn", "w", "w", "w", "w", "bbdoworldwide", "w",
								"w", "w", "fw", "n", "w", "w", "w", "fw", "w", "w", "w",
								"bbdoworldwide", "w", "w", "w", "fw", "w", "w", "n", "w"],
                passes: 100,
                hasMarquee: true
            }
        },
        canneswork: {
            col6: {
                order: ["fw","w", "w",
								"w", "fw", "w", "w", "w", "fw",  "w",
								"w", "fw", "w", "w"],
                passes: 100,
                hasMarquee: false
            },
            mobile: {
							order: ["fw","w", "w",
							"w", "fw", "w", "w", "w", "fw",  "w",
							"w", "fw", "w", "w"],
                passes: 100,
                hasMarquee: false
            }
        },
        work: {
            col6: {
                order: ["fw", "w", "w", "w", "fw", "w", "w", "w", "w", "w", "w",
								"w", "w", "w", "w", "fw", "w", "w", "w", "w", "w", "fw", "w",
								"w", "w", "w", "w", "w"],
                passes: 5,
                hasMarquee: false
            },
            mobile: {
                order: ["fw", "w", "w", "w", "w", "w", "w"],
                passes: 3,
                hasMarquee: false
            },
        },
        "bbdo-knows": {
            col6: {
                order: ["fbk", "bk", "bk", "bk", "fbk", "bk", "bk", "bk", "bk", "bk", "bk",
                                "bk", "bk", "bk", "bk", "fbk", "bk", "bk", "bk", "bk", "bk", "fbk", "bk",
                                "bk", "bk", "bk", "bk", "bk"],
                passes: 5,
                hasMarquee: false
            },
            mobile: {
                order: ["fbk", "bk", "bk", "bk", "bk", "bk", "bk"],
                passes: 3,
                hasMarquee: false
            },
        },
        "bbdo-u": {
            col6: {
                order: ["fbu", "bu", "bu", "bu", "fbu", "bu", "bu", "bu", "bu", "bu", "bu",
                                "bu", "bu", "bu", "bu", "fbu", "bu", "bu", "bu", "bu", "bu", "fbu", "bu",
                                "bu", "bu", "bu", "bu", "bu"],
                passes: 5,
                hasMarquee: false
            },
            mobile: {
                order: ["fbu", "bu", "bu", "bu", "bu", "bu", "bu"],
                passes: 3,
                hasMarquee: false
            },
        },
        news: {
            col6: {
                order: ["fn", "bbdozagreb", "n", "n", "n", "n",
								"bbdozagreb", "n", "n", "fn", "n", "bbdozagreb",
								"n", "fn", "bbdozagreb", "n", "n", "bbdozagreb", "n", "n", "n", "fn",
								"n", "n", "bbdozagreb", "n"],
                passes: 5,
                hasMarquee: false
            },
            mobile: {
                order: ["fn", "bbdozagreb", "n", "n", "bbdozagreb", "n","fn", "bbdozagreb",
								"n", "n","n", "bbdozagreb"],
                passes: 3,
                hasMarquee: false
            },
        },
        knowledge: {
            col6: {
                order: ["fk", "thedigitallab", "k", "k", "k", "k",
								"thedigitallab", "k", "k", "k", "fk", "thedigitallab", "k", "k",
								"k", "fk", "k", "k", "k", "thedigitallab", "k", "k", "k", "fk",
								"k", "k", "thedigitallab", "k"],
                passes: 5,
                hasMarquee: false
            },
            mobile: {
                order: ["fk", "k", "k", "k", "thedigitallab", "k","fk", "k", "k"
								, "k","k", "thedigitallab"],
                passes: 3,
                hasMarquee: false
            },
        },
    };
    for (var i in _interleaves) {
        if (_interleaves.hasOwnProperty(i)) {
            for (var j in _interleaves[i]) {
                if (_interleaves[i].hasOwnProperty(j)) {
                    var uniqueTemp = _.uniq(_interleaves[i][j].order);
                    _interleaves[i][j].uniques = uniqueTemp.map(function(result) {
                        var countForItem = 0;
                        _interleaves[i][j].order.map(function(inner) {
                            if (result === inner) {
                                countForItem++;
                            }
                        })
                        return {
                            item: result,
                            count: countForItem
                        };
                    })
                }
            }
        }
    }
    resetPerRequestOffsets = function() {
        for (var i in _feeds) {
            _feeds[i].totalOffset += _feeds[i].offset;
            _feeds[i].offset = 0;
           // console.log("resetPerRequests");
            //    console.log(i, _feeds[i]);
        }
        //console.log("feeds", _feeds.fw, _feeds);
    }
    reset = function() {
        for (var i in _feeds) {
            _feeds[i].totalOffset = 0;
            _feeds[i].offset = 0;
            //    console.log(i, _feeds[i]);
        }
        //console.log("feeds", _feeds.fw, _feeds);
    }
    getPattern = function(filter, formFactor) {

         //console.log(filter, formFactor);
         //console.log(_interleaves);
        return (_interleaves[filter][formFactor]);
    };
    getNextItem = function(nodes, typeString) {
        var sublist = _.filter(nodes, "type", typeString);
        if (sublist[0] && sublist[0].list_of_objects.length >= _feeds[typeString].offset) {
            // console.log(sublist, sublist[0], sublist[0].list_of_objects);
            var item = sublist[0].list_of_objects[_feeds[typeString].offset]
                // console.log("ITEM ", _feeds[typeString].offset, typeString, item);
            _feeds[typeString].offset++;
            return item;
        } else {
            return undefined;
        }
    }
    return {
        interleave: function(nodes, offset, filter, formFactor) {

            //if (offset === 0) {
                //  console.clear();

            //}

            //console.log("BACK IN INTERLEAVE");
            filter = filter || "";
            formFactor = formFactor || "col6";
            var pattern = getPattern(filter, formFactor);
            var passes = pattern.passes;
            var newnodes = [];
            if(pattern.hasMarquee && offset == 0){
                var marquee = getNextItem(nodes,'m');
                if(marquee){
                    newnodes.push(marquee);
                }
            }

            for (var i = 0; i < passes; i++) {

                for (var j = 0; j < pattern.order.length; j++) {

                    var nextNode = getNextItem(nodes, pattern.order[j])
                    if (nextNode) {
                        newnodes.push(nextNode);
                    }
                }
            }
            _moreAvailable = false
            nodes.forEach(function(val) {
                if (val.more_available
									&& val.type !== "thedigitallab"
									&& val.type !== "bbdoworldwide") {
                    _moreAvailable = true;
                }
            });

            resetPerRequestOffsets();

            return newnodes;
        },
        constructRequest: function(filter, offset, formFactor, sessionKey) {
            
            //console.log("offset",offset)
            if (offset === 0) {
                reset();
            }

            var pattern = getPattern(filter, formFactor);
            var requestArray = [];
            for (var i = 0; i < pattern.uniques.length; i++) {

                var arr = [pattern.uniques[i].item,
								_feeds[pattern.uniques[i].item].totalOffset,
								pattern.uniques[i].count * pattern.passes];

                if ( (filter === "cannes" || filter === Config.privateCannesString)
									&& pattern.uniques[i].item !== "bbdoworldwide") {
                    arr.push("Cannes 2015");
                } else if ( (filter === "cannes" || filter === Config.privateCannesString)
									&& pattern.uniques[i].item === "bbdoworldwide"){
                    arr.push("CannesLions");
                }
                requestArray.push(arr);
            }

            if (offset == 0 && pattern.hasMarquee) {
                requestArray.push(["m", 0, 1]);
            }

            var requestString = (JSON.stringify(requestArray));
            if(sessionKey.length > 0 && filter === Config.privateCannesString){

                    requestString += '&auth_token=' + sessionKey + "&show_private=true";

                   // console.log('getting private cannes feed', requestString );
                }
            return requestString;
        },
        moreAvailable: function() {

            return _moreAvailable;
        },
        reset: function(){
            reset();
        }
    };
};
module.exports = Interleaver();
