var React = require('react');    
var Config = require('../../Config');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var HeroImage = require('./aboutModules/HeroImage');
var ContentBlockHolder = require('./generalModules/ContentBlockHolder');
var Paragraph = require('./toolboxModules/Paragraph');
var ShareLinks = require('./generalModules/ShareLinks');
var Router = require('react-router');
var CareersStore = require('../stores/CareersStore');
var ContentHolder = require('./generalModules/ContentHolder');
var ContentBlockHolder = require('./generalModules/ContentBlockHolder');
var moment = require('moment');
var ContentConverter = require('../utils/ContentConverter');
var ShareLinks = require('./generalModules/ShareLinks');
var Translator = require('../utils/Translator.js');


//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------


function isMobile() {
    return $(window).width() <= 680;
}

function getState(){
    var id = this.getParams().id;
    return CareersStore.getJob(id)
}

function getSocialObject(){
    return {
        image: this.state.image,
        url: window.location.origin + this.context.router.getCurrentPath(),
        title: "Careers",
        description: this.state.description
    };
}

function getContent(){
    var i18n = Translator.getInstance();

    if (this.state){

        var image = isMobile() ? this.state.mobileImage : this.state.image;
        return (
            <div className="general-content">
                <HeroImage data={image} delay="0.5" />
                <ContentHolder delay="0.7">
                    <h2>{this.state.position}</h2>
                </ContentHolder>
                <div className="summary">
                    <Paragraph data={ContentConverter.textToHtmlNewLines( this.state.description )} delay="0.9" />
                </div>
                <ContentBlockHolder delay="1.1">
                    <div className="job-details">
                        <b>{
                        // TRANSLATORS: Careers page search for careers 
                        i18n.gettext("Job posted: ")
                        }</b> {moment.unix(this.state.postDate).format("MM/DD/YYYY")}
                    </div>
                </ContentBlockHolder>
                <ContentBlockHolder delay="1.3">
                    <div className="job-apply">
                        <a href={"mailto:" + this.state.email} className="btn-basic">{
                        // TRANSLATORS: Careers page search for careers 
                        i18n.gettext("Apply via email")
                        }</a>
                    </div>
                </ContentBlockHolder>
                <ShareLinks data={getSocialObject.call(this)} />
            </div>
        );

    } else {
        return [];
    }
}


//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------


var CareersDetailsPage = React.createClass({

    mixins: [ Router.State ],

    contextTypes: {
        router: React.PropTypes.func
    },

    //----------------------------------------
    //  Life cycle
    //----------------------------------------

    componentDidMount: function(){
        CareersStore.addChangeListener(this.storeChangeHandler);
        BBDOAPIUtils.getCareersData();
    },

    componentWillUnmount: function(){
        CareersStore.removeChangeListener(this.storeChangeHandler);
    },

    render: function(){
        console.log(this.state);
        // Used 'detail-page' to reuse componet styles
        return (
            <div className="career-page-details detail-page nav-space">
                {getContent.call(this)}
            </div>
        );
    },

    //----------------------------------------
    //  Event handlers
    //----------------------------------------

    storeChangeHandler: function(){
        this.setState( getState.call(this) );
    }

});

module.exports = CareersDetailsPage;