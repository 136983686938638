
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  addToken: function(token) {
  	  
    AppDispatcher.handleViewAction({
      type: "ADD_SEARCH_TOKEN",
      token: token
    });
  },
    removeToken: function(token,quiet) {
  	  
    AppDispatcher.handleViewAction({
      type: "REMOVE_SEARCH_TOKEN",
      token: token,
      quiet: quiet
    });
    
  },
  clearTokens: function(quiet){
    AppDispatcher.handleViewAction({
      type: "CLEAR_TOKENS",
      quiet: quiet
    });
  },
  toggleSearch: function(closed){

    AppDispatcher.handleViewAction({

      type: "TOGGLE_SEARCH",
      closed: closed

    });
  },
  
  
};