//------------- MIXIN --------------//
var MainTransitionMixin = {

   
   statics: {
       willTransitionFrom:function(trans,comp,cb){ 
        // TweenLite.to(comp.getDOMNode(),.5,{opacity:0,onComplete:cb});
      //  if(comp.doOutro !== undefined){
       //     comp.doOutro(cb);
       // }    else {
            TweenLite.to(comp.getDOMNode(),.25,{opacity:0,onComplete:cb});
      //  }
       }
   }

}

module.exports = MainTransitionMixin;