 /** @jsx React.DOM */

var React = require('react');
var Router = require('react-router');
var ShareActionCreator = require('../../actions/ShareActionCreator');
var Link = Router.Link;
var Translator = require('../../utils/Translator.js');


 var MobileList = React.createClass({
  getInitialState: function() {
    return {
      activeRef: ""
    };
  },
  componentDidUpdate : function(prevProps, prevState){
    if(!prevProps.currentMenuState && this.props.currentMenuState){
      this.setState({
        activeRef: ''
      });
    }
  },
  handleClick: function(link){
    this.setState({
      activeRef: link
    });
    ShareActionCreator.setMenuState();
  },
  render: function(){
    var i18n = Translator.getInstance();
    var iconClass = "icon";
    var iconActiveClass = iconClass + " icon--active";
    var menuLinks = <div></div>;
    var self = this;
    var menuArray = 
      [
        {
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('home'),
          type: 'home',
          link:"/"
        },
        {
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('work'),
          type: 'work',
          link: "work"
        },
        {
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('news'),
          type: 'news',
          link: "news"
        },
        /*{
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('knowledge'),
          type: 'knowledge',
          link: "knowledge"
        },*/
        {
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('about'),
          type: 'about',
          link: "about"
        },
        /*{
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('people'),
          type: 'people',
          link: "people"
        },
        {
          label: i18n.gettext('careers'),
          type: "careers",
          link: "careers",
        },*/
        {
          // TRANSLATORS: Mobile menu item
          label: i18n.gettext('contact'),
          type: 'contact',
          link: "contact",
        },
      ];

    if(this.props.menuType === "share"){
        menuArray = [
          {
            type: "facebook",
            link: "work"
          },
          {
            type: "twitter",
            link: "news"
          },
          {
            type: "linkedin",
            link: "knowledge"
          },
          {
            type: "email",
            link: "knowledge"
          },
          {
            type: "url",
            link: "knowledge"
          },


        ]
    }
      menuLinks = menuArray.map(function(result,i){
            if(result.externalLink != undefined){
              return(
                <li key={i}>
                  <a target ="_blank" href={result.externalLink}>
                    <span id={"mobile-" + result.type} className={self.state.activeRef === result.type ? iconActiveClass : iconClass}></span>
                    <span>{result.label}</span>
                  </a>
                </li>
                )
            }
            else{
              return(
                <li key={i}>
                  <Link to={result.link} onClick={self.handleClick.bind(null, result.type)} params={{lang: Config.lang}}>
                    <span id={"mobile-" + result.type} className={self.state.activeRef === result.type ? iconActiveClass : iconClass}></span>
                    <span>{result.label}</span>
                  </Link>
                </li>
                )
            }
      });
    return (
      <ul>{menuLinks}</ul>
    );
  }
 });

 module.exports = MobileList;
