/** @jsx React.DOM */
var React = require('react');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var Config = require('../../Config');
var ContactStore = require('../stores/ContactStore');
var ShareLinks = require('./generalModules/ShareLinks');
var Router = require('react-router');

function getDatafromStore() {
  return ContactStore.get();
}


function getSocialObject(){
    return {
        url: window.location.origin + this.context.router.getCurrentPath(),
        description: this.state.data[Config.lang].title
    };
}

var Contact = React.createClass({

    mixins: [ require('./mixins/MainTransitionMixin'), Router.State ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState : function (){

      return{
            data: []
          }
     },
    // componentWillMount : function(){
    // },
    componentDidMount: function(){
      ContactStore.addChangeListener(this._onChange);
      BBDOAPIUtils.getContactData();
    },

    componentWillUnmount: function(){
      ContactStore.removeChangeListener(this._onChange);
    },


    render: function(){

      if (this.state.data[Config.lang]) {//Check it data is empty
        var emails = this.state.data[Config.lang].list_of_emails.map(function(email, key) {
          return <p key={key}><strong>{email.label}:</strong>{email.name.length ? <br /> : null}{email.name}<br />{email.email}</p>;
        });

        return (
            <div className="nav-space">
                <section className="locations">
                    <div dangerouslySetInnerHTML={{__html: this.state.data.map_url.replace(/width="\d+"/, 'width="100%"')}}></div>


                    <div className="content">
                      <h1>{this.state.data[Config.lang].title}</h1>
                      <p>{this.state.data[Config.lang].address}</p>
                      <div className="emails">
                        {emails}
                      </div>
                      <ShareLinks data={getSocialObject.call(this)} />
                    </div>
                </section>
                
            </div>
        );
      } else {
        return (<div></div>);
      }
    },
    _onChange: function() {

      this.setState({
        data :  getDatafromStore()
      });
    }
    
});

module.exports = Contact;
