var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ =require("lodash");

var CHANGE_EVENT = 'change';
var name="ContactStore";
var _nodes = [];
var _data = {};
var ContactStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  contentIsLoaded: function(){
    return _nodes != [];
  },
  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },


  get: function() {


    return _data;
  }
});


ContactStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;


  switch(action.type) {

    case "RECEIVE_CONTACT_DATA":

      _data = action.data.data.contacts[0];
      ContactStore.emitChange();
      break;
      
    default:
      // do nothing
  }

});

module.exports = ContactStore;
