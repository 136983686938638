var React = require('react');    
var Config = require('../../Config');
var PeopleActionCreator = require('../actions/PeopleActionCreator');
var PeopleStore = require('../stores/PeopleStore');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var HeroImage = require('./aboutModules/HeroImage');
var ContentBlockHolder = require('./generalModules/ContentBlockHolder');
var Paragraph = require('./toolboxModules/Paragraph');
var ShareLinks = require('./generalModules/ShareLinks');
var PeopleGrid = require('./PeopleGrid');
var ContentConverter = require('../utils/ContentConverter');

//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------


function getState(){
    return PeopleStore.getData();
}

function isMobile() {
    return $(window).width() <= 680;
}

/*
 * Checks if required fields exist and have data
 */
function isPageDataValid(data) {
    return !! data && !! data.image;
}

function getGeneralContent(){
    var hideClass = this.state.active ? ' hidden-content' : '';
    var image = isMobile() ? this.state.mobileImage : this.state.image;
            /* Removed until further notice 
            <ContentBlockHolder delay="0.6">
                <span className="page-title">People</span>
            </ContentBlockHolder>*/
    return (
        <div className={"general-content" + hideClass}>
            <HeroImage data={image} delay="0.5" />
            <div className="offset-top-20">
                <Paragraph data={ContentConverter.textToHtmlNewLines( this.state.summary )} delay="0.7" />
            </div>
        </div>
    );
}

function getDetailContent(){

    var hideClass = '';
    var person = this.state.active;
    if (!person){
      if (this.state.persons.length) {
        person = this.state.persons[0];  
      } else {
        return [];
      }
      hideClass = ' hidden-content';
    }
    var heroImage = isMobile() ? person.mobileHeroImage : person.heroImage; 
    return (
        <div className={"detail-content" + hideClass}>
            <HeroImage data={heroImage} delay="0.5" />
            <ContentBlockHolder delay="0.6">
                <span className="person-name">{person.name}</span>
                <span className="person-position">{person.position}</span>
            </ContentBlockHolder>
            <Paragraph data={ContentConverter.textToHtmlNewLines(person.summary)} delay="0.7" />
            <span className="btn-close" onClick={this.btnCloseClickHandler}></span>
        </div>
    );
}

function getSocialObject(){
    return {
        image: this.state.image,
        url: window.location.origin + this.context.router.getCurrentPath(),
        title: "People",
        description: this.state.summary
    };
}

function getContent(){
    if ( isPageDataValid(this.state) ){

        return (
            <div>
                {getGeneralContent.call(this)}
                {getDetailContent.call(this)}
                <PeopleGrid data={this.state.persons} delay="0.8" />
                <ShareLinks data={getSocialObject.call(this)} />
            </div>
        );

    } else {
        return [];
    }
}


//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------


var PeoplePage = React.createClass({

      contextTypes: {
        router: React.PropTypes.func
      },

    //----------------------------------------
    //  Life cycle
    //----------------------------------------

    componentDidMount: function(){
    	PeopleStore.addChangeListener(this.storeChangeHandler);
    	BBDOAPIUtils.getPeopleData();
    },

    componentWillUnmount: function(){
    	PeopleStore.removeChangeListener(this.storeChangeHandler);
    },

	render: function(){
        // Used 'detail-page' to reuse componet styles
		return (
			<div className="people-page detail-page nav-space">
				{getContent.call(this)}
			</div>
		);
	},

	//----------------------------------------
    //  Event handlers
    //----------------------------------------

    storeChangeHandler: function(){
        this.setState( getState() );
    },

    btnCloseClickHandler: function(){
        PeopleActionCreator.hidePersonDetails(this.state.active.id);
        $('html, body').animate({scrollTop: '0px'}, 500);
    }

});

module.exports = PeoplePage;