var translationsData = {
    'en' : require('../translations/json/en.json'),
    'hr': require('../translations/json/hr.json')
};

var envConstants = {
    'production': {
        'apiUrl': 'https://api.bbdo.com',
        'cdnUrl': 'https://cdn.bbdo.com'
    },
    'staging': {
        'apiUrl': 'https://api-staging.bbdo.com',
        'cdnUrl': 'https://cdn-staging.bbdo.com'
    },
    'develop': {
        'apiUrl': 'http://bbdo-api.dev.provisionsofia.com',
        'cdnUrl': 'http://bbdo-api.dev.provisionsofia.com/cdn'
    }
};
var environment = 'develop';

var Config = {

    office: 'p_zgr',

    lang: 'en',

    availableLanguages: ["en", "hr"],

    siteTitle: 'BBDO Zagreb',

    translationsData: translationsData,

    updatePaths: function(lang, office) {
        this.lang = lang;
        if (office) {
            this.office = office;
        }
        this.buildPaths();
    },

    updateSiteTitle: function(value){
        this.siteTitle = value;
    },

    buildPaths: function() {
        this.apiDetail = this.getApiDetail();
        this.apiFeeds = this.getApiFeeds();
        this.apiSearch = this.getApiSearch();
        this.apiTagsURL = this.getApiTagsURL();
        this.aboutdetailfeed = this.getAboutdetailfeed();
        this.cannesAwardCounterFeed = this.getCannesAwardCounterFeed();
        this.peopleFeed = this.getPeopleFeed()
        this.contactFeed = this.getContactFeed();
        this.careersFeed = this.getCareersFeed();
        this.departments = this.getDepartments();
    },


    //----------------------------------------
    //  Endpoint urls
    //----------------------------------------

    getApiDetail: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/posts/show.json?office=" + office + "&language=" + language + "&id="
    },

    getApiFeeds: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/feeds/show.json?office=" + office + "&language=" + language + "&feeds=";
    },

    getApiSearch: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/search/show.json?office=" + office + "&language=" + language + "&order=1&limit=10000&offset=0";
    },

    getApiTagsURL: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/offices/show.json?office=" + office + "&language=" + language;
    },

    getAboutdetailfeed: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/about/show.json?office=" + office + "&language=" + language;
    },

    getCannesAwardCounterFeed: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/cannes2015/show.json?office=" + office + "&language=" + language;
    },

    getPeopleFeed: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/people/show.json?office=" + office + "&language=" + language;
    },

    getContactFeed: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/contacts/show.json?office=" + office + "&language=" + language;
    },

    getCareersFeed: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/careers/show.json?office=" + office + "&language=" + language;
    },

    getDepartments: function(office, language){
        office = office || this.office;
        language = language || this.lang;
        return "/2.0/departments/show.json?office=" + office + "&language=" + language;
    },

    msieversion: function() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer, return version number

            var retval = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));

            if (isNaN(retval)) {
                retval = 10;
            }
            return (retval);
        } else { // If another browser, return 0
            return ('otherbrowser');
        }
        return false;
    },
    stripTags: function(input, allowed) {
        if (!input) {
            input = '';
        }
        allowed = (((allowed || "<b><i><a><br><sup>") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
        return input.replace(tags, function($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    },
    CdnBaseURL: envConstants[environment].cdnUrl,
    apiBaseURL: envConstants[environment].apiUrl,
    proxy: "/proxies/proxy.php?url=",
    source: "/api/shim/shim.json",
    apiDetail: "/2.0/posts/show.json?office=ww&language=en&id=",
    apiFeeds: "/2.0/feeds/show.json?office=ww&language=en&feeds=",
    apiSearch: "/2.0/search/show.json?office=ww&language=en&order=1&limit=10000&offset=0",
    apiTagsURL: "/2.0/offices/show.json?language=en&name=ww",
    aboutdetailfeed: "/2.0/about/show.json?office=ww&language=en",
    locationfeed: "/proxies/proxy.php?url=http://agencyprofiles.bbdo.com/networkxml/agprofilesdata.json",
    cannesAwardCounterFeed: "/2.0/cannes2015/show.json?office=ww&language=en",
    cannesfeed: "/api/shim/cannesfeed.json",
    workdetailfeed: "/api/shim/workDetailFromAPI.json",
    knowledgedetailfeed: "/api/shim/knowledgeDetail.json",
    newsdetailfeed: "/api/shim/newsDetail.json",
    clientsdetailfeed: "/api/shim/clientsDetail.json",
    placeholder: {
        "16x9": "/cdn/content_images/placeholders/placeholder_16x9.jpg",
        "1x1": "/cdn/content_images/placeholders/placeholder_1x1.jpg",
        "3x2": "/cdn/content_images/placeholders/placeholder_3x2.jpg",
        "2x1": "/cdn/content_images/placeholders/placeholder_2x1.jpg",
        "1x2": "/cdn/content_images/placeholders/placeholder_1x2.jpg",
        "3x1_large": "/cdn/content_images/placeholders/placeholder_3x1_large.jpg",
        "3x1_large": "/cdn/content_images/placeholders/placeholder_3x1_small.jpg",
    },
    breakPoints: [{
        size: 0,
        cells: 3
    }, {
        size: 768,
        cells: 4
    }, {
        size: 960,
        cells: 6
    }, {
        size: 1280,
        cells: 6
    }],
    awards: {
        awardsTag: "Cannes 2015",
        awardsFilters: ["Agency of the Year", "Network of the Year", "Grand Prix", "Glass", "Innovation", "Gold", "Silver", "Bronze"], // removed "Titanium",
        categoryFilters: ["Branded Content & Entertainment", "Cyber", "Design", "Direct", "Film", "Film Craft", "Glass", "Health & Wellness", "Media", "Mobile", "Outdoor", "PR", "Press", "Product Design", "Promo & Activation", "Radio"]
    },
    /*modules: {
        aboutContent: require('./reactModules/components/aboutModules/AboutContent.js'),
        clientsContent: require('./reactModules/components/clientModules/ClientsContent.js'),
        knowledgeContent: require('./reactModules/components/content/KnowledgeContent.js'),
        workContent: require('./reactModules/components/content/WorkContent.js'),
        newsContent: require('./reactModules/components/content/NewsContent.js'),
    },*/
    cssMargin: 10,
    mobileBreak: 1024,
    ratio: (1 / 12.83), // master tile Ems.,
    feedItemsPerLoad: 20,
    mobileAgent: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    viewport: window.innerHeight,
    viewportMidTop: window.innerHeight * 0,
    viewportMidBottom: window.innerHeight * .5,
    viewportWidth: window.innerWidth,
    privateCannesString: "canneswork"
}
module.exports = Config;