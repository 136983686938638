var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var ServerConstants = require('../constants/ServerConstants');
var PeopleConstants = require('../constants/PeopleConstants');
var _ = require("lodash");

//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

var CHANGE_EVENT = 'change';
var _data = {};


//----------------------------------------
//  Methods
//----------------------------------------


function receiveData(data){
    _data = data;
}

function showDetailsFor(id){
    var target = _.find(_data.persons, _.matchesProperty('id', id));
    if (target) {
        resetDetails();
        target.active = true;
        _data.active = target;
    }
}

function resetDetails(){
    _data.active = null;
    _.forEach(_data.persons, function(value){
        value.active = false;
    });
}

//--------------------------------------------------------------------------
//
//  Public
//
//--------------------------------------------------------------------------

var PeopleStore = assign({}, EventEmitter.prototype, {


    //----------------------------------------
    //  Event handling
    //----------------------------------------

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    /**
     * @param {function} callback
     */
    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener)
    },

    //----------------------------------------
    //  Data operations
    //----------------------------------------

    getData: function(){
        return _.cloneDeep(_data);
    }


});

PeopleStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {

        case ServerConstants.RECEIVE_PEOPLE_DATA: {
            receiveData(action.data);
            PeopleStore.emitChange();
            break;
        }

        case PeopleConstants.SHOW_DETAILS: {
            showDetailsFor(action.id);
            PeopleStore.emitChange();
            break;
        }

        case PeopleConstants.HIDE_DETAILS: {
            resetDetails();
            PeopleStore.emitChange();
            break;
        }
        

        default:
            // do nothing
    }

});

module.exports = PeopleStore;