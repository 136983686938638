var React = require('react');    
var _ = require('lodash');
var Config = require('../../Config');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var HeroImage = require('./aboutModules/HeroImage');
var ContentBlockHolder = require('./generalModules/ContentBlockHolder');
var Paragraph = require('./toolboxModules/Paragraph');
var ShareLinks = require('./generalModules/ShareLinks');
var CareersStore = require('../stores/CareersStore');
var DepartmentsStore = require('../stores/DepartmentsStore');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var HeroImage = require('./aboutModules/HeroImage');
var ContentBlockHolder = require('./generalModules/ContentBlockHolder');
var ContentHolder = require('./generalModules/ContentHolder');
var Paragraph = require('./toolboxModules/Paragraph');
var ShareLinks = require('./generalModules/ShareLinks');
var CareersActionCreator = require('../actions/CareersActionCreator');
var Paragraph = require('./toolboxModules/Paragraph');
var Router = require('react-router');
var Link = Router.Link;
var ContentConverter = require('../utils/ContentConverter');
var ShareLinks = require('./generalModules/ShareLinks');
var Translator = require('../utils/Translator.js');


//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

function isMobile() {
    return $(window).width() <= 680;
}

function getState(){
    return _.extend({},
                    CareersStore.getData(),
                    { departments: DepartmentsStore.getData() }
    );
}

function getSocialObject(){
    return {
        image: this.state.image,
        url: window.location.origin + this.context.router.getCurrentPath(),
        title: "Careers",
        description: this.state.summary
    };
}


function getSearchBar(){
    var i18n = Translator.getInstance();
    var searchValue = this.state && this.state.searchValue ? this.state.searchValue : ""; 
    return (
        <ContentHolder delay="0.9">
            <div className="search-bar">
                <span className="search-icon"></span>
                <input type="text" 
                       ref="searchFilter"
                       placeholder= {
                        // TRANSLATORS: Careers page search for careers 
                        i18n.gettext("Search careers")
                        }
                       value={searchValue}
                       onChange={this.searchFieldChangeHandler} />
            </div>
        </ContentHolder>
    );
}

function getFilterBar(){
    var i18n = Translator.getInstance();
    var all = i18n.gettext('All');
    if (this.state.departments){ // this.state.departments
        var filters = _.map( this.state.departments );
        if (filters && filters.length){ // if it's array
            filters.unshift(all);
        }

        var items = _.map(filters, function(value, index){
            var id = (value == all) ? "" : value;
            var isActive  = (this.state.activeFilter == id);

            return (
                <li key={index}
                    onClick={this.btFilterItemClickHandler}
                    data-id={id}
                    className={isActive ? 'active' : ''}>
                        <span>{value}</span>
                </li>
            );
        }.bind(this));

        return (
            <ContentHolder delay="1.1">
                <div className="filter-bar">
                    <span className="label">
                            {
                                // TRANSLATORS: Careers page filter 
                                i18n.gettext("Filter department:")
                            }
                        </span>
                    <ul className="filter">
                        {items}
                    </ul>
                </div>
            </ContentHolder>
        );
    } else {
        return [];
    }
}

function getMobileFilterBar(){
    var i18n = Translator.getInstance();
    if (this.state.departments){ // this.state.departments
        // TODO: Rework to support key => value type of object for departments
        var filters = _.map( this.state.departments );
        if (filters && filters.length){ // if it's array
            filters.unshift("All");
        }

        var lastActive;
        var items = _.map(filters, function(value, index){
            var isActive  = (this.state.activeFilter && this.state.activeFilter == value)
                            || (this.state.activeFilter == "" && value == "All");
            if (isActive) {
                lastActive = value;
            }
            return (
                <li key={index}
                    onClick={this.mobileBtFilterItemClickHandler}
                    data-id={value}
                    className={isActive ? 'active' : ''}>
                        <span>{value}</span>
                </li>
            );
        }.bind(this));

        return (
            <ContentHolder delay="1.1">
                <div className="filter-bar-mobile" ref="mobileFilter">
                    <div className="label" onClick={this.mobileFilterClickHandler}> 
                        <span className="title">{
                            // TRANSLATORS: Careers page filter                   
                            i18n.gettext("Filter department:")
                        }</span> {lastActive}
                    </div>
                    <ul className="filter">
                        {items}
                    </ul>
                    <div className="controls">
                        <span className="btn-close" onClick={this.mobileFilterCloseClickHandler}></span>
                    </div>
                </div>
            </ContentHolder>
        );
    } else {
        return [];
    }
}

function getItems(){
    var i18n = Translator.getInstance();
    if (this.state.jobs){

        var lastShownIndex = _.findLastIndex(this.state.jobs, function(value){
            return this.state.activeJobs.indexOf(value.id) >= 0;
        }.bind(this));

        var items = _.map(this.state.jobs, function(value, index){
            var isActive = this.state.activeJobs.indexOf(value.id) >= 0;
            var lastShown = lastShownIndex == index ? " last-visible" : "";
            var descLenght = value.description.length;
            var description = value.description.substring(0, 250) + ( descLenght > 250 ? "..." : "" );
            description = ContentConverter.textToHtmlNewLines( description );
            return (
                <ContentHolder key={index} delay="1.3" className={"job-container" + (isActive ? " visible" : " hidden")}>
                    <div className={"job-item" + (isActive ? "" : " hidden") + lastShown}>
                        <h2>
                            <Link to="careers-details" params={{lang: Config.lang, id: value.id}}>{value.position}</Link>
                        </h2>
                        <Paragraph data={description} />
                    </div>
                </ContentHolder>
            );
        }.bind(this));

        var noResults = lastShownIndex >= 0 ? "" : (
            <Paragraph data={
                    // TRANSLATORS: Careers page search results 
                    i18n.gettext("No results found. Please enter another search term.")
                } delay="0.7" />
        );

        return (
            <div className="jobs">
                {items}
                {noResults}
            </div>
        );
    } else {
        return [];
    }
}

function getGeneralContent(){
    if (!this.state) return [];
    var image = isMobile() ? this.state.mobileImage : this.state.image;
    return (
        <div className="general-content">
            <HeroImage data={image} delay="0.5" />
            <div className="summary">
                <Paragraph data={ ContentConverter.textToHtmlNewLines(this.state.summary) } delay="0.7" />
            </div>
            {getSearchBar.call(this)}
            {getFilterBar.call(this)}
            {getMobileFilterBar.call(this)}
            {getItems.call(this)}
            <ShareLinks data={getSocialObject.call(this)} />
        </div>
    );
}


//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------


var CareersPage = React.createClass({

      contextTypes: {
        router: React.PropTypes.func
      },

    //----------------------------------------
    //  Life cycle
    //----------------------------------------

    componentDidMount: function(){
        CareersStore.addChangeListener(this.storeChangeHandler);
        DepartmentsStore.addChangeListener(this.storeChangeHandler);
        BBDOAPIUtils.getCareersData();
        BBDOAPIUtils.getDepartmentsData();

        //this.searchFieldChangeHandler = _.debounce(this.searchFieldChangeHandler, 500);
    },

    componentWillUnmount: function(){
        CareersStore.removeChangeListener(this.storeChangeHandler);
        DepartmentsStore.removeChangeListener(this.storeChangeHandler);
    },

	render: function(){
        // Used 'detail-page' to reuse componet styles
        
		return (
			<div className="career-page detail-page nav-space">
				 {getGeneralContent.call(this)}
			</div>
		);
	},

	//----------------------------------------
    //  Event handlers
    //----------------------------------------

    storeChangeHandler: function(){
        this.setState( getState() );
    },

    btFilterItemClickHandler: function(event){
        var filter = $(event.currentTarget).data('id');
        CareersActionCreator.selectFilter(filter);
    },

    mobileBtFilterItemClickHandler: function(event) {
        var filter = $(event.currentTarget).data('id');
        CareersActionCreator.selectFilter(filter);
        var filter = this.refs.mobileFilter.getDOMNode();
        $(filter).removeClass('active');
    },

    searchFieldChangeHandler: function(event){
        try {
            var value = this.refs.searchFilter.getDOMNode().value;
            CareersActionCreator.updateSearch(value);
        } catch(e) {}

    },

    mobileFilterClickHandler: function(event){
        var filter = this.refs.mobileFilter.getDOMNode();
        $(filter).addClass('active');
    },

    mobileFilterCloseClickHandler: function(event){
        var filter = this.refs.mobileFilter.getDOMNode();
        $(filter).removeClass('active');

    }

});

module.exports = CareersPage;