
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  load: function(event) {
    AppDispatcher.handleViewAction({
      type: "LOAD_MORE_CONTENT"
    });
  }
};
