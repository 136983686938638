/** @jsx React.DOM */
var React = require('react');
var Video = require('react-video');

var EmbedVideo = React.createClass({
  render: function(){
    var video = <div></div>;
    
    // if(this.props.embed.indexOf("youtube") > -1 && this.props.embed.indexOf("v=") > -1) {
    //   var _onError = function(err) {
    //       console.log(err);
    //     };
    //   var videoId = this.props.embed.split('v=')[1];
    //   var ampersandPosition = videoId.indexOf('&');
      
    //   if(ampersandPosition != -1) {
    //     videoId = videoId.substring(0, ampersandPosition);
    //   }
    //   video = <Video onError={_onError} from='youtube' videoId={videoId} /> 
    
    // }else if(this.props.embed.indexOf("youtu.be") > -1){
    //   var videoId = this.props.embed.split("/")[3]
      
    //   video = <Video onError={_onError} from='youtube' videoId={videoId} />
    // }
    //<iframe src={this.props.src } width={ this.props.width } height={ this.props.height } frameBorder={ 0 }></iframe>
    return(
      <div className="embed-video">
        <iframe src={this.props.embed } width={ 500 } height={ 400 } frameBorder={ 0 }></iframe>
      </div>
    );
  }
});
module.exports = EmbedVideo;