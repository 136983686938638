var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var FeedTransform = require('../transforms/FeedTransform');
var Interleaver = require('../transforms/Interleaver');
var _ = require("lodash");
var CHANGE_EVENT = 'change';
var TOKEN_CHANGE_EVENT = 'token_change';
var CLOSED_CHANGE_EVENT = 'close_change';
var BBDOAPIUtils = require('../utils/BBDOAPIUtils.js');
var _selected = -1;
var _filter = "all";
var _loading = false;
var _noNodes = false;
var _offset = 0;
var _sort = "";
var _originalNodes = [];
var _sortedNodes = [];
var _tokens = [];
var _closed = true;
var _quiet = false;

var SearchStore = assign({}, EventEmitter.prototype, {
    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },
    emitTokenChange: function(){
        
        this.emit(TOKEN_CHANGE_EVENT);
    },
    emitClosedChange: function(){
      this.emit(CLOSED_CHANGE_EVENT);  
    },
    getNodes: function() {

        if (_originalNodes.length === 0 && !_loading && !_noNodes) {
            this.getNewNodes();
        }
        return _originalNodes;
    },
    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener);
    },
    addTokenChangeListener: function(callback) {
        this.on(TOKEN_CHANGE_EVENT, callback);
    },
    removeTokenChangeListener: function(listener) {
        this.removeListener(TOKEN_CHANGE_EVENT, listener);
    },
    addSearchClosedListener: function(listener){
        this.on(CLOSED_CHANGE_EVENT,listener);
    },

    removeSearchClosedListener: function(listener){
        this.removeListener(CLOSED_CHANGE_EVENT,listener);
    },
    get: function(id) {
        return _originalNodes[id];
    },
    constructRequest: function(tokens){
        var requestString= '';
     
        tokens.map(function(result , i){
            requestString += "&query[]=" + "\"" + encodeURIComponent(result) + "\"";
        });
        return requestString;
       // var urlRequest = Config.apiBaseURL + Config.apiSearch + 
    },
    getNewNodes: function() {

        _originalNodes = [];
        _noNodes = false;
        var splitTokens;
        if(_tokens.length >= 0){
            splitTokens = _tokens.slice();
            _filter = "all"
            
            finalTokens = _.filter(splitTokens,function(result){
                var isRealToken;
                isRealToken = (result !== "news" && result !== "knowledge" && result !== "work");
                if (!isRealToken) { _filter = result };
                return isRealToken;
            })
            
            var urlRequest = Config.apiBaseURL + Config.apiSearch + this.constructRequest(finalTokens);//TODO: add mobile.
            BBDOAPIUtils.getSearchNodes(urlRequest);
            _noNodes = true;
            _loading = true;
        } else {

        }
    },
    getSelected: function() {
        return _selected;
    },

    addSearchToken: function(token){
        if (_tokens.indexOf(token) != -1) {
            return;
        }
        _tokens.push(token);
    },
    removeSearchToken: function(token){
        var index = _tokens.indexOf(token);
        if (index == -1) {
          return;
        }
        _tokens.splice(index, 1);
        return _tokens;
    },
    clearTokens: function(token){

        _tokens = [];
        
    },
    getTokens: function(){
        return _tokens;
    },
    getQuiet: function(){
        return _quiet;
    },
    getClosed: function(){
        return _closed;
    }


});
SearchStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {
        case "RECEIVE_SEARCH_NODES":
            //console.log(action);
           if(_filter == "work" || _filter == "news" || _filter == "knowledge"){
            
                _originalNodes = _.filter(action.rawNodes.data.list_of_mixed_search_results,{type:_filter});
            } else if(_filter === "Cannes 2015") {
            
                _originalNodes = _.filter(action.rawNodes.data.list_of_mixed_search_results,function(result){
                    var retval = _.includes(result[Config.lang].list_of_tags,"Cannes 2015");
                    //console.log(retval)
                    return retval;
                });

            } else {
            
              _originalNodes = action.rawNodes.data.list_of_mixed_search_results;  
            }
            //_originalNodes = _.uniq(_originalNodes,'id');

            
            _originalNodes = FeedTransform.createGridObjects(_originalNodes,_filter);
            _loading = false;
            _noNodes = _originalNodes.length > 0 ? false : true;
            
            SearchStore.emitChange();
            break;
        case "ADD_SEARCH_TOKEN":
            SearchStore.addSearchToken(action.token);
            _quiet = false;
            SearchStore.emitTokenChange();
            
            SearchStore.getNewNodes();

            break;
        case "REMOVE_SEARCH_TOKEN":
        
        _quiet = action.quiet;
            SearchStore.removeSearchToken(action.token);
            
            
            if(_tokens.length > 0){
                SearchStore.getNewNodes();
            
            } else {
                _originalNodes = [];
                SearchStore.emitChange();
            }
            SearchStore.emitTokenChange();

            break;
        case "CLEAR_TOKENS":
            _quiet = action.quiet;
            
            SearchStore.clearTokens();
            SearchStore.emitTokenChange();
            
            break;
        case "TOGGLE_SEARCH":

            _closed = action.closed;
            SearchStore.emitClosedChange();
        default:
            // do nothing
    }
});
module.exports = SearchStore;
