
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  selectRegion: function(n) {
    AppDispatcher.handleViewAction({
      type: "SELECT_REGION",
      regionIndex: n
    });
  }, 
  filterLocations: function(filter){
  	AppDispatcher.handleViewAction({
      type: "FILTER_LOCATIONS",
      locationTerm : filter
    });
  }
};