Config = require('../../../Config');

//------------- MIXIN --------------//
var TileMixin = {

	getTileStyles: function(){
		return ({
			width: this.calculateNBox(this.state.arWidth,this.props.tileUnit) + "px",
	        height: this.calculateNBox(this.state.arHeight,this.props.tileUnit) + "px",
	        fontSize:  (this.props.tileUnit*Config.ratio)/16 + "em"
        });
        
	},
 /*   shouldComponentUpdate: function(prevProps,prevState){
        return prevProps.tileUnit !== this.props.tileUnit || this.state.hoverState !== prevState.hoverState;
    },*/
    calculateNBox: function(n,tileUnit){


    	var calc = (n * tileUnit) + ((n-1) * Config.cssMargin);
    	
    	return calc;
    },
    getHeightWithActualRatio: function(){
    	return ~~(this.calculateNBox(this.state.arWidth,this.props.tileUnit) * .66666) + 'px'; // just for 3:2
    }
    
}
//------------- /MIXIN --------------//

module.exports = TileMixin;