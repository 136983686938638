var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils.js');
var _ = require("lodash");

var CHANGE_EVENT = 'change';
var _loaded = false;
var _nodes = [];
var _data = {};
var _tagArray = [];
var _siteTitle;
var _socialData;


function receiveSearchTagNodes(rawNodes){
   _loaded = true;
    //console.log("TAGS",action.rawNodes.offices[0][Config.lang].search_data)
      tagList = rawNodes.offices[0][Config.lang].search_data || [];
      tagList.push("work");
      tagList.push("news");
      tagList.push("knowledge");
      _nodes = _.uniq(tagList);
      _siteTitle = rawNodes.offices[0][Config.lang].html_title;
      _socialData = rawNodes.offices[0].social_data;


}

var TagStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
  this.removeListener(CHANGE_EVENT,listener)
},

  getTags: function() {
     // console.log("in get tags");
     if(_nodes && _nodes.length === 0 || !_loaded){
      BBDOAPIUtils.getSearchTags();

    }

    return _nodes;
    //return _nodes[tags];
  },

  getSiteTitle: function(){
       if(_nodes && _nodes.length === 0 || !_loaded){
        BBDOAPIUtils.getSearchTags();

      }
      return _siteTitle;

  },

  getSocialData: function(){
       if(_nodes && _nodes.length === 0 || !_loaded){
        BBDOAPIUtils.getSearchTags();

      }
      return _socialData;

  }

});


TagStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {
    case "RECEIVE_SEARCH_TAGS_NODES":
      receiveSearchTagNodes(action.rawNodes)
      TagStore.emitChange();
      break;
    default:
      // do nothing
  }

});

module.exports = TagStore;
