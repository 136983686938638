/** @jsx React.DOM */
 var React = require('react');    

var Embed = React.createClass({
mixins:[require('../mixins/AnimationMixin')],
    
    render: function(){
    var embedCode = {__html: this.props.data};
    var styleObject = {

    	width:'100%',
    	textAlign: 'center',
    	margin: '0 auto'

    }
    //
      return (
        <div className="embedVideo">
            <div style={styleObject} dangerouslySetInnerHTML={embedCode}></div>
        </div>
      		
        );
    }

});

module.exports = Embed;