/** @jsx React.DOM */
var LocationAction = require("../../actions/LocationActionCreator");
var React = require('react');    



var Search = React.createClass({
	
	getInitialState: function(){
		return{
				clicked: false,
				prevElement: undefined
			};
	},

	handleChange: function(){

		LocationAction.filterLocations(this.refs.filterLocation.getDOMNode().value);
	},
	handleSubmit: function(e){
		e.preventDefault();
	},
	render: function(){

		return( 
			<div className="location-search">
				<span className="search-icon"></span>
				<form onSubmit={this.handleSubmit}>

					<input onChange={this.handleChange} type="text" name="search" placeholder="Search Locations"   ref="filterLocation" />
				</form>
			</div>
		);
	}

});

module.exports = Search;