 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('./mixins/HoverMixin.js');
 var Cannes = require('./Cannes.js');
 var Grid = require('./Grid.js');
 var objectAssign = require('object-assign');
 var FeedStore = require('../stores/FeedStore');
 var gridActionCreator = require("../actions/GridActionCreator");
 var SearchActionCreator = require("../actions/SearchActionCreator");
 function getStateFromStores() {
         return FeedStore.getInterleavedFeed();
     }

 var GridSortSplit = React.createClass({
     getInitialState: function() {
         return {
             data: [],
             sortBy: ""
         };
     },
     contextTypes: {
         router: React.PropTypes.func.isRequired
     },
     componentWillUnmount: function() {
         FeedStore.removeChangeListener(this._onChange);
     },
     componentWillUpdate: function(nextProps, nextState) {
         if (this.state.sortBy !== nextState.sortBy) {
             gridActionCreator.selectSort(nextState.sortBy);
         }
     },
     getFilterFromPath: function() {
       
         var path = this.context.router.getCurrentPath().substr(1).split("/").splice(1).join("/");
         return (path === "" ? "all" : path);
     },
     componentDidMount: function() {

        SearchActionCreator.toggleSearch(true);
         FeedStore.addChangeListener(this._onChange);
         gridActionCreator.selectFilter(this.getFilterFromPath());
     },
     render: function() {

         var filter = this.getFilterFromPath();
        var self = this;
         var grids = <div />;
         if (this.state.data.sorted) {
          grids = this.state.data.sortArray.map(function(result,i){
            if(result.items.length > 0){
            return (
              <div key={i+result.category}>
                <div><h1 className="filter-title">{result.category}</h1></div>
                <Grid gridData={result.items} gridRelated={true} windowWidth={self.props.windowWidth} gridContentType={filter}/>
              </div>
            )
          } else {
            return "";
          }
          });


         } else {

            var shiftedGridData = this.state.data.slice();
            if(this.props.hasMarqueeOnTop){
              shiftedGridData.shift();
            }

             grids = <Grid gridData={shiftedGridData} sortBy={"awards"} gridRelated={false} windowWidth={this.props.windowWidth} gridContentType={filter} />;

         }
         return (<div className={filter == "all" ? "home-grid-page" : "grid-page"}>

       {grids}
       </div>);
     },
     _onChange: function() {

         this.setState({
             data: getStateFromStores()
         });

     },
 });

 module.exports = GridSortSplit;
