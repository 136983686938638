
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  selectFilter: function(gridType) {
    AppDispatcher.handleViewAction({
      type: "SELECT_FILTER",
      gridType: gridType
    });
  }
};