var PageDispatcher = require('../dispatcher/PageDispatcher');
var PageStatusConstants = require('../constants/PageStatusConstants');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ = require("lodash");

var CHANGE_EVENT = 'change';

var _animations = 0;
var _loadingProcess = 0;
var _isReady = false;
var _checkTimeout;
var _setReadyTimeout;


function reset(){
  _animations = 0;
  _loadingProcess = 0;
  _isReady = false;
  checkReady();
  // backup timer that will show the footer in case of error
  clearTimeout(_setReadyTimeout);
  _setReadyTimeout = setTimeout(function(){
    setReady();
  }, 5000)
}

function addAnimation(){
  _animations++;
  checkReady();
}

function removeAnimation(){
  _animations--;
  if (_animations < 0) _animations = 0;
  checkReady();
}

function addLoadingProcess(){
  _loadingProcess++;
  checkReady();
}

function removeLoadingProcess(){
  _loadingProcess--;
  if (_loadingProcess < 0) _loadingProcess = 0;
  checkReady();
}

function checkReady() {
  clearTimeout(_checkTimeout);
  _checkTimeout = setTimeout(function(){
      if (_animations == 0 && _loadingProcess == 0){
          setReady();
      }
  }, 300)
}


function setReady(){
  clearTimeout(_setReadyTimeout);
  _isReady = true;
  PageStatusStore.emitChange();
}

var PageStatusStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },


  isPageReady: function() {
    return _isReady;
  }

});

PageStatusStore.dispatchToken = PageDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {
    
    case PageStatusConstants.ANIMATION_START:{
      addAnimation();
      PageStatusStore.emitChange();
      break;
    }

    case PageStatusConstants.ANIMATION_END:{
      removeAnimation();
      PageStatusStore.emitChange();
      break;
    }

    case PageStatusConstants.DATA_LOAD_START:{
      addLoadingProcess();
      PageStatusStore.emitChange();
      break;
    }

    case PageStatusConstants.DATA_LOAD_END:{
      removeLoadingProcess();
      PageStatusStore.emitChange();
      break;
    }

    case PageStatusConstants.RESET:{
      reset();
      PageStatusStore.emitChange();
      break;
    }

    default:
      // do nothing
  }

});

module.exports = PageStatusStore;
