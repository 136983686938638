/** @jsx React.DOM */

var React = require('react');

var HoverMixin = require('../mixins/HoverMixin.js');

var Grid = require('../Grid.js');

 var Translator = require('../../utils/Translator.js');


/*var FeedStore = require('../../stores/FeedStore');*/
/* var gridActionCreator = require("../../actions/GridActionCreator");*/



//------------ <GRIDPAGE> --------------//
/*

Template for any page that contains a standard set of tiles.

*/
//---------------------------------------//
var RelatedGridHolder = React.createClass({

	render: function() {
		var i18n = Translator.getInstance();
		if (this.props.gridData && this.props.gridData.length) {
			return (
				<div>
					<div className="related"><h2>{
                         // TRANSLATORS: "Filed under" on single article bottom
						i18n.gettext('Related')}</h2></div>
					<Grid gridData={this.props.gridData} windowWidth={this.props.windowWidth} gridContentType={this.props.gridContentType} gridRelated={true} />
				</div>
			);
		} else {
			return (<div></div>);
		}
	},
});


module.exports = RelatedGridHolder;