/** @jsx React.DOM */
var React = require('react');
var ModalVideoStore = require('../../stores/ModalVideoStore');
var ModalVideoActionCreator = require('../../actions/ModalVideoActionCreator')
var NativeVideo = require("./videoPlayerModules/NativeVideo");
var EmbedVideo = require("./videoPlayerModules/EmbedVideo");
var Router = require('react-router');
var $ = require('jquery');
var Config = require('../../../Config');
var ModalVideoController = React.createClass({
contextTypes: {
        router: React.PropTypes.func
      },
	getInitialState:function(){
		return {
				data: {},
				open: false
				}
		
	},
	componentWillUnmount: function() {
	    ModalVideoStore.removeChangeListener(this._onChange);
        $(document).off('keyup',this.removeOnKeyup);
	},
	componentDidMount : function(){
	   $(document).on('keyup',this.removeOnKeyup);	
    	ModalVideoStore.addChangeListener(this._onChange);
	},
    removeOnKeyup: function(e){

                
            if (this.state.open && (e.keyCode === 27 || e.keyCode === 8)) {
                this.closeModal();
            }

       
    },
	_onChange: function(){

		this.setState({
			url: ModalVideoStore.getModalReturnURL(),
			data: ModalVideoStore.getModalVideo(),
			open: ModalVideoStore.isModalOpen(),
		});

	},

	closeModal:function(){
		ModalVideoActionCreator.closeModalVideo();

		if(this.state.url && window.location.pathname !== Config.lang + this.state.url){
			window.location = this.state.url;
		}
	},
    render: function(){
    	//ESC out of video
    	var self = this;

        if(this.state.open === true && Config.msieversion() !== 10){
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.overflow = "visible";
        }
    	var retVar = <div />
    	var mp4,webm,embed = "";
    	var player = <div></div>;
    	var styleObj = {
    		width:'100%',
    		height:'100%',
    		position:'fixed',
    		display: this.state.open ? 'block' : 'none',
    		top:0,
    		left:0,
    		backgroundColor: 'rgba(0,0,0,0.9)',
    		color:'white',
    		zIndex: 11111
    	}
    	if(this.state.data){
    		if(this.state.data.embed){
    			player = <EmbedVideo open={this.state.open} embed={this.state.open ? this.state.data.embed : '' } onDismissFullscreen={this.closeModal} />;
    		} else {
    			player = <NativeVideo open={this.state.open} data={this.state.data} onDismissFullscreen={this.closeModal}/>;
    		}
	    //	mp4 = this.state.data.mp4 || "";
	    //	webm = this.state.data.webm || "";
	    //	embed = this.state.data.embed || "";
    	}
    	//if(this.state.open){
    	retVar = 
        	<div style={styleObj}>

        		{player}
				
				<span className="video-modal-close" onClick={this.closeModal} ></span>
			</div>
		//}
        return retVar;
       
    }
});
module.exports = ModalVideoController;
