 var React = require('react');
 var HoverMixin = require('./mixins/HoverMixin');
 var Cannes = require('./Cannes');
 var GridSortSplit = require('./GridSortSplit');
 var FeedStore = require('../stores/FeedStore');
 var gridActionCreator = require("../actions/GridActionCreator");


/* function getStateFromStores() {
  return  FeedStore.getInterleavedFeed();
}*/

//------------ <GRIDPAGE> --------------//
/*

Template for any page that contains a standard set of tiles.

*/
//---------------------------------------//

// todo: get rid of feed store.

var CannesPage = React.createClass({
      getInitialState : function(){
        return {
          data : [],
        };
      },
      contextTypes: {
        router: React.PropTypes.func.isRequired
      },    	
      componentWillMount: function(){
        document.body.style.backgroundColor = "#000000";
        this.props.switchCannesStateFunc();
      },
      componentWillUnmount: function() {
        document.body.style.backgroundColor = "#ffffff";
        this.props.switchCannesStateFunc();
       // FeedStore.removeChangeListener(this._onChange);

     },
     componentDidMount : function(){
      //FeedStore.addChangeListener(this._onChange);
      /*gridActionCreator.selectFilter(this.props.gridContentType);*/
    },

    render : function(){
      var filter = 'cannes';
      var className = 'cannes-grid-page';
      return(
       <div className={className}>
       <GridSortSplit {...this.props} />
       </div>
       );
    },
    /*_onChange: function() {

      this.setState({
        data :  getStateFromStores()
      });

    },*/
  });


module.exports = CannesPage;