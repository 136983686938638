 /** @jsx React.DOM */
 var React = require('react');    


//--------------- <Cannes> ----------------//
var TileTokens = React.createClass({


    render: function(){
        var Tokens = <div></div>;

        var tokenColor = {
          bronze: "bronze",
          silver: "silver",
          gold: "gold",
          
          special: "special"
        };
        tokenColor["grand prix"] = 'grand-prix';
           Tokens = this.props.tokenTypes.map(function(result,i){
            var ClassString = "medallions " + tokenColor[result];
                return(
                	<span key={i} className={ClassString}></span>	
                );
        });         
        return (
        	<div className="  medallions-container">
        		{Tokens}
        	</div>
        );
    }

});
//--------------- </Cannes> ----------------//

module.exports = TileTokens;

