/** @jsx React.DOM */
var React = require('react/addons');
var NotFound = React.createClass({
    goHome: function(){
      window.document.location.href = "/";
    },
    render: function(){

      return (
        <div className="page-not-found nav-space">
          <div className="contain-page-not-found">
    		    <h1>404: Page not found</h1>
            <h3>Sorry the page you are looking seems to be missing.</h3>
            <span className="work-btn" onClick={this.goHome}>Go to Homepage</span>
          </div>
        </div>
      );

    }
});

module.exports = NotFound;
