/** @jsx React.DOM */
 var React = require('react');    

var HeroImage = React.createClass({
	mixins:[require('../mixins/AnimationMixin')],
    render: function(){
      
        return (
           <div className="hero">
           		<div className="hero-wrap">
           			<img src={this.props.data} alt="hero image" />
           		</div>
           	</div>
        );
    }

});

module.exports = HeroImage;