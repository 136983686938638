var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");

var CHANGE_EVENT = 'change';

var _scrollY = 0;

var ParallaxStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },

  get: function() {
    return _scrollY;

  }

});


ParallaxStore.dispatchToken = AppDispatcher.register(function(payload) {
  ParallaxStore.setMaxListeners(500);
  var action = payload.action;
  switch(action.type) {

    case "Y_CHANGE":
      
      ParallaxStore.emitChange();
      _scrollY = action.y;
      break;  
      
    default:
      // do nothing
  }

});

module.exports = ParallaxStore;
