/** @jsx React.DOM */
var React = require('react');  

var Quote = React.createClass({
	 mixins:[require('../mixins/AnimationMixin')], 
    render: function(){

        return (
		<div className="quote">
		      <span>{this.props.data}</span>
		</div>
        );
    }
});

module.exports = Quote;