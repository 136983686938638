 /** @jsx React.DOM */
 var React = require('react');
 var Cannes = require('./Cannes');
 var Router = require('react-router');
 var FeedStore = require('../stores/FeedStore');
 var gridActionCreator = require('../actions/GridActionCreator');
 var HoverMixin = require('./mixins/HoverMixin.js');
 var TileTokens = require("./tiles/TileTokens");
 var modalActionCreator = require('../actions/ModalVideoActionCreator');
 var Link = Router.Link;


function getDestination(post){
    var result = 'workDetail';
    if (!post) return result;
    switch(post.type){
        case 'knowledge': {
            result = 'knowledgeDetail';
            break;
        }

        case 'news':{
            result = 'newsDetail';
            break
        }

        case 'work':
        default: {
            result = 'workDetail';
            break;
        }
    }
    return result;
}

function isVideoPost(post, destination){
    return  post 
            && post.carousel 
            && post.carousel[0] 
            && post.carousel[0].videoURL 
            && destination == "workDetail";
}

 var Marquee = React.createClass({
     mixins: [HoverMixin],
     componentDidMount: function() {
         gridActionCreator.selectFilter(this.props.isMarquee ? "cannes" : "all");
         FeedStore.addChangeListener(this._onChange);
         this.changeMarqueeState(this.props.renderType);
     },
     componentWillUnmount: function() {
         FeedStore.removeChangeListener(this._onChange);
     },
     _onChange: function() {
         this.setState({
             post: FeedStore.getInterleavedFeed()[0]
         });
     },
     getInitialState: function() {
         return {
             classString: "marquee nav-space",
             image: "../../cdn/content_images/defaultmarquee.jpg",
             src: "work",
             id: null,
             overrideMarqueeRenderType: false,
             hoverElementActive: true,
             showCannes: false,

         };
     },
     changeMarqueeState: function(ChangeState) {
         //if cannes is not running show regular marquee
         if (ChangeState === "default") {
             this.setState({
                 hoverElementActive: true,
                 showCannes: false,
                 classString: "marquee nav-space"
             });
             //if we are on the cannes page show default marquee with different margin
         } else if (ChangeState === "CannesPageVisibleMarquee") {
             this.setState({
                 hoverElementActive: true,
                 showCannes: false,
                 classString: "marquee cannes-active"
             });
             //If cannes event is running show cannes awards on home page
         } else {
             this.setState({
                 hoverElementActive: false,
                 showCannes: true,
                 classString: "marquee nav-space"
             });
         }
     },
     openModal: function(n) {
         modalActionCreator.openModalVideo(this.state.post.carousel[0].videoURL, this.state.post.id, this.state.post.type);
     },
     componentWillReceiveProps: function(nextProps) {
         this.changeMarqueeState(nextProps.renderType);
     },
     render: function() {

         var hoverElements = <div></div>;
         var hoverClass = "marquee-hover ";
         var cannesRender = <div></div>;
         var styleObj = {
             opacity: "0"
         };
         var playbutton = <span></span>
         var paramObject = {lang: Config.lang};
         var TokenModule = '';
         var imageSrc, source, title, campaign = "";

         if (this.state.hoverState === true || Config.mobileAgent) {
             hoverClass += ' marquee-hover-active';
         }
         if (this.state.post) {
             imageSrc = this.state.post.marqueeImage;
             title = this.state.post.title;
             source = this.state.post.source;
             campaign = this.state.post[Config.lang].campaign;
             paramObject.id = this.state.post.id;
             paramObject.lang = Config.lang;
             styleObj = {
                 opacity: "1"
             };
            if (this.state.post.tokens) {
             TokenModule = <TileTokens tokenTypes={this.state.post.tokens} />;
            }
         }
         //debugger;

         if (this.state.hoverElementActive === true) {
             //add hover modules
             hoverElements = (<div className={hoverClass}>

                         <div className="marquee-content">
                                <h4>{this.state.post && this.state.post.type === 'work' ? campaign : source}</h4>
                                <h1>{title}</h1>
                        </div>
                    </div>);
         } else {
             //remove hover modules
         }

         var destination = paramObject.id && !this.state.showCannes ? getDestination(this.state.post) : "cannes";
         //if (this.state.showCannes === true) {
            if (false){
             //add Canne modules
             cannesRender = < Cannes data = {
                 this.props.data
             }
             windowWidth = {
                 this.props.windowWidth
             }
             />;
         } else {
             //remove cannes modules
             if (isVideoPost(this.state.post, destination)) {
                playbutton = <span className="play-button" ></span>;
            }
         }
         var noCannesStyleObj = this.props.windowWidth >= Config.mobileBreak ? {
             height: "110px"
         } : {}
         
         var innards = <section style={styleObj} className={this.state.classString} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse}>
                    <div className="aspect-ratio-wrap">
                        {hoverElements}
                        {cannesRender}
                        {playbutton}
                        {TokenModule}
                        <img src={imageSrc} alt="" />
                    </div>
                </section>
         var link = <Link to={destination} params={paramObject}>
                {innards}
            </Link>
         if (isVideoPost(this.state.post, destination)) {
            link = <a onClick={this.openModal}>
                {innards}
                </a>
         } else {
         }
         var marquee = <div style={noCannesStyleObj} />
         if (FeedStore.getSort() === "") {
             marquee = link
         }
         return (marquee);
     }
 });
 module.exports = Marquee;
