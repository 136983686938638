 /** @jsx React.DOM */

var React = require('react');    
var Config = require('../../Config');
var Grid = require('./Grid.js');
var DetailStore = require('../stores/DetailStore');
var GeneralContent = require('./content/GeneralContent');
var RelatedGridHolder = require('./generalModules/RelatedGridHolder');
var ShareLinks = require('./generalModules/ShareLinks');
var DetailActionCreator = require('../actions/DetailActionCreator');
 var SearchActionCreator = require("../actions/SearchActionCreator");
var $ = require('jquery');
//------------ <APP> --------------//
var GeneralDetail = React.createClass({
    
      contextTypes: {
        router: React.PropTypes.func
      },
    	getInitialState : function(){
    		return {
    			data : {},
    			id: 0,
          whichDetailPage: "",
          whichId: this.context.router.getCurrentParams().id,
          sectionHeight:0
    		};
    	},		   
	    componentWillUnmount: function() {
          DetailStore.removeChangeListener(this._onChange);
		 
      },
      setTitleofPage: function(){
        var oldTitle;
        if(this.state.data.content !== undefined && this.state.data.content[5].data !== oldTitle){
          window.document.getElementById("g-title").innerHTML = this.state.data.content[5].data;
          oldTitle = this.state.data.content[5].data;
        }
      },
      componentDidUpdate: function(prevProps, prevState){
      
        var details = $(this.refs.detailContain.getDOMNode());

        if(details.height() !== this.state.sectionHeight){
          this.setState({
            sectionHeight: details.height()
          });
        }
      },
    	componentDidMount : function(){
       SearchActionCreator.toggleSearch(true);

        var regex = /\/\1\//;
        var whichDetailPage = /\/([^\/]+)/.exec(this.context.router.getCurrentPath().split('/').splice(1).join("/"))[1];
        
        var whichId=this.context.router.getCurrentParams().id || "";
        this.setState({
          whichDetailPage: whichDetailPage,
          whichId: whichId
        });
        DetailStore.addChangeListener(this._onChange);
        DetailActionCreator.getDetailsFor(whichDetailPage,whichId);
    	},
    	render : function(){
        var renderVal = <div ref="detailContain" />
        var relatedGrid = <div />
        var classNameString = "detail-page";
        var mobileFactSheet = '';
        var socialObject = {
          url: '',
          image: '',
          title: '',
          description: ''
        };

        if(this.state.data && this.state.whichDetailPage){
          var Content = GeneralContent;
          if((this.state.whichDetailPage === "work" || this.state.whichDetailPage === "knowledge" || this.state.whichDetailPage === "news" || this.state.whichDetailPage === "bbdo-u" || this.state.whichDetailPage === "bbdo-knows") && this.state.data.content && this.state.data.content[4]){

            relatedGrid = <RelatedGridHolder {...this.props} gridContentType={this.state.whichDetailPage} gridData={this.state.data.content[4].data} />
          }
          if(this.state.whichDetailPage ==="knowledge" || this.state.whichDetailPage === "news" || this.state.whichDetailPage === "bbdo-u" || this.state.whichDetailPage === "bbdo-knows"){
            classNameString += " knowledge-news-detail";
            if (this.state.whichDetailPage === "bbdo-u") classNameString += ' bbdo-u';
            
            if(this.state.data.content){
              socialObject.image = this.state.data.content[0].data.imageUrl;
              socialObject.url = window.location.origin + this.context.router.getCurrentPath();
              socialObject.title = this.state.data.content[0].data.headline;
              socialObject.description = this.state.data.content[2].data[0].content;
            }
            this.setTitleofPage();
          } else if(this.state.whichDetailPage ==="work"){
            classNameString += " work-single";
            if(this.state.data.content){
            
              socialObject.image = this.state.data.content[0].data[0].imageURL;
              socialObject.url = window.location.origin + this.context.router.getCurrentPath();
              socialObject.title = this.state.data.content[1].data.campaign.name;
              socialObject.description = this.state.data.content[2].data[0].content;
            }
            this.setTitleofPage();
          }else{
            classNameString += " about-client";
            if(this.state.data.content){
              if(this.state.data.content.length === 2){
                
                socialObject.image = this.state.data.content[0].data.imageUrl;
                socialObject.url = window.location.origin + this.context.router.getCurrentPath();
                socialObject.title = this.state.data.content[1].data[0].content;
                socialObject.description = this.state.data.content[1].data[1].content;
              }else{
                socialObject.image = this.state.data.content[0].data.imageUrl;
                socialObject.url = window.location.origin + this.context.router.getCurrentPath();
                socialObject.title = "BBDO Worldwide: Clients";
                socialObject.description = this.state.data.content[1].data[0].content;
              }
            }
            if(this.props.windowWidth <= Config.mobileBreak){
             mobileFactSheet = <div className="toolbox-link"><a href="../cdn/bbdo_fact_sheet.pdf" target="_blank" download><div className="work-btn">Fact Sheet</div></a></div>;
            }
          }
          //FACT SHEET BUTTON ADD BACK WHEN IT IS REDESIGNED
          //{mobileFactSheet}

          renderVal = (

              <div className="nav-space">
                <section className={classNameString} ref="detailContain">
                  <Content windowWidth={this.props.windowWidth} id={this.state.id} data={this.state.data}></Content>
                  {this.state.whichDetailPage !== 'bbdo-u' && this.state.whichDetailPage !== 'bbdo-knows' ? 
                    <ShareLinks data={socialObject} shouldUseSmoothScroll={this.props.shouldSmoothScroll} sectionHeight={this.state.sectionHeight} scrollY={this.props.scrollPosition} />
                    : ''}
                  
                </section>
                  {relatedGrid}
              </div>
          )

        }
    		return renderVal;
    	},
      _onChange: function() {
         var latest = DetailStore.get();
          this.setState({
            data :  latest
          });
      
      }
});


module.exports = GeneralDetail
