var AppDispatcher = require('../dispatcher/AppDispatcher');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var DetailTransform = require('../transforms/Transforms').DetailTransform;
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ = require("lodash");
var AuthStore = require('./AuthStore');
var CHANGE_EVENT = 'change';
var name="DetailStore";
var _nodes = [];
var _modalOpen = false;
var _id;
var _selectedVideo = 0;
var DetailStore = assign({}, EventEmitter.prototype, {
  getName: function(){
    return name;
  },
    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener);
    },

    getId: function() {
        return _id;
    },

    _assembleKnowledgeOrNewsObject: function() {
        var returnObject = {};
        returnObject.content = [
        {
            type:"detailHero",
            data:_nodes[Config.lang].detailHero
        },
        {
            type:"author",
            data:_nodes[Config.lang].author
        },
        {
            type:"content",
            data:_nodes[Config.lang].content
        },
        {
            type:"tags",
            data:_nodes[Config.lang].tags
        }
        ];
        return returnObject;
    },
    _assembleAboutObject: function(){
        var returnObject = {};
        returnObject.content = [
        {
            type:"hero",
            data:_nodes[Config.lang].hero
        },
        {
            type:"content",
             data:_nodes[Config.lang].content
        }
        ];
        return returnObject;
    },
    _assembleClientObject: function(){
        var returnObject = {};
        returnObject.content = [
        {
            type:"hero",
            data:_nodes[Config.lang].hero
        },
        {
            type:"content",
             data:_nodes[Config.lang].content
        },
        {
            type:"clientLogo",
            data:_nodes[Config.lang].client_logos
        }

        ];
        return returnObject;
    },

    contentIsLoaded: function(){
      return _nodes != [];
    },
    get: function(contentType, id) {
       
        var returnObject = {};
          if(_nodes){

          switch (_nodes.type) {

              case "work":
                  returnObject.content = DetailTransform.createWorkDetail(_nodes, AuthStore.checkUserSession().sessionID);
                  return returnObject;
                  break;
              case "about":
                returnObject.content = DetailTransform.createAbout(_nodes);
               
                  return returnObject;
                  break;
              case "client":
                  return this._assembleClientObject();
                  break;
              default:
                returnObject.content = DetailTransform.createNewsOrKnowledgeDetail(_nodes);

                  return returnObject;
                  break;

          }

        }

        return _nodes;

    },


});

DetailStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {
        case "GET_DETAILS_FOR":

            _id = action.id;
            _contentType = action.contentType;
            //console.log(_contentType);
            BBDOAPIUtils.getDetailNodes(_contentType, _id, AuthStore.checkUserSession().sessionID);
            break;

        case "RECEIVE_DETAIL_NODES":
            _nodes = action.rawNodes;
            if(_nodes.status === "success") { // actual result from API.
                if(action.rawNodes.data.posts){
                    _nodes = action.rawNodes.data.posts[0];
                } else{
                   // console.log(action.rawNodes.data.about[0]);
                    _nodes = action.rawNodes.data.about[0];
                    _nodes.type = "about";
                }
            }


            DetailStore.emitChange();
            break;



        default:
            // do nothing
    }

});

module.exports = DetailStore;
