/** @jsx React.DOM */
 var React = require('react');    
var HoverMixin = require('../mixins/HoverMixin');
var FeedStore = require('../../stores/FeedStore');
 var gridActionCreator = require("../../actions/GridActionCreator");


//--------------- <Cannes> ----------------//


var CannesFilterMenu = React.createClass({
      mixins: [HoverMixin,require('../mixins/AnimationFadeMixin')],

       getInitialState : function(){
      return {
         
          sortBy: ""
          };
      },
      handleClick : function (e) {
             if( this.state.hoverState === true ){
                  this.selectSort('');
            }else {
              this.setHover();
            }
      },
      removeAward : function(){
          this.selectSort('');
          this.setHoverFalse();
      },
      selectSort: function(whichSort){

          this.setState({
            sortBy: whichSort
          });
          this.setHoverFalse();
      },
      
      componentWillMount : function(){
        FeedStore.addChangeListener(this._onCannesSortChange);
        if(window.document.location.pathname === "/canneswork"){
          this.selectSort("awards");
          //this.props.sortStateFunc();
          gridActionCreator.selectSort('awards');
        }
      },
      componentWillUnmount : function(){

      },
      componentWillUpdate: function(nextProps, nextState){
      
      if(this.state.sortBy !== nextState.sortBy){
        gridActionCreator.selectSort(nextState.sortBy);
      }
       
     },
      _onCannesSortChange: function(){
        FeedStore.getSort();
      },
      render: function(){
        var dynamicClass = "mobile-filter-wrap";
        var selectedSort = '';
        var dropDown = '';
        var allOption = '';
        var selectedAwards = '';
        var activeStyleObj= {
          color: '#FFFFFF'
        };
        var newsIfApplicable = <span/>;
        
        if(this.props.shouldFilterNews){
          newsIfApplicable = <li onClick={this.selectSort.bind(null,'news')}><span style={this.state.sortBy === 'news' ? activeStyleObj : {} }>News</span></li>;
          allOption =  <li onClick={this.selectSort.bind(null, '')}><span style={this.state.sortBy === 'All' ? activeStyleObj : {} }>All</span></li>;
          }
        if(this.state.hoverState === true){
             dynamicClass +=" mobile-filter-wrap-active";
        }
        // if(this.state.sortBy !== ''){
        //   selectedAwards = (
        //     <div className="sort">
        //       <h3>
        //         <span>Sort by:</span>
        //       </h3>
              
        //     </div>
        //   );
        // }else{
          selectedAwards = (
            <div className="sort" onClick={this.handleClick}>
              <h3>
                <span className="arrow-right"></span>
                <span>Sort by:</span>
                <div className="selected-filter" ><h4>{this.state.sortBy}</h4></div>
              </h3>
            </div>
            );
        return (
          <div className={dynamicClass}>
          {selectedAwards}
          <div className="filter-list">
              <ul>
                <li onClick={this.selectSort.bind(null,'awards')}><span style={this.state.sortBy === 'awards' ? activeStyleObj : {} }>Awards</span></li>
                <li onClick={this.selectSort.bind(null,'category')}><span style={this.state.sortBy === 'category' ? activeStyleObj : {} }>Category</span></li>
                <li onClick={this.selectSort.bind(null,'client')}><span style={this.state.sortBy === 'client' ? activeStyleObj : {} }>Client</span></li>
                <li onClick={this.selectSort.bind(null,'agency')}><span style={this.state.sortBy === 'agency' ? activeStyleObj : {} } >Agency</span></li>
                {newsIfApplicable}
                {allOption}
              </ul>
          </div>
          <div className=" sort-cannes">
            <span className="cannes-close" onClick={this.onCloseClick}></span>
          </div>
          </div>
        );
    }
});
//--------------- </Cannes> ----------------//

module.exports = CannesFilterMenu;