/** @jsx React.DOM */
var React = require('react');    

var LocationHQ = React.createClass({
componentsDidMount : function(){

},
shouldComponentUpdate: function(nextProps){
	return (nextProps.isMobile !== this.props.isMobile || nextProps.locationHQData.Region !== this.props.locationHQData.Region);
},
render: function(){

	var fullAddress = this.props.locationHQData.address1+","+this.props.locationHQData.address2;
	var addressStr = encodeURIComponent(fullAddress);

var imageURL =  "https://maps.googleapis.com/maps/api/staticmap?center=" +  addressStr  +  "&zoom=16&size=640x220&maptype=roadmap&markers=color:red%7Clabel:C%7C" + addressStr;
  return ( 
    <div className={this.props.activeState?"region-hq region-active":"region-hq"}>
    		<div className="region-hq-contain">
	    		<div className="local-map">
	    			<img src={imageURL} />
	    		</div>
	    		<div className="local-map-description">
		    		<h1>{this.props.locationHQData.name} Headquarters</h1>
		    		<div>
		    			<span>{this.props.locationHQData.address1}</span>
		    			<span>{this.props.locationHQData.address2}</span>
		    		</div>
		    		<p>Tel: {this.props.locationHQData.phone}</p>
		    		<div>
		    			<span>New Business:</span>
		    			<span>{this.props.locationHQData.email}</span>
		    		</div>
		    	</div>
	    	</div>
    </div>
  );
}
});

module.exports = LocationHQ;