/** @jsx React.DOM */
 var React = require('react/addons');
var _ = require('lodash');
var toolboxItems = {

  header:require("./Header"),
  quote:require("./Quote"),
  paragraph:require("./Paragraph"),
  list:require('./List'),
  extraImage:require('./ExtraImage'),
  parallaxImage:require('./ParallaxImage'),
  readMore:require('./ReadMore'),
  embed:require('./Embed'),
  link:require('./Link'),
  pdf: require('./Pdf'),
  awardsList: require('./AwardsList')
};

var ToolBox = React.createClass({
  getInitialState: function(){
    return({
      showReadMore: false // if readmore exists, we switch this on until the user clicks it.
    });
  },

  mixins:[require('../mixins/AnimationMixin')],
    toolboxItems:toolboxItems,   
    
    componentWillMount: function(np){
     
  
        if(_.find(this.props.data,{'type':'readMore'})){

            this.setState({
              showReadMore:true
            });

        }
     
    },
    doSomething:function(){
      this.setState({showReadMore:false});
    },
    render: function(){
      
    	var returnValue = null;
      var self = this;
      var afterReadMore = false;
    	var toolBoxText = this.props.data.map(function(result,i){
          var tag = self.toolboxItems[result.type];
          // console.log("SHOULD BE ASSEMBLING TOOLBOX",result.type)
          var properties = {

            componentName:result.type,
            data:result.content,
            delay:0.5 + (i*0.1),
            key:i,
            style:{transition:'transform 1s',
            WebkitTransition: 'transform 1s',color:'red'},
            
          };
          if(result.type === "readMore"){
            properties.clickedReadMore = self.doSomething;
            if(self.state.showReadMore){
              afterReadMore = true;
            }
          }
          if((afterReadMore && self.state.showReadMore && result.type !== "readMore") || (!self.state.showReadMore && result.type === "readMore")){
            return "";
          } else {
            return React.createElement(tag, properties);
          }
    		}); 
   		 return(<section className="toolbox">
            
                 {toolBoxText}
                 
              </section>);
         
    }

}); 

module.exports = ToolBox;