var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var ServerConstants = require('../constants/ServerConstants');
var _ = require("lodash");

//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

var CHANGE_EVENT = 'change';
var _data = {};


//----------------------------------------
//  Methods
//----------------------------------------


function receiveData(data){
    _data = data;
}


//--------------------------------------------------------------------------
//
//  Public
//
//--------------------------------------------------------------------------

var DepartmentsStore = assign({}, EventEmitter.prototype, {


    //----------------------------------------
    //  Event handling
    //----------------------------------------

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    /**
     * @param {function} callback
     */
    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener)
    },

    //----------------------------------------
    //  Data operations
    //----------------------------------------

    getData: function(){
        return _.cloneDeep(_data);
    }


});

DepartmentsStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {

        case ServerConstants.RECEIVE_DEPARTMENTS_DATA: {
            receiveData(action.data);
            DepartmentsStore.emitChange();
            break;
        }

        default: {
            
            // do nothing
        }
    }

});

module.exports = DepartmentsStore;