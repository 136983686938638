 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('../mixins/HoverMixin');
 var TileMixin = require('../mixins/TileMixin');
 var Link = require('react-router').Link;
 //----------- <Grid><Knowledge> ------------//
 var Knowledge = React.createClass({
     mixins: [HoverMixin, TileMixin, require('../mixins/MobileHoverMixin')],
     getInitialState: function() {
         return {
             arWidth: 1,
             arHeight: 2,
             startedTransition: false
         };
     },
     hoverStateTrue: function() {
         var hoverState = this.refs.hover.getDOMNode();
         TweenLite.to(hoverState, 0.3, {
             opacity: "1"
         });
     },
     hoverStateFalse: function() {
         var hoverState = this.refs.hover.getDOMNode();
         TweenLite.to(hoverState, 0.3, {
             opacity: "0"
         });
     },
     render: function() {
         var sizeableStyle = this.getTileStyles();
         sizeableStyle.backgroundImage = 'url(' + this.props.data.homeImage + ')';
         var divStyle2 = {
             backgroundImage: 'url(' + this.props.data.hoverImage + ')'
         };
         var paramObject = {
             id: this.props.data.id,
             lang: Config.lang
         };
         var classString = "item knowledge";
         if (this.state.hoverState === true) {
             classString += ' title-overlay-active';
         }
         var outBound = <span />
         var windowTarget = this.props.data.externalURL ? "_blank" : "_self";
         if (windowTarget == "_blank") {
             outBound = <span className="outbound-link-icon"></span>
         }
         var innards = <div  ref="sizeable" className={classString} style={sizeableStyle}>
                                <div className="think-fade-hover" ref="hover">
                                    <div className="fade-text">
                                        <h3>{this.props.data.source}</h3>
                                        <h2>{this.props.data.title}</h2>   
                                    </div>
                                    {outBound}
                                </div>
                        </div>
         var link = <Link to={this.props.tileType + 'Detail'} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item">
            {innards}
        </Link>
         if (this.props.data.externalURL) {
             link = <a href={this.props.data.externalURL} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item" target="_blank">
            {innards}
            </a>
         }
         return (<li className='gridItemTop' style={this.props.styleObj}>
           {link}
</li>);
     }
 });
 //----------- <Grid></Knowledge> ------------//
 module.exports = Knowledge;
