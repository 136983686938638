var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ =require("lodash");
var $ = require('jquery');
var cookie = require('jquery.cookie');
var CHANGE_EVENT = 'change';

var _nodes = [];
var _selected = -1;
var _filter = "all";
var _userInput = '';
var session = {
  status: false,
  sessionID: ''
};
var AuthStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
hardSetSession: function(token){
  session = {
    status:true,
    sessionID: token
  }
},
  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

removeChangeListener: function(listener){
  this.removeListener(CHANGE_EVENT,listener);
},

  checkUserInput: function(){
    $.ajax({
      type: "POST",
      dataType: "json",
      url: "private-cannes.php",
      data: _userInput,
      success: function(data) {
        if(data.status === 'success'){
          session = {
            status: true,
           sessionID: data.id
          };
          var date = new Date();
          var hours = 6;
          date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
          $.cookie('sessionID', session.sessionID, { expires: date });
          AuthStore.emitChange();
        }else{
          session = {
            status: false,
            sessionID: 0
          };
          AuthStore.emitChange();
        }
      }
    });
  },
  logOut: function(){
    $.removeCookie('sessionID', { path: '/' });
    window.location.href = "/";
  },
  checkUserSession: function(){
    //console.log($.cookie('sessionID'));
    if($.cookie('sessionID') !== undefined){
      session = {
        status: true,
        sessionID: $.cookie('sessionID')
      };
    }
    return session;
  }
});


AuthStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {

    case "SEND_USER_INPUT":
      _userInput = action.data;
      AuthStore.checkUserInput();
      break;
    case "LOGOUT_USER":
      AuthStore.logOut();
      AuthStore.emitChange();
      break;
    case "CHECK_IF_USER_HAS_SESSION":
      AuthStore.checkUserSession();
      AuthStore.emitChange();
      break;
    default:
      // do nothing
  }

});

module.exports = AuthStore;