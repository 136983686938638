 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('../mixins/HoverMixin');
 var TileMixin = require('../mixins/TileMixin');
 var Link = require('react-router').Link;
var TileTokens = require("./TileTokens");
 var ThreeByOne = React.createClass({
     mixins: [HoverMixin, TileMixin],
     getInitialState: function() {
         return {
             arWidth: 3,
             arHeight: 1,
         };
     },

     render: function() {

         var classString = "item three-by-one";
         var sizeableStyle = this.getTileStyles();
         var outBound = <div></div>;
         var TokenModule = '';;
         var windowTarget = this.props.data.externalURL ? "_blank" : "_self";
         if (windowTarget === "_blank") {
             outBound = <span className="outbound-link-icon"></span>;
         }
         sizeableStyle.backgroundImage = 'url(' + this.props.data.image_3x1 + ')';
         if (this.state.hoverState === true) {
             classString += ' title-overlay-active';
         }
         if(this.props.data.tokens){
            TokenModule = <TileTokens tokenTypes={this.props.data.tokens} />;
        }
         var paramObject = {
             id: this.props.data.id,
             lang: Config.lang
         };
         var innards = <div ref="sizeable" className="item three-by-one title-overlay-active" style={sizeableStyle}>
                        <div className="title-overlay">
                            <h3>{this.props.data.source}</h3>
                            <h2>{this.props.data.title}</h2>
                            {outBound}
                            {TokenModule}
                         </div>
                        </div>
         var link = <Link to={this.props.tileType + 'Detail'} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item">
                    {innards}
                </Link>
         if (this.props.data.externalURL) {
             link = <a href={this.props.data.externalURL} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item" target="_blank">
            {innards}
            </a>
         }
         return (<li className='gridItemTop' style={this.props.styleObj}>
                {link}

            </li>);
     }
 });
 module.exports = ThreeByOne;
