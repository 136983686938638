/** @jsx React.DOM */
 var React = require('react');
var HoverMixin = require('../mixins/HoverMixin');
var FeedStore = require('../../stores/FeedStore');
 var gridActionCreator = require("../../actions/GridActionCreator");


//--------------- <Cannes> ----------------//


var CannesFilterMenu = React.createClass({
      mixins: [HoverMixin,require('../mixins/AnimationFadeMixin')],

       getInitialState : function(){
      return {

          sortBy: ""
          }
      },
      handleClick : function (e) {
             if( this.state.hoverState === true ){
                  this.setHoverFalse();
            }else {
               this.setHover();
            }
      },
      onCloseClick: function(){

        this.selectSort('');
        this.props.sortStateFunc();
      },
      selectSort: function(whichSort){

          this.setState({
            sortBy: whichSort
          });
      },

      componentWillMount : function(){
        FeedStore.addChangeListener(this._onCannesSortChange);
       
        if(window.document.location.pathname === "/canneswork"){
          this.selectSort("awards");
          this.props.sortStateFunc();
          gridActionCreator.selectSort('awards');
        }
      },
      componentWillUnmount : function(){

      },
      componentWillUpdate: function(nextProps, nextState){

      if(this.state.sortBy !== nextState.sortBy){
        gridActionCreator.selectSort(nextState.sortBy);
      }

     },
      _onCannesSortChange: function(){
        FeedStore.getSort();
      },
      render: function(){
        var dynamicClass = "filter-wrap";
        var selectedSort = '';
        var dropDown = '';
        var closeButton = '';
        var activeStyleObj= {
          color: '#FFFFFF'
        };
        if(this.props.sortState === true){
             dynamicClass +=" filter-wrap-active";
        }
        var newsIfApplicable = <span/>
        if(this.props.shouldFilterNews){
          newsIfApplicable = <li onClick={this.selectSort.bind(null,'news')}><span style={this.state.sortBy === 'news' ? activeStyleObj : {} } >News</span></li>;
          closeButton = <span className="cannes-close" onClick={this.onCloseClick}></span>
          }

        return (
          <div className={dynamicClass}>
          <div className="one-half-box cannes-lion-icon"></div>
          <div className="eight-box filter-list">
              <ul>
                <li onClick={this.selectSort.bind(null,'awards')}><span style={this.state.sortBy === 'awards' ? activeStyleObj : {} }>Awards</span></li>
                <li onClick={this.selectSort.bind(null,'category')}><span style={this.state.sortBy === 'category' ? activeStyleObj : {} } >Category</span></li>
                <li onClick={this.selectSort.bind(null,'client')}><span style={this.state.sortBy === 'client' ? activeStyleObj : {} } >Client</span></li>
                <li onClick={this.selectSort.bind(null,'agency')}><span style={this.state.sortBy === 'agency' ? activeStyleObj : {} } >Agency</span></li>
                {newsIfApplicable}
              </ul>
          </div>
          <div className=" sort-cannes">
            {closeButton}
          </div>
          </div>
        );
    }
});
//--------------- </Cannes> ----------------//

module.exports = CannesFilterMenu;
