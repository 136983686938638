/** @jsx React.DOM */
var React = require('react');
var $ = require('jquery')
var NativeVideo = React.createClass({

  componentDidUpdate: function(prevProps,prevState){
    if(prevProps.open === false && this.props.open === true){
      
      $('.videoplayer')[0].src = this.props.data.mp4;
      $('.videoplayer source')[0].src = this.props.data.mp4;
      $('.videoplayer source')[1].src = this.props.data.webm;
      $('.videoplayer')[0].play();
      $('.videoplayer')[0].addEventListener('webkitendfullscreen', this.props.onDismissFullscreen, false);
    } else if (prevProps.open && !this.props.open){
      $('.videoplayer')[0].pause();
      $('.videoplayer')[0].src = "";
    }

  },
          /*<source src={this.state.data.webm} type='video/webm; codecs="vp8, vorbis"'></source>*/
  render: function(){
   
    var src = <source />
  //if(this.props.data && this.props.data.mp4){
    src = [<source key={0} src={this.props.data.mp4} type='video/mp4'></source>,
    <source key={1} src={this.props.data.webm} type='video/webm'></source>]
 // }
    return (
      <div className="video-contain">
        <video className="videoplayer" autoPlay controls>

        {src}

        </video>
      </div>
    );
  }
});
module.exports = NativeVideo;