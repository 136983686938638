   /** @jsx React.DOM */
var Config = require('../../../Config')
var React = require('react');    
var HeroWithText = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
   	render: function(){
       var divStyle = null;
       
       var imageUrl;
       if(this.props.data.imageUrl && this.props.windowWidth > Config.mobileBreak){
        imageUrl = this.props.data.imageUrl;
      } else if(this.props.data.imageUrlMobile){
        imageUrl = this.props.data.imageUrlMobile;
      }
      if(this.props.data.imageUrl){
           divStyle = {
              backgroundImage: 'url(' + imageUrl + ')'
            };
          }
        return(
          <div className="hero-w-text">
            <div className="overlay"></div>
            <div className="hero"  style={divStyle}></div>
            <div className="hero-text">
              <h4>{this.props.data.source}</h4>
              <h1>{this.props.data.headline}</h1>
            </div>
          </div>
      	);
      }
});

module.exports = HeroWithText;