 /** @jsx React.DOM */
 var React = require('react/addons');
 var TransitionGroup = React.addons.TransitionGroup;
 var HoverMixin = require('./mixins/HoverMixin.js');
 var Marquee = require('./Marquee');
 var Grid = require('./Grid.js');
 var FilterPage = require('./FilterPage');
 var MobileCannesAwardCounter = require('./CannesPageModules/MobileCannesCounter');
 var CannesStore = require('../stores/CannesStore.js');
 var CannesPage = require('./CannesPage');
 var Config = require('../../Config');
 var _outroCB = null;

 function getDataFromCannesStore(){
    return CannesStore.get();
}
 var Home = React.createClass({

     contextTypes: {
         router: React.PropTypes.func.isRequired
     },
    getInitialState : function (){
          return{
                data: [],
          };

      },
      componentDidMount : function(){
          this._onChange();
          CannesStore.addChangeListener(this._onChange);
      },
      componentWillUnmount : function(){
          CannesStore.removeChangeListener(this._onChange);
      },

     getFilterFromPath: function() {
         var path = this.context.router.getCurrentPath().substr(1).split("/").splice(1).join("/").toLowerCase();
         return (path === "" ? "all" : path);
     },
     render: function() {
         var MarqueeType = null;
         var renderPage = 'default';
         var src = "/";
         var filter = this.getFilterFromPath();
         var MobileAwardCounter = '';
         var filterPage = <FilterPage hasMarqueeOnTop={true} windowWidth={this.props.windowWidth} />;

         var marqueeIfApplicable = (
            <Marquee delay={0} 
                     data={this.state.data} 
                     isCannes={false} 
                     renderType={renderPage} 
                     windowWidth={this.props.windowWidth} 
                     src={src} 
                     id={10}/>
          );

         return (
            <div>
                {MobileAwardCounter}
                {marqueeIfApplicable}
                {filterPage}
            </div>);
     },
    _onChange: function() {

        this.setState({
          data :  getDataFromCannesStore()
        });

    }
 });
 module.exports = Home;
