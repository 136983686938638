var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils.js');
var _ =require("lodash");

var CHANGE_EVENT = 'change';

var _nodes = [];
var _data = {};

var CannesStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

removeChangeListener: function(listener){
  this.removeListener(CHANGE_EVENT,listener);
},

  get: function() {
    if(_nodes.length === 0){
      
        BBDOAPIUtils.getCannesNode();
    }
    return _nodes;
  }

});


CannesStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {

    case "RECEIVE_CANNES_NODES":
      _nodes = action.rawNodes;
      CannesStore.emitChange();
      break; 
    default:
      // do nothing
  }

});

module.exports = CannesStore;