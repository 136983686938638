/** @jsx React.DOM */
var React = require('react');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var Map = require('./locationModules/Map');
var Search = require('./locationModules/Search');
var LocationRegionButton  = require('./locationModules/LocationRegionButton');
var config = require('../../Config');
var LocationList  = require('./locationModules/LocationList');
var LocationHQ = require('./locationModules/LocationHQ');
var LocationMobileHQ = require('./locationModules/LocationMobileHQ');
var WorldwideHQ = require('./locationModules/WorldwideHQ');
var LocationStore = require('../stores/LocationStore');
var LocationActionCreator = require("../actions/LocationActionCreator");
var LocationListMobile  = require('./locationModules/LocationListMobile');
var SearchActionCreator = require("../actions/SearchActionCreator");
function getDatafromStore(){
  return LocationStore.get();
}
function getRegionNamesFromStore(){
  return LocationStore.getRegionNames();
}
function getRegionsFromStore(){
  return LocationStore.getRegions();
}
function isFilteringFromStore(){
  return LocationStore.isFiltering();
}
var Locations = React.createClass({
mixins: [require('./mixins/MainTransitionMixin')],
  getInitialState : function (){

      return{
            data: []
          }
     },
      componentWillMount : function(){

      },
      componentDidMount : function(){

        SearchActionCreator.toggleSearch(true);

        LocationStore.addChangeListener(this._onChange);

        BBDOAPIUtils.getLocationNodes();
      },
      componentDidUpdate : function(){

      },
      componentWillUnmount : function(){
          LocationStore.removeChangeListener(this._onChange);
      },
      handleUserSelect: function(n){

          LocationActionCreator.selectRegion(n);

      },
      render: function(){

        var isMobile = this.props.windowWidth <= config.mobileBreak ;
          var locationHTML = <div></div>;
          var columnCount = 3;
          var data= {
            region : [],
            LocationList : {},
            Map : {
                longitude: -73.9802208,
                latitude:  40.760996,
                zoom: 15
            }
          }
          if(this.props.windowWidth <= Config.mobileBreak){
            columnCount= 2;
          }
          var locationList = <LocationList   locationData={this.state.data.regionData} columns={columnCount} />;
          var locationButtons =  <LocationRegionButton hide={this.state.filtered} regionData={this.state.regionNames} regionIndex={this.state.data.regionIndex} onUserSelect={this.handleUserSelect} />;

           if(this.state.data.regionData){
                  var locationHQ;
                  if(isMobile){
                          locationList = <LocationListMobile  isMobile={isMobile} isSearch={this.state.filtered} locationHQData= {this.state.data.regionData.hq} regionData={this.state.regionNames} regionIndex={this.state.data.regionIndex} onUserSelect={this.handleUserSelect}  locationData={this.state.data.regionData} columns={1} />;
                          locationButtons = '';
                          loacationHQ = <LocationMobileHQ isMobile={isMobile} locationHQData= {this.state.data.regionData.hq} />
                    }
                   if (this.state.filtered) {
                                locationHQ = '';
                    }else if(!isMobile){
                                locationHQ = <LocationHQ  isMobile={isMobile} locationHQData= {this.state.data.regionData.hq} />
                    }
                    //THIS IS THE LOCATION CODE THAT NEEDS TO BE ADDED BACK IN
                    /*
                      <Search />
                      {locationButtons}
                      {locationHQ}
                      {locationList}

                    */
                   locationHTML = (
                          <div className="nav-space">
                                <section className="locations">
                                    <Map isMobile={isMobile} mapData = {data.Map} />
                                    <WorldwideHQ wwData ={this.state.data.ww} />
                                </section>
                                <Search />
                                {locationButtons}

                                {locationList}
                          </div>
                    );
            }
            return(locationHTML);
      },
    _onChange: function() {

      this.setState({
        data :  getDatafromStore(),
        regionNames : getRegionNamesFromStore(),
        filtered : isFilteringFromStore()
      });
  }
});

module.exports = Locations;
