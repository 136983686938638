var $ = require('jquery');

//------------- MIXIN --------------//
	var progressBar = {
		scope: {
			initialize : true
		},
		init: function(index) {
			var activeSlide = index +1;
			var initialLength = $('.slick-slide').length -2;

			if( this.scope.initialize === true){
				this.initBar(initialLength);
				this.scope.initialize = false;
			}else{
				this.updateBar(activeSlide, initialLength);
			}

		},
		initBar :function(initial){
			var percentage = 100 / initial;
			var percentSting = percentage.toString();
			var finalWidth = percentSting + "%";
			$('.progress-bar').css("width", finalWidth);
		},
		updateBar : function(active, initial){

			var percentage = 100 / initial;
			var calculatePercent = active * percentage;
			var finalWidth = calculatePercent.toString() + "%";
			$('.progress-bar').css("width", finalWidth);

		},

		clickBar : function(ev){
			//Update Bar Location//
			var $div = $(ev.target);
			var fullwidth = $div.width();
			var offset = $div.offset();
			var x = ev.clientX - offset.left;
			var width = x / fullwidth;
			var percentWidth = width *100;
			var finalWidth = percentWidth.toString() + "%";

			//Update Slide Based on location//
			var index = $('.slick-slide').length -2;
			var click = percentWidth;
			var percent = 100/ index;
			for( i=0; i<index; i++){
				if(click >= percent * i  &&  click <= percent * (i+0.9) ){

					$('.slick-dots').find('button')[i].click();
				}
			}
			$('.progress-bar').css("width", finalWidth);
		},
	};
//------------- /MIXIN --------------//

module.exports = progressBar;