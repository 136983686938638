/** @jsx React.DOM */
var React = require('react');
var ModalVideoActionCreator = require('../../actions/ModalVideoActionCreator');
var LightboxActionCreator = require('../../actions/LightboxActionCreator');
var ExtraImage = React.createClass({

	mixins:[require('../mixins/AnimationMixin')],
    openModal:function(){
        ModalVideoActionCreator.openModalVideo(this.props.data.videoURL);
    },
    openLightbox: function(event){
        if (event.button == 0) // if left-click
        LightboxActionCreator.open(this.props.data.originalImgURL);
    },
    render: function(){
    	var playbutton = '';
        var clickHandler = null;
        var caption = 'extra image';
        if(this.props.data.description){
            caption = this.props.data.description;
        }
    	if(this.props.data.type !== "image"){
            clickHandler = this.openModal;
    		playbutton = <span onClick={this.playVideo} className="play-button"></span>;
    	}

        if (this.props.data.hasLightbox){
            clickHandler = this.openLightbox;
        }

    	return (
        	<div>
			<div className={"extra-image" + (this.props.data.hasLightbox ? " has-lightbox" : "" )}
                 onMouseUp={clickHandler}>
			      <img ref="image" src={this.props.data.imageURL} alt={caption} />
			      {playbutton}
			</div>
			<div className="extra-image-description">
			      <span>{this.props.data.description}</span>
			</div>
		</div>
        );
       
    }
});
module.exports = ExtraImage;
