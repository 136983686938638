var AppDispatcher = require('../dispatcher/AppDispatcher');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils');
var FeedStore = require('./FeedStore');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ =require("lodash");
var Config = require("../../Config");

var CHANGE_EVENT = 'change';

var _nodes = [];
var _id;
var _type;
var _modalOpen = false;
var _selectedPost;
var _selectedVideo = {};
var ModalVideoStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },

  isModalOpen: function(){
    return _modalOpen;
  },
  getModalVideo: function(){
    return _selectedVideo
  },
  getModalReturnID: function(){
    return _id;
  },
  getModalReturnURL: function(){
    if(_type && _id) {
      return "/" + Config.lang + "/" + _type.replace('bbdou', 'bbdo-u') + "/" + _id; // .replace is here because in these cases type is different from url
    } else {
      return "";
    }
  }


});

ModalVideoStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {
    
    case "OPEN_MODAL_VIDEO":
      _selectedVideo = action.videoObject;
      _id = action.id;
      _type = action.typeOfPost;
      _modalOpen = true;
      ModalVideoStore.emitChange();
      break;
    case "CLOSE_MODAL_VIDEO":
      _modalOpen = false;
      ModalVideoStore.emitChange();

    default:
      // do nothing
  }

});

module.exports = ModalVideoStore;
