
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {


  openModalVideo: function(videoObject,id,type){
  	AppDispatcher.handleViewAction({
  		type: "OPEN_MODAL_VIDEO",
  		videoObject: videoObject,
      id: id,
      typeOfPost: type
  	})
  },
  closeModalVideo: function(){
    
  	AppDispatcher.handleViewAction({
  		type: "CLOSE_MODAL_VIDEO",
  	})
  }
  
};