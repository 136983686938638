var page = require('page');
//------------- MIXIN --------------//
var NavigateMixin = {
	navigate: function(u) {
		return function(){
			page(u);
		}
  	},
}
//------------- /MIXIN --------------//

module.exports = NavigateMixin;