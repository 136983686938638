 /** @jsx React.DOM */
 var React = require('react');
 var Tokenizer = require('../../vendor/react-typeahead/src/tokenizer/index');
 var TagStore = require('../../stores/TagStore');
 var SearchStore = require('../../stores/SearchStore');
 var SearchActionCreator = require('../../actions/SearchActionCreator');
 var Transition = require('react-router').Navigation;
 var TokenContainer = require('./TokenContainer');
 var $ = require('jquery');
 var Translator = require('../../utils/Translator.js');
 var Search = React.createClass({
     contextTypes: {
         router: React.PropTypes.func
     },
     mixins: [require('../mixins/HoverMixin'), Transition],
     getInitialState: function() {
         return {
             tags: [],
             lastToken: "",
             routeNext: "",
             closed: true
         };
     },
     componentDidMount: function() {
         this._onChange();
         SearchStore.addTokenChangeListener(this._onTokenChange);
         SearchStore.addSearchClosedListener(this._onClosedChange);
         TagStore.addChangeListener(this._onChange);
         var self = this;

        $('.search-input').on("keydown", function(e) {
                
                if (e.keyCode === 13) {
                  //self.handleSearchClick();
                  e.preventDefault();
                 // self.addSearchString($('.result-item')[0].firstChild.innerHTML);
                }
            });

     },
     toggleOpen: function() {
         var searchContain = this.refs.searchContain.getDOMNode();
         var searchInput = $(".search-input")[0];
         var searchForm = this.refs.searchForm.getDOMNode();
        
         if (searchInput.value.length > 0 && $('.result-item')[0] !== undefined ) {
            this.addSearchString($('.result-item')[0].firstChild.innerHTML);
         } else {
             if (this.state.hoverState === false) {
                 if (this.props.windowWidth > Config.mobileBreak) {
                     TweenLite.to(searchContain, 0.2, {
                         width: "27%"
                     });
                     $('.search-input').focus();
                     this.setHover();
                 } else {
                     TweenLite.to(searchContain, 0.2, {
                         width: "100%"
                     });
                     $('.search-input').focus();
                     this.setHover();
                 }
             } else {
                 TweenLite.to(searchContain, 0.2, {
                     width: "0"
                 });
                 this.setHoverFalse();
             }
         }
     },
     handleSearchClick: function(){
        SearchActionCreator.toggleSearch(!SearchStore.getClosed());
     },
     componentWillUnmount: function() {
         TagStore.removeChangeListener(this._onChange);
         SearchStore.removeTokenChangeListener(this._onTokenChange);
         SearchStore.removeSearchClosedListener(this._onClosedChange);
     },
     justARoute: function(str) {
        return !(str !== "work" && str !== "news" && str !== "knowledge");
     },
     componentDidUpdate: function(prevProps, prevState) {
        if(this.state.closed !== prevState.closed){
            this.toggleOpen();
   
        } else if (prevState.routeNext !== this.state.routeNext && this.state.routeNext !== "") {
            //console.log("about to decide on routenext");
             if (this.justARoute(this.state.routeNext)) {
              
                var nextRoute = this.state.routeNext === "Cannes 2015" ? "cannes" : this.state.routeNext;
                 this.context.router.transitionTo(nextRoute, {lang: Config.lang});
             } else {
                 this.context.router.transitionTo('search', {
                     tags: this.state.routeNext,
                     lang: Config.lang
                 });
             }
             this.state.routeNext = "";
         }
     },
     removeToken: function(state) {
       
        // this.refs.tokenizer._removeTokenForValue(state);
     },
     addSearchString: function(token) {
         
         SearchActionCreator.addToken(token);
         this.setState({
             lastToken: token
         });
     },
     removeSearchString: function(token) {
         
         SearchActionCreator.removeToken(token);
         this.setState({
             lastToken: ''
         });
     },
     _onTokenChange: function() {
        
         this.setState({
            closed: SearchStore.getClosed(),
         });
         
        this.refs.tokenizer.setTokens(SearchStore.getTokens());
         if (!SearchStore.getQuiet()) {
             var routeToBeAdded;

             var path = /\/([^\/]+)/.exec(this.context.router.getCurrentPath());
             var whichDetailPage = path ? path[1] : "";
             if (whichDetailPage === "work" || whichDetailPage === "cannes" || whichDetailPage === "knowledge" || whichDetailPage === "news") {
                 routeToBeAdded = whichDetailPage !== "cannes" ? whichDetailPage : "Cannes 2015";
             }

             var tempTokens = SearchStore.getTokens().slice(0);
             if (routeToBeAdded) {
                 tempTokens.unshift(routeToBeAdded);
             }

             if (whichDetailPage !== "search") {
                //console.log("going to set routenext",tempTokens)
                 this.setState({
                     routeNext: tempTokens.join('+')
                 });
             }
         }
     },

     render: function() {
         var i18n = Translator.getInstance();
         var self = this;
         return (<div className="searchTag-container">
  <div className="search" ref="searchContain">
    <form ref="searchForm">
      <Tokenizer 
      ref="tokenizer"
      options={this.state.tags}

      maxVisible= {4}
      customClasses={{
        typeahead: "search-and-token-contain",
        input: "search-input",
        results: "search-result-container",
        listItem: "result-item",
        token: "result-token",
        customAdd: "topcoat-addme"
      }}
      placeholder={
      // TRANSLATORS: Search placeholder
        i18n.gettext('Search')
     }
          onTokenAdd={function(token) {
            self.addSearchString(token);
      }}
      onTokenRemove={function(token){self.removeSearchString(token)}}/>
    </form>
    <span className="icon-search" onClick={this.handleSearchClick} ></span>
  </div>
  
 
</div>);
     },
     _onChange: function() {
       
         this.setState({
             tags: TagStore.getTags()
         });
     },
     _onClosedChange: function(){
       
        this.setState({
            closed: SearchStore.getClosed(),
        })
     }
 });
 module.exports = Search;
