var React = require('react');    
var Config = require('../../Config');
var _ = require('lodash');
var PersonThumb = require('./PersonThumb');
var PeopleActionCreator = require('../actions/PeopleActionCreator');

//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

function generatePersonList(collection){
    var result = [];

    if (collection && collection.length) {
        result = _.map(collection, function(value){
            return (
                <PersonThumb key={value.id} 
                             data-id={value.id}
                             data={value}
                             onClick={this.personClickHandler} />
            );
        }.bind(this))
    }
    return result;
}

//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------


var PeopleGrid = React.createClass({

    mixins:[require('./mixins/AnimationMixin')],
    
    //----------------------------------------
    //  Life cycle
    //----------------------------------------

	render: function(){
        var personList = generatePersonList.call(this, this.props.data);
        return (
            <div className="people-grid">
                {personList}
            </div>
        );
	},

	//----------------------------------------
    //  Event handlers
    //----------------------------------------

    personClickHandler: function(event) {
        var id = $(event.currentTarget).data('id');
        if (id){
            PeopleActionCreator.showPersonDetails(id);
            $('html, body').animate({scrollTop: '0px'}, 500);
        }
    }

});

module.exports = PeopleGrid;