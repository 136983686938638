/** @jsx React.DOM */
var React = require('react');

var LocationRegionButton = React.createClass({
componentsDidMount : function(){
},
handleClick : function(n, ref){
	this.props.onUserSelect(n);
},

render: function(){
	 var style = { };
      if(this.props.hide){
          style.display = "none";
      }else{
          style = {};
      }
  return (
  	<div className="region-button-container" style={style}>
   		<div  ref="some" className={this.props.regionIndex===5?"active region-title-box":"region-title-box"} onClick={this.handleClick.bind(null, 5)}><div className="region-title">North America</div></div>
      <div ref="some"  className={this.props.regionIndex===3?"active region-title-box":"region-title-box"} onClick={this.handleClick.bind(null, 3 )}><div className="region-title">Latin America</div></div>
      <div ref="some" className={this.props.regionIndex===2?"active region-title-box":"region-title-box"}  onClick={this.handleClick.bind(null, 2 )}><div className="region-title">Europe</div></div>
      <div ref="some" className={this.props.regionIndex===4?"active region-title-box":"region-title-box"}  onClick={this.handleClick.bind(null, 4 )}><div className="region-title">Middle East & <span>Africa</span></div></div>
   		<div ref="some"  className={this.props.regionIndex===0?"active region-title-box":"region-title-box"} onClick={this.handleClick.bind(null, 0 )}><div className="region-title">Asia</div></div>
      <div ref="some"  className={this.props.regionIndex===1?"active region-title-box":"region-title-box"} onClick={this.handleClick.bind(null, 1 )}><div className="region-title">Australia &  <span>New Zealand</span></div></div>
  	</div>
  );
}
});

module.exports = LocationRegionButton;
