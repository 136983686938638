
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  getDetailsFor: function(contentType,id) {
  	  
    AppDispatcher.handleViewAction({
      type: "GET_DETAILS_FOR",
      contentType: contentType,
      id: id
    });
  }
  
};