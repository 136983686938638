/** @jsx React.DOM */
var React = require('react');    



var Agency = React.createClass({
	
	render: function(){
			var classed = "agency";

			if(this.props.selectedIndex === this.props.keyIndex){
				classed += " active"
			}
			var phoneIfExists = "";

			if(this.props.phone && this.props.isMobile){
				var telHref= "tel:"+this.props.phone;
				phoneIfExists = <span><a href={telHref}>{this.props.phone}</a></span>;
			} else {
				phoneIfExists = <span>{this.props.phone}</span>
			}
		return(

			<div className={classed} >
				<h3 onClick={this.props.onAgencySelect.bind(null, this.props.keyIndex)}  key={this.props.keyIndex} >{this.props.title}</h3>
				<div className="agency-info">
						<span>{this.props.addressOne}</span>
						<span>{this.props.addressTwo}</span>

						<span><a target="_blank" href={this.props.website}>{this.props.website}</a></span>
						{phoneIfExists}



					</div>
				</div>
				);
		return <div>{data}</div>;
	}

});

module.exports = Agency;