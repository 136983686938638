/** @jsx React.DOM */
var React = require('react');
var SocialLinks = require('./SocialLinks.js');
var FeedStore = require("../../stores/FeedStore");
var DetailStore = require("../../stores/DetailStore");
var LocationStore = require("../../stores/LocationStore");
var FooterAction = require("../../actions/LoadMoreActionCreator");
var PageStatusStore = require('../../stores/PageStatusStore');

var stores = {
  "LocationStore": LocationStore,
  "FeedStore": FeedStore,
  "DetailStore": DetailStore
}
var $ = require('jquery');
var Footer = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
  handleLoadMore : function(){
      FooterAction.load();
  },
  getInitialState: function(){
    return ({
      loadMoreActive: this.props.loadMore,
      showFooter: false,
      isPageReady: false,
    });
  },
  componentDidMount: function(){

    FeedStore.addChangeListener(this._onFeedChange);
    DetailStore.addChangeListener(this._onDetailChange);
    LocationStore.addChangeListener(this._onLocationChange);
    PageStatusStore.addChangeListener(this._onPageStateChange);
  },
  componentWillUnmount: function(){

      FeedStore.removeChangeListener(this._onFeedChange);
      DetailStore.removeChangeListener(this._onDetailChange);
      LocationStore.removeChangeListener(this._onLocationChange);
      PageStatusStore.removeChangeListener(this._onPageStateChange);
  },
  _onFeedChange: function(){
    //if(this.props.loadMore){
      this._onChange(FeedStore);
      this.setState({
        loadMoreActive: FeedStore.getMoreAvailable()
      });
   // }
  },
  componentWillReceiveProps: function(nextProps){
    //if(nextProps.footerStore !== this.props.footerStore){
      this.setState({
        showFooter: false //(stores[nextProps.footerStore]).contentIsLoaded()
      })
    //}
  },
  _onLocationChange: function(){
    this._onChange(LocationStore)
  },
  _onDetailChange: function(){
    this._onChange(DetailStore)
  },
  _onChange: function(store){
    //console.log(store.getName(),this.props.footerStore);
     if(store.getName() === this.props.footerStore){
    //   console.log("should show?",store.contentIsLoaded())
        this.setState({
          showFooter: store.contentIsLoaded()
        })
      }

  },
  _onPageStateChange: function(){
      this.setState({
        isPageReady: PageStatusStore.isPageReady()
      });
  },
  render: function(){
    if (!this.state.isPageReady) return (<div />);

    var returnValue = <div />;
    var divStyle = null;
    var type ="footer";
    var title = "Social";
    var loadMoreButton = <div className="load-more" />;
    if(this.props.loadMore && this.state.loadMoreActive){
      loadMoreButton =  ( <div className="load-more">
          <a onClick={this.handleLoadMore} style={divStyle}><img src="/assets/images/icons/load-more.svg" width="50" height="50" /></a>
        </div>)
    }
    if(true){
      returnValue = (
      <section  className="footer">
        <div className="factsheet-white">
          <a href="../cdn/bbdo_fact_sheet.pdf" target="_blank" download><span>Fact Sheet</span><span className="factsheet-icon"></span></a>
        </div>
        {loadMoreButton}
        <div className="social-container">
          <SocialLinks title={title} type={type} windowWidth={this.props.windowWidth} />
         </div>
      </section>
    );
  }
    return (
      returnValue
    );
  },
});

module.exports = Footer;
