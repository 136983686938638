/** @jsx React.DOM */
 var React = require('react');    

var ReadMore = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	var linkItem = <a className="work-btn" href={this.props.data.url} target="_blank">{this.props.data.name}</a>;
    	if(this.props.data.shouldDownload === true){
    		linkItem = <a className="work-btn" download href={this.props.data.url} target="_blank">{this.props.data.name}</a>
    	}	
      return(
      	<div className="toolbox-link">
      		{linkItem}
      	</div>
      	);
      }

});

module.exports = ReadMore;