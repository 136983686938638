/** @jsx React.DOM */
 var React = require('react');   
 var Translator = require('../../utils/Translator.js');

var ReadMore = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	var i18n = Translator.getInstance();
      return(
      	<div className="toolbox-link">
      		<a className="work-btn" href={this.props.data.url} target="_blank" download>{
		      // TRANSLATORS: Download PDF button
		        i18n.gettext('Download PDF')
     }</a>
      	</div>
      	);
      }

});

module.exports = ReadMore;