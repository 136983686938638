/** @jsx React.DOM */
 var React = require('react');    

var List = React.createClass({
mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	var listItems = this.props.data.map(function(result,i){
            var html = {__html: Config.stripTags(result)};
    			return (
            			<li key={i} dangerouslySetInnerHTML={html} />
        		);
    	});
    	return (
        <div className="toolbox-list-items">
            <ul>{listItems}</ul>
         </div>
        );
    }

});

module.exports = List;