var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var ServerConstants = require('../constants/ServerConstants');
var CareersConstants = require('../constants/CareersConstants');
var _ = require("lodash");


//TODO: Make filters key => value objects!

//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

var CHANGE_EVENT = 'change';
var _data = {};


//----------------------------------------
//  Methods
//----------------------------------------


function filterJobs(){
    var collection = _.cloneDeep(_data.jobs);

    if (_data.activeFilter) {
        collection = _.filter(collection, {"department": _data.activeFilter})
    }

    if (_data.searchValue){
        collection = _.filter(collection, function(value){
            return  value.description.toLowerCase().indexOf( _data.searchValue.toLowerCase() ) >= 0 ||
                    value.position.toLowerCase().indexOf( _data.searchValue.toLowerCase() ) >= 0;
        })
    }

    return _.pluck(collection, 'id');
}

function receiveData(data){
    var searchValue = _data.searchValue || "";
    var activeFilter = _data.activeFilter || "";

    _data = data;
    _data.searchValue = searchValue;
    _data.activeFilter = activeFilter;
    _data.activeJobs = filterJobs();

}

function selectFilter(value){
    _data.activeFilter = value;
    _data.activeJobs = filterJobs();
}

function updateSearch(value){
    _data.searchValue = value;
    _data.activeJobs = filterJobs();
}




//--------------------------------------------------------------------------
//
//  Public
//
//--------------------------------------------------------------------------

var CareersStore = assign({}, EventEmitter.prototype, {


    //----------------------------------------
    //  Event handling
    //----------------------------------------

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    /**
     * @param {function} callback
     */
    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener)
    },

    //----------------------------------------
    //  Data operations
    //----------------------------------------

    getData: function(){
        return _.cloneDeep(_data);
    },

    getJob: function(id) {
        console.log(_data);
        var entry = _.find(_data.jobs, {id: id});
        return _.extend( _.cloneDeep(entry), {image: _data.image, mobileImage: _data.mobileImage, email: _data.email});
    },


});

CareersStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {

        case ServerConstants.RECEIVE_CAREERS_DATA: {
            receiveData(action.data);
            CareersStore.emitChange();
            break;
        }

        case CareersConstants.SELECT_FILTER: {
            selectFilter(action.value);
            CareersStore.emitChange();
            break;
        }

        case CareersConstants.UPDATE_SEARCH: {
            updateSearch(action.value);
            CareersStore.emitChange();
            break;
        }


        default: {
            
            // do nothing
        }
    }

});

module.exports = CareersStore;