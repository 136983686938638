//Determines the Path and then forwards that data to the Dispatch//

var PageDispatcher = require('../dispatcher/PageDispatcher');
var PageStatusConstants = require('../constants/PageStatusConstants');

module.exports = {

	animationStarted: function() {
		PageDispatcher.handleViewAction({
			type: PageStatusConstants.ANIMATION_START,
		});
	},

	animationFinished: function() {
		PageDispatcher.handleViewAction({
			type: PageStatusConstants.ANIMATION_END,
		});
	},

	dataLoadStarted: function(){
		PageDispatcher.handleViewAction({
			type: PageStatusConstants.DATA_LOAD_START,
		});
	},

	dataLoadFinished: function(){
		PageDispatcher.handleViewAction({
			type: PageStatusConstants.DATA_LOAD_END,
		});
	},

	reset: function(){
		PageDispatcher.handleViewAction({
			type: PageStatusConstants.RESET,
		});
	}


};