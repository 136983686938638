 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('../mixins/HoverMixin');
 var TileMixin = require('../mixins/TileMixin');

        //----------- <Grid><News> ------------//
        var News = React.createClass({

            mixins: [HoverMixin,TileMixin, require('../mixins/MobileHoverMixin')],
            getInitialState: function() {

                return {

                    arWidth: 2,
                    arHeight: 1,
startedTransition:0
                };
            },
            hoverStateTrue: function(){
                var hoverState = this.refs.hover.getDOMNode();
                TweenLite.to( hoverState, 0.3, {opacity: "1" });
            },
            hoverStateFalse: function(){
                var hoverState = this.refs.hover.getDOMNode();
                TweenLite.to( hoverState, 0.3, {opacity: "0" });
            },
            render: function(){

            var sizeableStyle = this.getTileStyles();
            var outBound = <div></div>;
            sizeableStyle.backgroundImage = 'url(' + this.props.data.homeImage + ')';

            var theNextURL = this.props.data.externalURL ? this.props.data.externalURL : "/" + Config.lang + "/news/" + this.props.data.id;
            var windowTarget = this.props.data.externalURL ? "_blank" : "_self";
            if(windowTarget =="_blank"){
                outBound = <span className="outbound-link-icon"></span>
            }
            var classString = "item news";
            if (this.state.hoverState === true) {
                classString += ' title-overlay-active';
            }
            
                return(
                    <li className='gridItemTop'  style={this.props.styleObj}>
                        <a href={theNextURL} onMouseEnter={this.setHover} target={windowTarget} onMouseLeave={this.setHoverFalse} ref="item">
                            <div  ref="sizeable" className={classString} style={sizeableStyle}>
                                <div className="news-fade-hover" ref="hover">
                                    <div className="fade-text">
                                        <h3>{this.props.data.source}</h3>
                                         <h2>{this.props.data.title}</h2>
                                    </div>
                                    {outBound}
                                </div>
                            </div>
                        </a>
                    </li>
                    );
            }
        });
    //----------- <Grid></News> ------------//

    module.exports = News;
