/** @jsx React.DOM */
var React = require('react');    
var Agency = require('./Agency');


var CountryAgencyList = React.createClass({
	
	getInitialState: function(){
		return{
				clicked: false,
				prevElement: undefined
			};
	},

	render: function(){
		var self = this;
		var countryClass = "country-agency-list"
			if(this.props.agencyData.length === undefined ){
				var data = <div></div>

		}else{
			var data = this.props.agencyData.map(function(result,i){

				return(
					<Agency key={result.address1+i} agencyClass={this.state.classed} addressOne={result.address1} addressTwo={result.address2} website={result.website} isMobile={this.props.isMobile} phone={result.phone} title={result.name} keyIndex={result.id} selectedIndex={this.props.selectedIndex} onAgencySelect={this.props.onAgencySelect}></Agency>
				);
		}.bind(this));
		}
		if(this.props.selectedCountry === this.props.countries){
			countryClass += " country-active"
		}
		return (
			<div className="countries">
				<h1 onClick={this.props.countryExpand.bind(null, this.props.countries)} >{this.props.countries}</h1>
				<div className={countryClass}>
					{data}
				</div>
			</div>
		);
	}

});

module.exports = CountryAgencyList;