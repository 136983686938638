//------------- MIXIN --------------//
var gsap = require('gsap');
var PageStatusActionCreator = require('../../actions/PageStatusActionCreator');

var AnimationMixin = {

  _transitioning: false,
  _startedTransition: false,

  componentWillEnter: function(callback) {
    // console.log("got into cwe",this.props.componentName)
    this.doIntro(callback);
  },

  doIntro: function(callback) {
    var delay = 0;
    if (this.props.delay) {
      delay = this.props.delay;
    }

    TweenLite.to(this.getDOMNode(), 0, {
      opacity: 1,
      transform: 'translateY(500px) rotateY(90deg)'
    });
    TweenLite.delayedCall(this.props.delay, this.finishIntro);

    if (!this.props.preventCall) {
      PageStatusActionCreator.animationStarted();
    }
  },

  finishIntro: function() {
    TweenLite.set(this.getDOMNode(), {
      opacity: 1,
      transform: 'translateY(0px) rotateY(0deg)',
      '-webkit-transform': 'translateY(0px) rotateY(0deg)',
      transition: 'transform .5s',
      '-webkit-transition': '-webkit-transform .5s'
    });
    if (!this.props.preventCall) {
      PageStatusActionCreator.animationFinished();
    }
  },
  componentDidMount: function(callback) {
    ///console.log("got into mount",this.props.componentName)

    this.doIntro(callback);

  },
  componentWillAppear: function(callback) {
    //  console.log("got into cwa",this.props.componentName)
    this.doIntro(callback);

  },


}

module.exports = AnimationMixin;