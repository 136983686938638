/** @jsx React.DOM */
var React = require('react');
var CountryAgencyList= require('./CountryAgencyList');
var _ =require("lodash");
var LocationList = React.createClass({
	getInitialState: function(){
		return {
			selectedIndex: 0
		};
	},
	componentsDidMount : function(){
	},
	componentDidUpdate : function(prevProps, prevState) {

		if(this.props.regionIndex != prevProps.regionIndex) {
			this.setState({
				shouldExpand : this.props.regionIndex
			});
		}
	},

	onAgencySelect: function(n){
		if(n === this.state.selectedIndex){
			this.setState({
				selectedIndex : 0
			});
		}else{
			this.setState({
				selectedIndex : n
			});
		}
	},
	onCountrySelect: function(n){
		this.setState({
			shouldCountryExpand : n
		});
	},
	handleClick : function(n, e){
		this.props.onUserSelect(n);
	},
	render: function(){
	var self = this;
	var twoColumn = {};
			if(this.props.columns === 2){
				twoColumn = {
		          width: "40%",
		          margin: "0 5%"
		        };
			}
			var lengthsArray = [];
			var totalLength = 0;
			var nColumns = [];
			data = this.props.locationData.countries.map(function(result, index){
				lengthsArray[index] = result.agency.length;
				totalLength += result.agency.length;
				
				return(
					<CountryAgencyList isMobile={false} key={result.name} countryExpand={self.onCountrySelect} selectedIndex={this.state.selectedIndex} onAgencySelect={self.onAgencySelect} agencyData={result.agency}  countries={result.name} />
				);
			}.bind(this));
			var perColumn = Math.floor(totalLength / this.props.columns);

			var runningCount = 0;
			var nColumns = [];
			var currentColumn = 0;
			nColumns[currentColumn] = [];
			for(var i in data){

				if(runningCount >= perColumn && currentColumn <this.props.columns){
					currentColumn++;
					nColumns[currentColumn] = [];
					runningCount = 0;
				}
				nColumns[currentColumn].push(data[i]);
				runningCount += lengthsArray[i];
			}
			//var divisibleByN = Math.floor(data.length / this.props.columns);
			//nColumns = _.chunk(data, divisibleByN );
			 for(var i =0; i< nColumns.length;  i++) {
				nColumns[i] = <div style={twoColumn} className="location-col">{nColumns[i]}</div>

			 }

	return(
		<div className="location-grid">
			{nColumns}
		</div>
	);
}
});

module.exports = LocationList;
