/** @jsx React.DOM */
 var React = require('react');    

var AwardsList = React.createClass({
mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	var listItems = this.props.data.map(function(result,i){
            var html = {__html: Config.stripTags(result)};
    			return (
            			<li key={i} dangerouslySetInnerHTML={html} />
        		);
    	});
        var awardsListContainer = <div></div>;

        if(this.props.data.length > 0){
            awardsListContainer =  <div className="toolbox-list-items toolbox-awards-list"><h1>Awards</h1> <ul>{listItems}</ul></div>;
        }
    	return (
            awardsListContainer
        );
    }

});

module.exports = AwardsList;