 /** @jsx React.DOM */

var React = require('react');
var Router = require('react-router');
var Search = require('./Search');
var CannesNavMenu = require('../CannesPageModules/CannesNavMenu');
var CannesFilterNav = require('../CannesPageModules/CannesFilterMenu');
var MobileCannesFilterNav = require('../CannesPageModules/MobileCannesFilterMenu');
var MobileTokenContain = require('./MobileTokenContainer');
var Config = require('../../../Config');
var Link = Router.Link;
var openTimeout;
var closeTimeout;
        var MobileHeader = React.createClass({

            contextTypes: {
                router: React.PropTypes.func.isRequired
            },
            
            getInitialState: function(){
                return({
                    scrollPosition: 0,
                    showCurrentPageHeader : false,
                    pathName: undefined
                });
            },
            getPageName : function(){
                var path = this.context.router.getCurrentPath().substr(1).split("/").splice(1).join("/");
                path = path.split("/")[1];
                window.clearTimeout(openTimeout);
                window.clearTimeout(closeTimeout);
                return path;

            },
            componentDidUpdate: function(prevProps, prevState){
                var self = this;
                var path = this.context.router.getCurrentPath().substr(1).split("/").splice(1).join("/");
                path = path.split("/")[1];
                
                if(path !== this.state.pathName){
                    this.setState({
                        pathName: path
                    });
                    openTimeout   = window.setTimeout(function(){self.updatecurrentPageClass();}, 1000);
                    closeTimeout = window.setTimeout(function(){self.updatecurrentPageClass();}, 3000);

                }
              
                
                 
            },
            updatecurrentPageClass : function(){
                if(this.state.showCurrentPageHeader === false){
                    this.setState({
                        showCurrentPageHeader: true
                    });
                }else{
                    this.setState({
                        showCurrentPageHeader: false
                    });

                }
            },
            render: function(){ 
                var dynamicClass = 'header mobile-header';
                var CannesNav = '';
                var pageName = this.getPageName();
                var currentPageClass = 'current-page';
                var CannesFilterMenu = '';
                var MobileCannes = '';
                var TokenContain = '';
                var subPage = this.context.router.getCurrentPath().split("/")[1];
                if(subPage === "search"){
                    TokenContain = <MobileTokenContain windowWidth={this.props.windowWidth} />
                }
                if(this.state.showCurrentPageHeader === true){
                    currentPageClass += ' current-page-active';
                }
                if(this.props.cannesNavMenuVisible === true){
                    dynamicClass += " mobile-cannes-active";
                    
                    //CannesFilterMenu = <CannesFilterNav delay={1} />;
                    MobileCannes = <MobileCannesFilterNav shouldFilterNews={subPage!==Config.privateCannesString} delay={1} />
                }
                
                return ( 
                <div className={dynamicClass} ref="mainNav">
                    <div className="logo-search-contain">
                        <div className="logo-current-wrap">
                            <div className="logo-block">
                               <Link to="main" params={{lang: Config.lang}}> 
                                    <img className="mobile-logo" src="/assets/images/BBDO_logo_red.svg" alt="BBDO WorldWide" />
                                </Link>
                            </div>
                        </div>
                        <Search windowWidth={this.props.windowWidth} />
                    </div>
                    {TokenContain}
                    {MobileCannes}
                </div>
                );
            }
        });
//--------------- </MobileHeader> ----------------//

module.exports = MobileHeader;
