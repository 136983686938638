var _ = require('lodash');
var Config = require('../../Config')



//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------


//--------------------------------------------------------------------------
//
//  Public
//
//--------------------------------------------------------------------------

var DepartmentsTransform = {

	createData: function(data){
		data = data.data.departments;

		return _.map(data, function(value){
			return value[Config.lang].name
		});
	}
}

module.exports = DepartmentsTransform;
