   /** @jsx React.DOM */
var Config = require('../../../Config')
var React = require('react');    
var ContentBlockHolder = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
   	render: function(){
       
        return(
            <div className="content-block-header">
                {this.props.children}
            </div>
      	);
      }
});

module.exports = ContentBlockHolder;