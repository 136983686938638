var AppDispatcher = require('../dispatcher/AppDispatcher');
var CareersConstants = require('../constants/CareersConstants');


module.exports = {

    selectFilter: function(value) {
        AppDispatcher.handleViewAction({
            type: CareersConstants.SELECT_FILTER,
            value: value
        });
    },

    updateSearch: function(value){
        AppDispatcher.handleViewAction({
            type: CareersConstants.UPDATE_SEARCH,
            value: value
        });
    }
};