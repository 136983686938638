/** @jsx React.DOM */
var React = require('react');    
var _ = require('lodash');
var Config = require('../../../Config');
var ContentConverter = require('../../utils/ContentConverter');
var Translator = require('../../utils/Translator.js');


//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------


function generateItems(collection){
    var active = this.state.active;
    var result = [];
    var totalColsCount = 3;
    var isMobile = $(window).width() <= 680;
    var rows = Math.ceil(collection.length / totalColsCount);
    for (var i = 0 ; i < rows; i++){
        var startIndex = i * totalColsCount;
        var endIndex = Math.min( (i+1) * totalColsCount, collection.length )
        var cols = endIndex - startIndex;
        var activeContent = null;
        var rowResult = [];
        for (j = 0; j < cols; j++){
            var index = (i * totalColsCount) + j;
            var data = collection[index];
            rowResult.push( generateItem.call(this, data) );
            if (data.id == active) {
                activeContent = generateDetailsContent.call(this, data);
                if (isMobile){
                    rowResult.push( activeContent );
                }
            }
        }
        result.push(<div key={'row' + i} children={rowResult} />);
        if (activeContent && !isMobile) {
            result.push( activeContent );
        }
    }
    return result;
}

function generateItem(data){
    var className = 'client-thumb';
    var active = this.state.active;
    if (active && data.id !== active){
        className += ' disabled';
    }
    if(data.description != ''){
        var hoverable = ' hoverable'

         return (
        <div onClick={this.clientThumbClickHandler}
             key={data.id}
             data-id={data.id}
             className={className+hoverable} 
             title={data.name}
             alt={data.name}
             style={{backgroundImage: "url(" + data.image + ")"}}>
        </div>
         )
     }else{

        return (
        <div onClick={this.closeActive}
             key={data.id}
             data-id={data.id}
             className={className}
             title={data.name}
             alt={data.name} 
             style={{backgroundImage: "url(" + data.image + ")"}}>
        </div>
        )
     }
    
     //Show additional info on click - onClick={this.clientThumbClickHandler}>
}

function generateDetailsContent(data){
    var i18n = Translator.getInstance();
    var searchHref = "/" + Config.lang + "/search/" + data.name;  
    var content = {__html: ContentConverter.textToHtmlNewLines( data.description )};
    return (
        <div key={data.id-"content"} className="details-content">
            <p dangerouslySetInnerHTML={content} />
            <a href={searchHref} className="btn-work">{
             // TRANSLATORS: Client on click
              i18n.gettext('See work')
             }</a>
            <span className="btn-close" onClick={this.closeActive}></span>
        </div>
    );
}

//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------

var ClientGrid = React.createClass({
    mixins:[require('../mixins/AnimationMixin')],
  
    //----------------------------------------
    //  Life cycle
    //----------------------------------------

    getInitialState: function(){
        return {
            active: 0
        }
    },

    render: function(){
        var items = generateItems.call(this, this.props.data);

        return (
           <div className="client-grid">
                {items}
            </div>
        );
    },

    //----------------------------------------
    //  Event handlers
    //----------------------------------------

    clientThumbClickHandler: function(event){
        var id = $(event.currentTarget).data('id');
        if (id){
            this.setState({active: id});
        }
    },

    closeActive: function(event){
        this.setState({active: 0});
    }

});

module.exports = ClientGrid;