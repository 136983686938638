
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {


  open: function(imageURL){
  	AppDispatcher.handleViewAction({
  		type: "OPEN_LIGHTBOX",
  		imageURL: imageURL,
  	});
  },
  close: function(){
  	AppDispatcher.handleViewAction({
  		type: "CLOSE_LIGHTBOX",
  	});
  }
  
};
