 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('./mixins/HoverMixin.js');
 var Cannes = require('./Cannes.js');
 var Grid = require('./Grid.js');
 var _ = require('lodash');
 var FeedStore = require('../stores/FeedStore');
 var gridActionCreator = require("../actions/GridActionCreator");
 var SearchActionCreator = require("../actions/SearchActionCreator");
 var FilterPage = React.createClass({
     getInitialState: function() {
         return {
             data: [],
             oneTimeShift: true,
         }
     },
     contextTypes: {
         router: React.PropTypes.func.isRequired
     },
     componentWillUnmount: function() {
         FeedStore.removeChangeListener(this._onChange);
     },
     getFilterFromPath: function() {
         var path = this.context.router.getCurrentPath().substr(1).split("/").splice(1).join("/");
         return (path === "" ? "all" : path);
     },
     componentDidMount: function() {
         SearchActionCreator.toggleSearch(true);
         FeedStore.addChangeListener(this._onChange);
         //gridActionCreator.selectSort("");
         gridActionCreator.selectFilter(this.getFilterFromPath())
     },
     render: function() {
         var filter = this.getFilterFromPath();

         return (<div className={filter == "all" ? "home-grid-page" : "grid-page nav-space"}>
       <Grid gridData={this.state.data} gridRelated={false} windowWidth={this.props.windowWidth} gridContentType={filter} />
       </div>);
     },
     _onChange: function() {
         var newGridNodes = FeedStore.getInterleavedFeed().slice();
         var nextOneTimeShift = this.state.oneTimeShift;
        // console.log("NGN",_.pluck(newGridNodes,'creation_timestamp'));
         if(this.props.hasMarqueeOnTop && newGridNodes.length > 0){
           
           newGridNodes.shift();
          //  console.log("Shiftingd",_.pluck(newGridNodes,'creation_timestamp'));
            nextOneTimeShift = false;
         }
       
         this.setState({
             data: newGridNodes,
             oneTimeShift: nextOneTimeShift
         });
     },
 });
 module.exports = FilterPage;
