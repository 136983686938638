var $ = require('jquery');
//------------- MIXIN --------------//
var AnimationFadeMixin = {
    _transitioning:false,
   componentWillEnter: function(callback){
       
       this.doIntro(callback);  
   },
   doIntro: function(callback){
    if(!this._transitioning){
        this._transitioning = true;
        $(this.getDOMNode()).css('opacity','1');
        TweenLite.from(this.getDOMNode(),.5,{opacity:0,delay:this.props.delay,overwrite:true,onComplete:function(){this._transitioning = false}});
        if(callback){callback();}
       }
     },
   componentDidMount: function(callback){
       this.doIntro(callback); 
   },
    componentWillAppear: function(callback){
      this.doIntro(callback); 
   },
   

}

module.exports = AnimationFadeMixin;