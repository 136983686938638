/** @jsx React.DOM */
var React = require('react');    
var SocialLinks = require('./SocialLinks.js');
var ParallaxStore = require('../../stores/ParallaxStore');
var ShareActionCreator = require('../../actions/ShareActionCreator');
var ContentConverter = require('../../utils/ContentConverter');
var PageStatusStore = require('../../stores/PageStatusStore');

 var $ = require('jquery');
  function checkForSmoothScroll(){
    var scrollValue = ParallaxStore.get();
      return scrollValue;
 }
var ShareLinks = React.createClass({
  getInitialState : function (){
    return{
      isMenuOpen: false,
      scrollPosition: 0,
      bottomofPage: false,
      isPageReady: false,
      scrollAfterReady: false,
    };

  },
  componentDidMount : function(){
    ParallaxStore.addChangeListener(this._onChange);
    PageStatusStore.addChangeListener(this._onPageStatusChange);
    //window.requestAnimationFrame(this.evalPosition);
  },
  componentWillUnmount : function(){
    ParallaxStore.removeChangeListener(this._onChange);
    PageStatusStore.removeChangeListener(this._onPageStatusChange);
  },
	shareMenu: function(){
		$('.social-links').fadeIn();
	},
	socialClick: function(){

    	//old share code
      //ShareActionCreator.setMenuState();
    	//ShareActionCreator.setMenuType("share");
  	},
    toggleMenu : function(){
      if(this.state.isMenuOpen === false){
        this.setState({
          isMenuOpen: true
        });
      }else{
        this.setState({
          isMenuOpen: false
        });
      }
    },
    _onChange: function(){
      if (this.state.isPageReady) {
        if(this.props.shouldUseSmoothScroll === true){
          this.calculateSharePositionforParallax();
        } else{
            TweenLite.set(this.refs.shareContainer.getDOMNode(), {
              y:  "none", top: "auto"
            });
        }
        this.scrolling();
      }
    },
    _onPageStatusChange: function(){
      var isPageReady = PageStatusStore.isPageReady();
      var obj = {
        isPageReady: PageStatusStore.isPageReady()
      };
      if (!isPageReady) obj.scrollAfterReady = false;
      this.setState(obj);
    },
    calculateSharePositionforParallax : function(){
      var windowHeight = $(window).height() - 70;
      if(this.state.smoothScrollVal !== checkForSmoothScroll()){
        
      var sharePosition = checkForSmoothScroll() * -1;
        TweenLite.set(this.refs.shareContainer.getDOMNode(), {
          y:  sharePosition, top: windowHeight
        });
      }
    },
    scrolling: function(){
      if(this.state.scrollPosition !== window.scrollY || !this.state.scrollAfterReady){
        if (this.state.isPageReady) this.setState({scrollAfterReady: true});

        var scroll = window.scrollY + 70;
        var documentBottom = $(document).height() - $(window).height();

        if(scroll >= documentBottom ){
          this.setState({
            bottomofPage:true
          });
        }else{
          this.setState({
            bottomofPage:false
          });
        }
        
        this.setState({
          scrollPosition : window.scrollY
        });
      }
    },
    scrollUp: function(){
      $('html, body').animate({scrollTop: '0px'}, 500);
      this.setState({
          isMenuOpen:false
      });
    },
    windowPopup: function(url){
       popupWindow = window.open(
           url,'popUpWindow','height=700,width=700,left=10,top=10,resizable=0,scrollbars=1,toolbar=1,menubar=no,location=no,status=yes');
    },
    handleSocial: function(type){
      var url = encodeURIComponent(this.props.data.url);
      var title = encodeURIComponent(this.props.data.title);
      var description = ContentConverter.stripHTML(this.props.data.description) || "";
      description = encodeURIComponent(description.slice(0,65).concat("..."));
      var source = encodeURIComponent(this.props.data.image);
      switch(type){
        case "facebook":
          this.windowPopup("https://www.facebook.com/sharer/sharer.php?u=" + this.props.data.url );
        break;
        case "linkedin":

          this.windowPopup("https://www.linkedin.com/shareArticle?mini=true&url=" + this.props.data.url + "&title=" + title + "&summary="+ description + "&source="+ source +"" );
        break;
        case "twitter":
          this.windowPopup("https://twitter.com/intent/tweet?text=" + title + ": " + description + "&url=" + this.props.data.url + "");
        break;
      }
    },
    render: function(){
      if (!this.state.isPageReady) return (<div />);

      var classString = '';
      var containerClass = 'share-container';

      var styleObject = {
        display: "block",
        opacity: 1
      };
      if(this.state.isMenuOpen === true){
        classString += " menu-active ";
      }
      if(this.state.bottomofPage === true){
        containerClass += " cover-footer";
      }
		 	var title = "Share";
		 	var type = "secondaryPage";
      return (
        <div className="share" style={styleObject} ref="shareContainer">
          <div className={containerClass}>
            <span className="back-top box" onClick={this.scrollUp}><a href="javascript:void(0);"><i className="fa fa-sort-asc"></i></a></span>
              <ul className={classString}>
                <li className="box " onClick={this.handleSocial.bind(null, "facebook")}><a href="javascript:void(0);"><i className="fa fa-facebook"></i></a></li>
                <li className="box " onClick={this.handleSocial.bind(null, "twitter")}><a href="javascript:void(0);"><i className="fa fa-twitter"></i></a></li>
                <li className="box " onClick={this.handleSocial.bind(null, "linkedin")}><a href="javascript:void(0);"><i className="fa fa-linkedin"></i></a></li>
              </ul>
            <span className="box main-share"><a href="javascript:void(0);" onClick={this.toggleMenu}><i className="fa fa-share-alt"></i></a></span>
          </div>
        </div>
      );
    },

});

module.exports = ShareLinks;