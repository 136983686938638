/** @jsx React.DOM */
 var React = require('react');
var CannesStore = require('../../stores/CannesStore.js');
 var CannesFilterNav = require('./CannesFilterMenu');
var Config = require('../../../Config');
function getDataFromCannesStore(){
    return CannesStore.get();
}
//--------------- <Cannes> ----------------//
var CannesNavMenu = React.createClass({
      contextTypes: {
          router: React.PropTypes.func.isRequired
      },
     mixins: [require('../mixins/AnimationFadeMixin')],
      getInitialState : function (){
          return{
                data: [],
                classObject : "cannes-lion-nav-menu",
                sortState: false
          };

      },
      componentDidMount : function(){
          this._onChange();
          CannesStore.addChangeListener(this._onChange);
      },
      componentWillUnmount : function(){
          CannesStore.removeChangeListener(this._onChange);
      },
      sortActive: function() {
          if(this.state.sortState === false){
            this.setState({
              sortState: true
            });
          }else{
            this.setState({
              sortState: false
            });
          }
      },


    render: function(){
        var cannesStyle = this.state.classObject;
        var displayStyle = {};
        var Awards = <div></div>;
        var windowWidth = this.props.windowWidth < 640;
        var awardsContainClass = "cannes-awards-wrap";
        var awardsColor = {
          bronze: "#B96E2B" ,
          silver: "#C0BEC2" ,
          gold: "#EAC750",
          grandPrix: "#E9E831",
          special: "#fff"
        };
        var cannesTitle = {
          bronze: "Bronze" ,
          silver: "Silver" ,
          gold: "Gold",
          platinum: "Platinum",
          grandPrix: "Grand Prix",
          special: "Special Awards"
        }
        if(this.state.data.cannes2015){
          var list_of_awards = this.state.data.cannes2015[0][Config.lang].list_of_awards;

            if(this.state.data.length <= 3){
              awardsContainClass += ' award-count-sm';
            }else if(this.state.data.cannes2015.length > 3 && this.state.data.cannes2015.length < 6){
              awardsContainClass += ' award-count-md';
            }else {
              awardsContainClass += ' award-count-lg';
            }
            if(this.props.mobile === true){
              Awards = list_of_awards.map(function(result,i){
                if(Math.floor(result.number) > 0 ){
                  return(
                   <div className="award" key={i}>
                      <span className="award-icon"><img src={result.image} /></span>
                      <span style={{color: awardsColor[result.name]}}>{cannesTitle[result.name]}</span>
                      <span className="number">{result.number}</span>
                    </div>
                  );
                }
              });
            }else{
              Awards = list_of_awards.map(function(result,i){
                //console.log(result);
                if(Math.floor(result.number) > 0 ){
                  return(
                   <div className="award"  key={i}>
                      <span style={{color: awardsColor[result.name]}}>{cannesTitle[result.name]}</span>
                      <span className="number">{result.number}</span>
                    </div>
                  );
                }
              });
            }
          }
        return (
             <div className={cannesStyle} ref='cannesSection'>
                <div className="two-box cannes-lion-icon"></div>

                <div className="six-box cannes-lion-awards">
                    <div className="headline">
                      <h2>Cannes Lions Live Results</h2>
                    </div>
                  <div className={awardsContainClass}>
                    <div className="cannes-awards-contain">
                      {Awards}
                    </div>
                  </div>
                </div>
                <div className="two-box sort-cannes">
                  <span className="work-btn" onClick={this.sortActive}>Sort</span>
                </div>
                <CannesFilterNav shouldFilterNews = {this.props.shouldFilterNews} sortState={this.state.sortState} sortStateFunc={this.sortActive} />
            </div>
        );
    },
      _onChange: function() {

        this.setState({
          data :  getDataFromCannesStore()
        });

    }

});
//--------------- </Cannes> ----------------//

module.exports = CannesNavMenu;
