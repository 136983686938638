/** @jsx React.DOM */
var React = require('react');
var LightboxStore = require('../../stores/LightboxStore');
var LightboxActionCreator = require('../../actions/LightboxActionCreator')
var Router = require('react-router');
var $ = require('jquery');
var Config = require('../../../Config');
var LightboxController = React.createClass({
contextTypes: {
        router: React.PropTypes.func
      },
	getInitialState:function(){
		return {
				imageURL: null,
				open: false
				}
		
	},
	componentWillUnmount: function() {
	    LightboxStore.removeChangeListener(this._onChange);
        $(document).off('keyup',this.removeOnKeyup);
	},
	componentDidMount : function(){
	   $(document).on('keyup',this.removeOnKeyup);	
    	LightboxStore.addChangeListener(this._onChange);
	},
    removeOnKeyup: function(e){
            if (this.state.open && (e.keyCode === 27 || e.keyCode === 8)) {
                this.close();
            }

       
    },
	_onChange: function(){
		this.setState({
			imageURL: LightboxStore.getImage(),
			open: LightboxStore.isOpen(),
		});
	},

	close:function(){
		LightboxActionCreator.close();
		if(this.state.url && window.location !== Config.lang + this.state.url){
			window.location = Config.lang + this.state.url;
		}
	},
    render: function(){
    	//ESC out of video
    	var self = this;

        if(this.state.open === true && Config.msieversion() !== 10){
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.overflow = "visible";
        }
    	var styleObj = {
    		width:'100%',
    		height:'100%',
    		position:'fixed',
    		display: this.state.open ? 'block' : 'none',
    		top:0,
    		left:0,
    		backgroundColor: 'rgba(0,0,0,0.9)',
    		color:'white',
    		zIndex: 11111
    	};
        var content = '';
    	if(this.state.imageURL){
            content = (<img src={this.state.imageURL} alt="" />);
    	}
    	return (
        	<div style={styleObj}>
                <div className="lightbox-content">
                    <span className="vertical-helper"></span>
                    {content}
                </div>
				<span className="video-modal-close" onClick={this.close} ></span>
			</div>
        );
    }
});
module.exports = LightboxController;
