/** @jsx React.DOM */
var React = require('react');
var ParallaxStore = require('../../stores/ParallaxStore');
var ParallaxImage = React.createClass({
	mixins:[require('../mixins/AnimationMixin')],
	getInitialState: function() {

        return {
        	translateYPosition: 0
            };
    },
	componentDidMount : function(){
		ParallaxStore.addChangeListener(this._onChange);
		//window.requestAnimationFrame(this.evalPosition);
	},
	componentWillUnmount : function(){
		ParallaxStore.removeChangeListener(this._onChange);
	},
	_onChange: function(){
		var self = this;
		self.evalPosition();
	},
	evalPosition : function(){
		var containerTop = this.refs.imageContainer.getDOMNode().getBoundingClientRect().top;
		var containerBottom = this.refs.imageContainer.getDOMNode().getBoundingClientRect().bottom;
		var containerHeight = containerBottom - containerTop;
		var windowBottom = window.innerHeight;
		var imageTop = this.refs.parallaxImage.getDOMNode().getBoundingClientRect().top;
		var range = containerHeight + windowBottom;
		var containerInRange = containerTop + containerHeight;
		var ratio = containerInRange/range;
		var imageCutoff = -133 * (this.refs.parallaxImage.getDOMNode().getBoundingClientRect().width/1280);
		this. setState({
			translateYPosition: ratio * imageCutoff + 'px'
		});
		 

	},
    render: function(){
    		styleObj={
    			transform: "translateY(" + this.state.translateYPosition + ")",
    			WebkitTransform: "translateY(" + this.state.translateYPosition + ")",
    			msTransform: "translateY(" + this.state.translateYPosition + ")"

    		}
    	
    		 return (
        	<div className='toolbox-parallax-image'>
					<div ref="imageContainer" className="extra-image">
			      <img style={styleObj} ref="parallaxImage" className="parallax" src={this.props.data.image}  />
			</div>
			<div className="extra-image-description">
			      <span>{this.props.data.description}</span>
			</div>
		</div>
        );
    	
    }
});
module.exports = ParallaxImage;