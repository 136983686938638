/** @jsx React.DOM */
var React = require('react');  

var mapStyles = [
    {
        "featureType": "landscape",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 65
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 51
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 30
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 40
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": -25
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#fbfbfb"
            },
            {
                "lightness": 0
            },
            {
                "saturation": 0
            }
        ]
    }
];
var Map = React.createClass({

                    loadScript:function() {
                        var self = this;
                        window.initializemap = function(){
                              
                                
                                    self.createMap();
                                
                            
                        };
                        
                            var script = document.createElement('script');
                            script.type = 'text/javascript';
                            script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
                            '&callback=initializemap';
                            document.body.appendChild(script);
                    },

                    createMap: function(){
                            var mapOptions = {
                            center: this.mapCenterLatLng(),
                            zoom: this.props.mapData.zoom,
                               scrollwheel: false,
                               styles : mapStyles
                            },
                            map = new google.maps.Map(this.getDOMNode(), mapOptions);
                            var marker = new google.maps.Marker({ position: this.mapCenterLatLng(), map: map});
                            this.setState({map: map});
                    },

                    componentDidMount: function () {
                        if(!window.initializemap){
                            this.loadScript();
                        } else {
                            this.createMap();
                        }
          
                    },
 
                    mapCenterLatLng: function () {
                            var props = this.props;
                            return new google.maps.LatLng(props.mapData.latitude, props.mapData.longitude);
                            
                    },
                    render: function () {
                            var style = {
                                    width: '100%',
                                    height: '500px'
                            };
                            if(this.props.isMobile){
                                    style = {
                                        width:'100%',
                                        height: '200px'
                                    };
                            }
 
                            return (
                                    <div className='map' style={style}></div>
                            );
                    }
            });
 


module.exports = Map;