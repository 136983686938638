var _ = require('lodash');
var Config = require('../../Config');




//--------------------------------------------------------------------------
//
//  Private
//
//--------------------------------------------------------------------------

function getImage(ratio, data) {
	var url = '';
	try {
		var urlSuffix = data[0].data[ratio].url;
		if (urlSuffix.length){
			url = Config.CdnBaseURL + urlSuffix;
		}
	} catch (e) {}

	if (!url.length) {
		url = Config.placeholder[ratio];
	}

	return url;
}

function getJobsList(collection) {
	return _.map(collection, function(value){
		var generalData = value[Config.lang];
		return {
			id: value.id,
			department: generalData.department,
			description: generalData.description,
			position: generalData.position,
			postDate: value.posted_timestamp
		};
	});
}

//--------------------------------------------------------------------------
//
//  Public
//
//--------------------------------------------------------------------------

var CareersTransform = {

	createData: function(data){
		data = data.data.careers[0];
		var generalData = data[Config.lang];

		return {
			summary: generalData.summary,
			email: data.apply_email,
			image: getImage('3x1_large', generalData.list_of_media),
			mobileImage: getImage('3x2', generalData.list_of_media),
			jobs: getJobsList(data.list_of_jobs)
		};
	}
}

module.exports = CareersTransform;
