 /** @jsx React.DOM */
var React = require('react');
var HoverMixin = require('../mixins/HoverMixin');
var TileMixin = require('../mixins/TileMixin');
var Link = require('react-router').Link;
//----------- <Grid><Video> ------------//
var ThreeByTwo = React.createClass({
    mixins: [HoverMixin,TileMixin, require('../mixins/MobileHoverMixin')],
    getInitialState: function() {

        return {

            arWidth: 3,
            arHeight: 2,
 
        };
    },
    toggleVideo : function(hover){
        var video;
        if(this.refs.video){
            video = this.refs.video.getDOMNode();
            if(hover === true){
                video.play();
            }else if(hover === false){
                video.pause();
            }
        }
    },
    componentDidUpdate: function(prevProps, prevState) {
         this.toggleVideo(this.state.hoverState);
    },

    render: function(){
        var paramObject = {id:this.props.data.id, lang: Config.lang};
        var divStyle = {
            width: this.calculateNBox(this.state.arWidth,this.props.tileUnit)/16 + "em",
            height: this.calculateNBox(this.state.arHeight,this.props.tileUnit)/16 + "em",
        };
        return(
            <li  className='gridItemTop' style={this.props.styleObj}>
                <Link  to={this.props.tileType!='tweet'? this.props.tileType + 'Detail' : '/'} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item">
                    <div ref="sizeable" className="item three-by-two" style={divStyle}>
                        <div className="title-overlay">
                            <span className="title">{this.props.data.title}</span>
                        </div>
                        <img src={this.props.data.homeImage} />
                    </div>
                </Link>
            </li>
        );
    }
});
    //----------- <Grid></Video> ------------//

module.exports = ThreeByTwo;
