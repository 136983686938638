var ServerActionCreators = require('../actions/ServerActionCreators');
var PageStatusActionCreator = require('../actions/PageStatusActionCreator');
var $ = require('jquery');

module.exports = {
    
    encodeForProxyIfNecessary: function(url){
        var retval = url;
        var ms = Config.msieversion();
        if ((ms == 9 || ms == 10 || ms == 11) && url.substring){
            url = encodeURIComponent(url);
            retval =  Config.proxy + url;
        }
        return retval;
    },
    getAllNodes: function(constructedURL) {
        PageStatusActionCreator.dataLoadStarted();
        var src = this.encodeForProxyIfNecessary(constructedURL);
        var rawNodes = $.get(src, function(result) {
            ServerActionCreators.receiveAll(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },
    getDetailNodes: function(contentType, id, sessionKey) {
        PageStatusActionCreator.dataLoadStarted();
        var feed = Config.workdetailfeed;
        var useLocal = false;
        var url = Config.apiBaseURL + Config.apiDetail + id;


        if(sessionKey.length > 0){
            url +='&auth_token=' + sessionKey;
        }
        if (id.length > 5) { // IS ACTUAL API
            feed = url;
        } else {
            useLocal = true;
            feed = Config[contentType + 'detailfeed'];
        }
        if(contentType === 'about'){
            feed = Config.apiBaseURL + Config.aboutdetailfeed;
        } 
        feed = this.encodeForProxyIfNecessary(feed);
        var rawNodes = $.get(feed, function(result) {
            ServerActionCreators.receiveDetails(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        }).fail(function(){ 
            //window.location.href = "/";
            PageStatusActionCreator.dataLoadFinished();
         });
    },
    getLocationNodes: function() {
        PageStatusActionCreator.dataLoadStarted();
        var rawNodes = $.get(Config.locationfeed, function(result) {
            ServerActionCreators.receiveLocations(result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },
    getCannesNode: function() {
        /*var rawNodes = $.get(this.encodeForProxyIfNecessary(Config.apiBaseURL + Config.cannesAwardCounterFeed), function(result) {
            ServerActionCreators.receiveCannes(result.status && result.status.http_code===200 ? result.contents : result);
        });*/
    },
    getClientNodes: function() {
        PageStatusActionCreator.dataLoadStarted();
        var rawNodes = $.get(this.encodeForProxyIfNecessary(Config.client), function(result) {
            PageStatusActionCreator.dataLoadFinished();
            ServerActionCreators.receiveClientDetails(result);
        });
    },
    getSearchTags: function(){
        PageStatusActionCreator.dataLoadStarted();
        var rawNodes = $.get(this.encodeForProxyIfNecessary(Config.apiBaseURL + Config.apiTagsURL), function(result){
            ServerActionCreators.receiveSearchTags(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
     
    },
     getSearchNodes: function(constructedURL) {
        PageStatusActionCreator.dataLoadStarted();
        var src = constructedURL;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(src), function(result) {
            ServerActionCreators.receiveSearchNodes(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },

    getPeopleData: function(){
        PageStatusActionCreator.dataLoadStarted();
        var url = Config.apiBaseURL + Config.peopleFeed;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(url), function(result) {
            ServerActionCreators.receivePeopleData(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },

    getContactData: function() {
        PageStatusActionCreator.dataLoadStarted();
        var url = Config.apiBaseURL + Config.contactFeed;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(url), function(result) {
            ServerActionCreators.receiveContactData(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },

    getCareersData: function(){
        PageStatusActionCreator.dataLoadStarted();
        var url = Config.apiBaseURL + Config.careersFeed;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(url), function(result) {
            ServerActionCreators.receiveCareersData(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },

    getDepartmentsData: function(){
        PageStatusActionCreator.dataLoadStarted();
        var url = Config.apiBaseURL + Config.departments;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(url), function(result) {
            ServerActionCreators.receiveDepartmentsData(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },

    getJobsData: function(){
        PageStatusActionCreator.dataLoadStarted();
        var url = Config.apiBaseURL + Config.jobs;
        var rawNodes = $.get(this.encodeForProxyIfNecessary(url), function(result) {
            ServerActionCreators.receiveJobsData(result.status && result.status.http_code===200 ? result.contents : result);
            PageStatusActionCreator.dataLoadFinished();
        });
    },
};
