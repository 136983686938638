var React = require('react/addons'),
    TransitionGroup = React.addons.TransitionGroup,
    Knowledge = require('./tiles/Knowledge'),
    News = require('./tiles/News'),
    OneByOne = require('./tiles/OneByOne'),
    ThreeByTwo = require('./tiles/ThreeByTwo'),
    ThreeByOne = require('./tiles/ThreeByOne'),
    Video = require('./tiles/Video'),
    TweetDesktop = require('./tiles/Tweet'),
    TweetMobile = require('./tiles/TweetMobile'),
    HoverMixin = require('./mixins/HoverMixin.js'),
    PackeryMixin = require('../vendor/react-packery-mixin/lib/index.js');
    PageStatusActionCreator = require('../actions/PageStatusActionCreator');
var $ = require('jquery');
var packeryOptions = {
    transitionDuration: 0,
    gutter: 10,
};

var animTimeout;

//-------------------------- <GRID> -------------------------//
var Grid = React.createClass({
    mixins: [PackeryMixin('packeryContainer', packeryOptions)],
    _first: true,
    getInitialState: function() {
        return ({
            initialGridWidth: this.props.windowWidth,
            mountedAlready: false,
            Tweet: TweetDesktop
        });
    },
    componentDidMount: function() {
        this.setState({
            initialGridWidth: $('#grid').width(),
            mountedAlready: true
        });
        PageStatusActionCreator.animationStarted();
    },
    shouldComponentUpdate: function(prevProps, prevState) {
        return this.state.initialGridWidth !== prevState.initialGridWidth;
        // return this.state.mountedAlready === true;
    },
    componentWillReceiveProps: function(nextProps) {
        var nextTweetObject = TweetDesktop;
        if (this._first || this.props.gridData.length !== nextProps.gridData.length || this.props.gridContentType !== nextProps.gridContentType || this.props.windowWidth !== nextProps.windowWidth) {
            if (nextProps.windowWidth !== 0) {
                if (nextProps.windowWidth < Config.mobileBreak) {
                    nextTweetObject = TweetMobile;
                } else {}
            }
            this._first = false;
            this.setState({
                Tweet: nextTweetObject
            });
            this._timer = setTimeout(function() {
                this.packery.reloadItems();
                this.forceUpdate();
            }.bind(this), 10);
            this._timer = setTimeout(function() {
                this.packery.reloadItems();
                this.forceUpdate();
            }.bind(this), 100);
        }
    },
    getCurrentBreakPoint: function(viewportWidth, breakPoints) {
        var i = breakPoints.length - 1;
        while (viewportWidth < breakPoints[i].size && i > 0) {
            i--;
        }
        return breakPoints[i];
    },
    getStyleObj: function(i) {
        //var td = this.state.mountedAlready ? 0 : (this.initDelay + i * 0.05);
        var td = this.initDelay + (Math.round(i / 3) * 0.05);
        clearTimeout(animTimeout);
        animTimeout = setTimeout(function(){
            PageStatusActionCreator.animationFinished();
        }, td * 1000);
        var styleObj = {
            top: 1000 + (Math.ceil(i / 5)) * 500,
            transitionDelay: td + "s",
            WebkitTransitionDelay: td + "s"
        };
        return styleObj;
    },
    renderHomeGrid: function(result, i) {

        var styleObj = this.getStyleObj(i);
        if (result.type === "work") {
            switch (result.displayAs) {
                case "3x2":
                    return (<Video styleObj={styleObj} tileType={result.type} windowWidth={this.props.windowWidth} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+ i*.05} data={result} />);
                    break;
                case "1x1":
                    return (<OneByOne windowWidth={this.props.windowWidth} styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+i*.05} data={result} />);
                    break;
            }
        } else {
            switch (result.type) {
                case "knowledge":
                    return (<Knowledge styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+i*.05} data={result} positioned={this.packery} />);
                    break;
                case "tweet":
                    return (<this.state.Tweet styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+ i*.05} data={result} />);
                    break;
                case "news":
                    return (<News styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+ i*.05} data={result} />);
            }
        }
    },
    renderSubGrid: function(result, i) {

        var styleObj = this.getStyleObj(i);
        if (result.type !== "tweet") {
            switch (result.displayAs) {
                case "1x1":
                    return (<OneByOne windowWidth={this.props.windowWidth}  styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+ i*.05} data={result} />);
                    break;
                case "3x2":
                    return (<Video styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+i*.05} data={result} />);
                    break;
            }
        } else {
            return (<this.state.Tweet styleObj={styleObj} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay+ i*.05} data={result} />);
        }
    },
    renderRelatedGrid: function(result, i) {
        var styleObj = this.getStyleObj(i);
        if (result.type != "tweet") {
            return (<ThreeByOne styleObj={styleObj} tileType={result.type} tileUnit={this.tileUnit} key={result.id} delay={this.initDelay + i*.05} data={result} />)
        }
    },
    render: function() {
        var cssMargin = Config.cssMargin;
        var cells = this.getCurrentBreakPoint(this.props.windowWidth, Config.breakPoints).cells;
        if (this.props.gridRelated && cells === 4) {
            cells = 6;
        }
        gridWidth = ($('#grid').width() || this.state.initialGridWidth) - cssMargin * (cells - 1);
        this.tileUnit = Math.floor(gridWidth / cells);
        this.initDelay = this.props.delay || .5;
        if (this.props.gridData) {
            var func = this.renderHomeGrid;
            if (this.props.gridContentType !== "all" && this.props.gridContentType !== "cannes" && !this.props.gridRelated) {
                func = this.renderSubGrid;
            } else if (this.props.gridRelated === true) {
                func = this.renderRelatedGrid;
            }
            var gridDivs = this.props.gridData.map(func);

        }
        return (<section id="grid" >


              <ul ref='packeryContainer'>
                   {gridDivs}
             </ul>

            </section>);
    }
});
//------------------------- </GRID> -------------------------//
module.exports = Grid;
