
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {
  sendPass: function(userInput) {
    AppDispatcher.handleViewAction({
      type: "SEND_USER_INPUT",
      data: userInput
    });
  },
  logOut: function(){
  	AppDispatcher.handleViewAction({
      type: "LOGOUT_USER"
    });
  }
  
};