  /** @jsx React.DOM */
  var React = require('react/addons');
  var Router = require('react-router');
  var Route = Router.Route;
  var Redirect = Router.Redirect;
  var TransitionGroup = React.addons.TransitionGroup;
  var FeedStore = window.FeedStore = require('../stores/FeedStore');
  var DesktopMenu = require('./DesktopMenu.js');
  var MobileMenu = require('./MobileMenu.js');
  var $ = require('jquery');
  var GeneralDetail = require('./GeneralDetail.js');
  var Locations = require('./Locations.js');
  var FilterPage = require('./FilterPage.js');
  var CannesPage = require('./CannesPage.js');
  var PeoplePage = require('./PeoplePage');
  var CareersPage = require('./CareersPage');
  var CareersDetailsPage = require('./CareersDetailsPage');
  var ContactPage = require('./ContactPage');
  var Perf = window.Perf = React.addons.Perf;
  var Home = require('./Home.js');
  var Config = window.Config = require('../../Config');
  var ShareStore = require('../stores/ShareStore');
  var ShareActionCreator = require('../actions/ShareActionCreator');
  var SearchActionCreator = require('../actions/SearchActionCreator');
  var MobileHeader = require('./generalModules/MobileHeader');
  var SearchPage = require('./SearchPage');
  var SmoothScrollMixin = require('./mixins/SmoothScrollMixin');
  var Footer = require('./generalModules/Footer');
  var ModalVideoController = require('./generalModules/ModalVideoController');
  var SearchStore = require('../stores/SearchStore');
  var PrivateCannes = require('./CannesPageModules/PrivateCannes');
  var DefaultRoute = Router.DefaultRoute;
  var RouteHandler = Router.RouteHandler;
  var NotFoundRoute = Router.NotFoundRoute;
  var NotFound = require('./generalModules/NotFound');
  var LightboxController = require('./generalModules/LightboxController');
  var Translator = require('../utils/Translator.js');
  var TagStore = require('../stores/TagStore');
  var PageStatusStore = require('../stores/PageStatusStore');
  var PageStatusActionCreator = require('../actions/PageStatusActionCreator');

/*  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  ga('create', Config.stagingAnalyticsTag, 'auto');*/



  //------------ <APP> --------------//
  var Main = React.createClass({
      contextTypes: {
          router: React.PropTypes.func.isRequired
      },
      menuCount: 0,
      mixins: [SmoothScrollMixin],

      getInitialState: function() {
          return {
              data: [],
              windowWidth: 0,
              windowHeight: 0,
              documentHeight: 0,
              scrollPosition: 0,
              component: <div />,
              cannesState: 0,
              modalOpen: false,
              modalData: {},
              menuOpen: false,
              menuType: "main",
              cannesPageVisible: false
          }
      },
      _onChange: function() {

          this.setState({
              menuOpen: ShareStore.getMenuState(),
              menuType: ShareStore.getMenuType()
          });
      },

      _onTagStoreChange: function(){
        var siteTitle = TagStore.getSiteTitle();
        Config.updateSiteTitle(siteTitle)
        this.setState({
            siteTitle : siteTitle
          })
      },
      handleResize: function(e) {
          this.setState({
              windowWidth: $(window).width(),
              windowHeight: $(window).height(),
              documentHeight: $(document).height()
          });
      },
      renderSubcomponent: function(comp, ctx) {
          var comp = <Component params={ctx.params} querystring={ctx.querystring} windowWidth={this.state.windowWidth} gridContentType={filter} />
          self.setState({
              component: comp
          });
      },
      componentDidMount: function(e) {
          TagStore.addChangeListener(this._onTagStoreChange);
          window.addEventListener('resize', this.debounce(this.handleResize, 20));
          ShareStore.addChangeListener(this._onChange);
          TagStore.getSiteTitle();



          //TODO : switch out debounce function above for the better one in SmoothScrollMixin
          this.handleResize();

      },
      componentWillUnmount: function(e) {
          ShareStore.removeChangeListener(this._onChange);
      },
      switchCannesState: function() {
          if (this.state.cannesPageVisible === false) {
              this.setState({
                  cannesPageVisible: true
              });
          } else {
              this.setState({
                  cannesPageVisible: false
              });
          }
      },
      handleMobileClick: function() {
          ShareActionCreator.setMenuState();
      },
      handleMenuChange: function() {
          ShareActionCreator.setMenuType("main");
          this.handleMobileClick();
      },
      checkIfShouldSmoothScroll: function() {
          //console.log("in check ===============")
      },
      getRouteName: function(){
         var i18n = Translator.getInstance();

        if(this.props.routes && this.props.routes[2] && this.props.routes[2].name){
          var oldRoute;
          if(this.props.routes[2].name !== oldRoute){
            if(this.props.routes[2].name.indexOf("Detail") === -1 && this.state.siteTitle){
              var pathTitle = this.props.routes[2].name;
              pathTitle = pathTitle.replace('-', ' ');
              pathTitle = i18n.gettext(pathTitle);
              pathTitle = pathTitle.charAt(0).toUpperCase() + pathTitle.slice(1);
              window.document.getElementById('g-title').innerHTML =  pathTitle + " | " + this.state.siteTitle;
            }
            oldRoute = this.props.routes[2].name;
          }
          return this.props.routes[2].name;
        } else {
            if(this.state.siteTitle!= undefined){
                window.document.getElementById('g-title').innerHTML =  this.state.siteTitle;
            }
          return undefined;
        }
      },
      render: function() {
        var routeName = this.getRouteName();
        var footerCanLoadMore = routeName == "knowledge" || routeName == "work" || routeName=="news" || routeName == undefined;
        var footerStore = footerCanLoadMore ? "FeedStore" : "DetailStore";
        if(routeName === "locations"){
          footerStore = "LocationStore"
        } 
        if(routeName === Config.privateCannesString){
          footerStore = "FeedStore";
        }
      

          var mobileOverlayStyle = "mobile-menu-overlay";
          var name = "/";
          var routeHandler;
          name = this.context.router.getCurrentPath();
          //      if (name === "/") {
          routeHandler = (
            <RouteHandler {...this.props} 
                          switchCannesStateFunc={this.switchCannesState} 
                          navHeight={this.state.navHeight} 
                          key={name} 
                          windowHeight={this.state.windowHeight} 
                          windowWidth={this.state.windowWidth} 
                          scrollPosition={this.state.scrollPosition} 
                          cannesPageShowing={this.state.cannesPageVisible}>
            </RouteHandler>
            );
          styleObj = {
              width: this.state.windowWidth
          }
          var menus = (<MobileMenu  menuState={this.state.menuOpen} menuType={this.state.menuType} />);
          var mobileHeader = <MobileHeader prevPath={this.props.prevPath} windowWidth={this.state.windowWidth} cannesNavMenuVisible={this.state.cannesPageVisible} />;
          if (this.state.menuOpen === true) {
              mobileOverlayStyle += " active-overlay";
          }
          var mobileOverlay = <div className={mobileOverlayStyle} onClick={this.handleMobileClick}></div>;
          var mobileButton = <div onClick={this.handleMenuChange} className="mobile-button"><span className="hamburger-menu"></span></div>
          //if (this.state.windowWidth >= Config.mobileBreak) {
          if (window.matchMedia('(min-width: '+ (Config.mobileBreak + 1) +'px)').matches) {
              mobileOverlay = ""
              mobileHeader = "";
              menus = <DesktopMenu prevPath={this.props.prevPath} windowWidth={this.state.windowWidth} forceResizeCheck={this.handleResize} cannesNavMenuVisible={this.state.cannesPageVisible}></DesktopMenu>
              mobileButton = "";
          }

          return (
            <div className="app-container">
          {mobileOverlay}
         {mobileHeader}
         {mobileButton}
         <div className="page-container" ref='scrollContainer' style={styleObj}>
            {menus}
            {routeHandler}
         </div>
          <Footer loadMore={footerCanLoadMore} footerStore={footerStore} windowWidth={this.state.windowWidth} delay={1} preventCall={true} />
         <ModalVideoController />
         <LightboxController />
         </div>);
      }
  });
  var prevPath = null;
  var routes = (
    <Route path="/">
      <Redirect from="/" to="main" params={{lang: Config.lang}} />
      <Route name="main" path=":lang" handler={Main}>
        <Route name="about" handler={GeneralDetail}/>
        <Route name="locations" handler={Locations} path="locations" />
        <Route name="search" handler={SearchPage} path="search/:tags"/>
        <Route name="searchBlank" handler={SearchPage} path="search/"/>
        <Route name="knowledge" handler={FilterPage}/>
        <Route name="knowledgeDetail" handler={GeneralDetail} path="knowledge/:id" />
        <Route name="newsDetail" handler={GeneralDetail}  path="news/:id" />
        <Route name="work" handler={FilterPage} />
        <Route name="workDetail" handler={GeneralDetail} path="work/:id"/>
        <Route name="workDetailWithSlug" handler={GeneralDetail} path="work/:id/:slug"/>

        <Route name="news" handler={FilterPage}/>
        <Route name="canneswork" handler={PrivateCannes} path="canneswork" />
        <Route name="cannes" handler={Home}/>

        <Route name="people" handler={PeoplePage} />

        <Route name="contact" handler={ContactPage} />
        <Route name="careers" handler={CareersPage} />
        <Route name="careers-details" handler={CareersDetailsPage} path="careers/:id" />
        
        <Route name="bbdo-knows" handler={FilterPage} />
        <Route name="bbdokDetail" handler={GeneralDetail} path="bbdo-knows/:id" />
        <Route name="bbdo-u" handler={FilterPage} />
        <Route name="bbdouDetail" handler={GeneralDetail} path="bbdo-u/:id" />

       
        
        <DefaultRoute handler={Home} />
      </Route>
      <NotFoundRoute handler={NotFound} />
    </Route>
  );

 /*<Route name="careers" handler={CareersPage} />
        <Route name="careers-details" handler={CareersDetailsPage} path="careers/:id" />
 */       
  React.initializeTouchEvents(true);
  Router.run(routes, Router.HistoryLocation, function(Handler, state) {
    PageStatusActionCreator.reset();
    if (state.params.lang){
      Config.updatePaths(state.params.lang);
      Translator.changeLanguage(state.params.lang);
    }
    ga('send', state.path);
    React.render(<Handler {...state} prevPath={prevPath} shouldSmoothScroll={false} />, document.getElementById('page-contain')); // should I add {...state}  //{state.routes[1] && state.routes[1].name==="knowledgeDetail" && !window.oldIE }
  });
  window.handleResize = function() {
      Main.handleResize();
  }

  module.exports = Main;
