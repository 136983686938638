 /** @jsx React.DOM */
var React = require('react');
var Home = require('../Home');
var PrivateCannesAction = require('../../actions/PrivateCannesActionCreator');
 var PrivateCannesContent = React.createClass({
    logoutAction: function(){
        PrivateCannesAction.logOut();
    },
     render: function() {

      var styleObj = {
        color: "#fff",
        marginTop: "100px",
        display: "block"
      };

      return (
            <div className="private-cannes-container">
              <div className="pc-logout" onClick={this.logoutAction}>Logout</div>
               <Home switchCannesStateFunc={this.props.switchCannesStateFunc} windowWidth = {this.props.windowWidth}/>
            </div>);
     },
 });
 module.exports = PrivateCannesContent;