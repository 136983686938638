 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('../mixins/HoverMixin');
 var TileMixin = require('../mixins/TileMixin');
 var Config = require("../../../Config");
 var Link = require('react-router').Link;
 var TileTokens = require("./TileTokens");
 //----------- <Grid><CaseStudy> ------------//
 var OneByOne = React.createClass({
     mixins: [HoverMixin, TileMixin, require('../mixins/MobileHoverMixin')],
     getInitialState: function() {
         return {
             arWidth: 1,
             arHeight: 1,
         };
     },
     render: function() {
         var classString = "item square-tile";
         var sizeableStyle = this.getTileStyles();
         var title = this.props.data.title || this.props.data[Config.lang].title;
         var source = this.props.data.source || this.props.data[Config.lang].source;
         var campaign = this.props.data[Config.lang].campaign;
         
         var outBound = <div></div>;
         sizeableStyle.backgroundImage = 'url(' + this.props.data.homeImage + ')';
         var TokenModule = '';
         if (this.state.hoverState === true) {
             classString += ' title-overlay-active';
         }
         if(title.length > 55 && Config.mobileBreak < this.props.windowWidth ){
            title = title.slice(0,54).concat("...");
         }else if(Config.mobileBreak >= this.props.windowWidth && title.length > 35){
            title = title.slice(0,34).concat("...");

         }
        var windowTarget = this.props.data.externalURL ? "_blank" : "_self";
            if(windowTarget === "_blank"){
                outBound = <span className="outbound-link-icon"></span>;
            }
         var paramObject = {
             id: this.props.data.id,
             lang: Config.lang
             
         };
        if(this.props.data.tokens){
            TokenModule = <TileTokens tokenTypes={this.props.data.tokens} />;
        }

        var innards = <div ref="sizeable" className={classString} style={sizeableStyle}>
                    {TokenModule}
                        <div className="title-overlay">
                            <h3>{this.props.data.type === 'bbdok' 
                                && this.props.data[Config.lang].list_of_authors.length 
                                ? 'By ' + this.props.data[Config.lang].list_of_authors.join(', ') + '/' + source 
                                : (this.props.data.type === 'work' ? campaign : source)}</h3>
                            <h2>{title}</h2>
                           {outBound}
                        </div>
                    </div>;
        var link = <Link to={this.props.tileType + 'Detail'} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item">
                    {innards}
                </Link>

        if(this.props.data.externalURL){
            link = <a href={this.props.data.externalURL} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} ref="item" target="_blank">
            {innards}
            </a>
        }
         return (
            <li className='gridItemTop' style={this.props.styleObj}>
                {link}
            </li>
         );
     }
 });
 //----------- <Grid></CaseStudy> ------------//
 module.exports = OneByOne;
