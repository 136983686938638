 /** @jsx React.DOM */
 var React = require('react');
 var Tokenizer = require('../../vendor/react-typeahead/src/tokenizer/index');
 var SearchActionCreator = require('../../actions/SearchActionCreator');
 var SearchStore = require('../../stores/SearchStore');
 var Translator = require('../../utils/Translator.js');
 var TokenContainer = React.createClass({
     getInitialState: function() {
         return {
             tokens: this.props.initialTags || []
         };
     },
     componentDidMount: function() {
         SearchStore.addTokenChangeListener(this._onTokenChange);
         this.setState({
             tokens: SearchStore.getTokens()
         });
     },
     componentWillUnmount: function() {
         SearchStore.removeTokenChangeListener(this._onTokenChange);
         this.removeAll();
     },
     _onTokenChange: function() {
         
         this.setState({
             tokens: SearchStore.getTokens()
         });
     },
     removeMe: function(token,quiet) {
         SearchActionCreator.removeToken(token,quiet);
         if($('.search-input').is(":focus")=== false){

            $('.search-input')[0].focus();
         }
     },
     removeAll: function() {
         tokens = this.state.tokens;
         if (tokens.length > 0) {
             for (var i = tokens.length - 1; i >= 0; i--) {
                 this.removeMe(tokens[i],true);
             }
         }
     },

     render: function() {
         var i18n = Translator.getInstance();
         var tokens = '';
         var self = this;
         if (this.state.tokens) {
             tokens = this.state.tokens.map(function(result, i) {
                 return (<li key={i} onClick={self.removeMe.bind(null,result)}><span>{result}</span><span className="close"></span></li>);
             });
         } else {
             
         }
         return (<div className="token-container">
                <div className="token-wrap">

                    <h1>{
                        // TRANSLATORS: Search results
                        i18n.gettext('Search results:')
                    }</h1>
                  <ul>
                        {tokens}
                    </ul>
                </div>
                <div className="btn-wrap">
                    <span  onClick={this.removeAll} className="work-btn">{i18n.gettext('clear search')}</span>
                </div>
            </div>);
     }
 });
 module.exports = TokenContainer;
