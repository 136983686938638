   /** @jsx React.DOM */

var React = require('react');    
var AuthorDescription = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
   	render: function(){
   		var authorSlug = "";
   		if(this.props.data.name){
   			authorSlug = <p>By {this.props.data.name}</p>
   		}
        return(
			<div className="descriptions">
			    <div className="author">
			        {authorSlug}
			        <p>{ this.props.data.company}</p>
			    </div>
			</div>
      	);
    }
});

module.exports = AuthorDescription;

