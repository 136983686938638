/** @jsx React.DOM */
 var React = require('react');    
var HoverMixin = require('../mixins/HoverMixin');
var ClientItems = require('./ClientItems');
var ClientList = React.createClass({
    getInitialState: function(){
        return ({
            currentOpen: -1
        })
    },

    reportHover: function(n){
        this.setState({
            currentOpen: n
        })

    },
    render: function(){
        var self = this;
        var listItems = this.props.data.map(function(result, i){

            var columns = 2;
            var columnNumber = i%columns;
            return(
                <ClientItems reportHover={self.reportHover} shouldCloseSoon={i !== self.state.currentOpen} clientIndex={i} image={result.image} whichColumn={columnNumber?"right-client":"left-client"} hoverImage={result.white_image} link={result.link} paragraph={result.description} />
            );
        });
      return (
        <div className="clients">
            <ul>{listItems}</ul>
         </div>
        );
    }

});

module.exports = ClientList;