//------------- MIXIN --------------//
var HoverMixin = {
        getInitialState: function() {
            return {
                hoverState: false
            };
        },
        setHover: function(e) {
           
            this.setState({
                hoverState: true
            });
        },
        setHoverFalse: function(e) {
           
            this.setState({
                hoverState: false
            });
        },
    }
    //------------- /MIXIN --------------//
module.exports = HoverMixin;
