/** @jsx React.DOM */
var React = require('react');

var TagStore = require('../../stores/TagStore');


var SocialLinks = React.createClass({
  getInitialState: function() {
    return {
      clickState: false
    };
  },
  handleClick : function(e){
     var social = this.refs.social.getDOMNode();
    if(this.props.type === "footer"){
      this.setState({
        clickState: !this.state.clickState
      });
      if(this.state.clickState === false){
        TweenLite.to( social, 0.5, {marginRight: "0px", onComplete: function(){social.style.zIndex = 1;} });
      }else{
        social.style.zIndex = -1;
        TweenLite.to( social, 0.5, {marginRight: "-200px" });
      }
    }else{
      this.setState({
        clickState: !this.state.clickState
      });
      if(this.state.clickState === false){
        TweenLite.to( social, 0.5, {marginRight: "0px"});
      }else {
        TweenLite.to( social, 0.5, {marginRight: "-165px" });
      }
    }
  },

    render: function(){
      var activeStyleObj = {};
      var divStyle = {};
      var socialData = TagStore.getSocialData();

      

      if(this.state.clickState === true){
        activeStyleObj = {
          borderColor: "rgba(239, 64, 36, 1)"
        };
      }
      if(this.props.windowWidth){
        if(this.props.windowWidth > 680){
          divStyle = {
            zIndex: "1",
            marginRight: "0px"
          };
        }
      }
      return (
          <div className="social-links">
            <span  style={activeStyleObj} className="work-btn social-more-button" onClick={this.handleClick}>{this.props.title}</span>
            <ul ref="social" style={divStyle}>
              {socialData != undefined && socialData.youtube_url != "" && socialData.youtube_url != undefined
              ?<li style={activeStyleObj}><a target="_blank" href={socialData.youtube_url}><i className="fa fa-youtube-play"></i></a></li>
              : ""}
              {socialData != undefined && socialData.facebook_url != "" && socialData.facebook_url != undefined
              ?<li style={activeStyleObj}><a target="_blank" href={socialData.facebook_url}><i className="fa fa-facebook"></i></a></li>
              : ""}
              {socialData != undefined && socialData.twitter_url != "" && socialData.twitter_url != undefined
              ?<li style={activeStyleObj}><a target="_blank" href={socialData.twitter_url}><i className="fa fa-twitter"></i></a></li>
              : ""}
              {socialData != undefined && socialData.linkedin_url != "" && socialData.linkedin_url !=undefined
              ?<li style={activeStyleObj}><a target="_blank" href={socialData.linkedin_url}><i className="fa fa-linkedin"></i></a></li>
              : ""}
              {socialData != undefined && socialData.instagram_url != "" && socialData.instagram_url != undefined
              ?<li style={activeStyleObj}><a target="_blank" href={socialData.instagram_url}><i className="fa fa-instagram"></i></a></li>
              : ""}
            </ul>
          </div>
        );
    }
});
module.exports = SocialLinks;


