var React = require('react');    
var Config = require('../../Config');
var _ = require('lodash');
var HoverMixin = require('./mixins/HoverMixin');
var AnimationMixin = require('./mixins/AnimationMixin');

//--------------------------------------------------------------------------
//
//  Component
//
//--------------------------------------------------------------------------

var PersonThumb = React.createClass({

    mixins:[HoverMixin, AnimationMixin],

    //----------------------------------------
    //  Life cycle
    //----------------------------------------

	render: function(){
        var props = _.omit(this.props, ['data', 'active']);
        var overlayClass = (this.state.hoverState || this.props.data.active) ? ' title-overlay-active' : '';
        return (
            <div className={"person-thumb" + overlayClass}
                 style={{backgroundImage: "url(" + this.props.data.image + ")"}}
                 {...props}
                 onMouseEnter={this.setHover} 
                 onMouseLeave={this.setHoverFalse}>
                <div className="title-overlay">
                    <h3>{this.props.data.name}</h3>
                    <h2>{this.props.data.position}</h2>
                </div>
            </div>
        );
	},

	//----------------------------------------
    //  Event handlers
    //----------------------------------------

});

module.exports = PersonThumb;