/**
 * Provides smooth, hardware-accellerated ease-based scrolling for container
 * elements by normalizing scroll behavior across different browsers / platforms.
 * Works great for parallax, or simply browsing a document.
 *
 * @author  Christopher Pappas
 * @date    5.14.14
 */
 var Config = require('../../../Config');
 //console.log(Config,window.Config);
var ParallaxActionCreator = require('../../actions/ParallaxActionCreator.js');
var SmoothScrollMixin = {
    locked: false,
    then: Date.now(),
    interval: 16,
    getInitialState: function() {
        return {
            useSmoothScroll: false,
            friction: 1,
            nextPosition: 0,
            currentPosition: 0,
            scrollPercent: 0,
            debounceAmount: 16
        };
    },
    debounce: function(func, wait, immediate) {
        var timeout;
        if (!immediate) {
            immediate = (wait === 0);
        }
        return function() {
            var context = this,
                args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },
    componentDidMount: function() {
        if(this.props.shouldSmoothScroll === true){
            
            this.updateHeight();
        }
        this.setupStyles();
        this.decideOnDebounce(this.state.windowWidth);
        this.animationLoop();
    },
    redoEventListeners: function() {
        if (this.debounceFunction) {
            window.removeEventListener('scroll', this.debounceFunction)
            window.removeEventListener('wheel', this.debounceFunction)
            window.removeEventListener('touchmove', this.debounceFunction)
        }
        this.debounceFunction = this.debounce(this.onScroll, this.state.debounceAmount);
        window.addEventListener('scroll', this.debounceFunction)
        window.addEventListener('wheel', this.debounceFunction)
        window.addEventListener('touchmove', this.debounceFunction)
    },

/*    pageIsGridPage: function() {
        console.log("PAGE IS GRID?", this.context.router)
        return (false);
    },*/
    decideOnDebounce: function(windowWidth) {
     
        if (this.props.shouldSmoothScroll && windowWidth > 680) {
                
            this.setState({
                debounceAmount: 5,
                friction: .2,
                useSmoothScroll: true
            })
        } else {
            //      console.log("NOT USING SMOOTHSCROLL",windowWidth)
            this.setState({
                debounceAmount: 16,
                friction: 1,
                useSmoothScroll: false,
                nextPosition: 0,
                currentPosition: 0
            });
            TweenLite.set(this.refs.scrollContainer.getDOMNode(), {
                clearProps: 'y'
            });

        }
        this.setupStyles();
    },
    componentDidUpdate: function(prevProps, prevState) {
        if(this.state.useSmoothScroll === true && this.props.useSmoothScroll){
            this.updateHeight();
        }
         this.setupStyles();
        if (prevState.windowWidth !== this.state.windowWidth || prevProps.shouldSmoothScroll !== this.props.shouldSmoothScroll) {

            this.decideOnDebounce(this.state.windowWidth);
        }
        if (this.state.debounceAmount != prevState.debounceAmount) {
            this.redoEventListeners();
        }
    },
    setupStyles: function() {
        this.refs.scrollContainer.getDOMNode().style.position = this.state.useSmoothScroll ? 'fixed' : 'static';
    },
    updateHeight: function() {
        var $container = this.refs.scrollContainer.getDOMNode();

        //$container.parentNode.style.height = $container.offsetHeight + 'px';
           // console.log("UPDATE HEIGHT" + $container.parentNode.style.height);
    },
    animationLoop: function() {

        var now = Date.now();
        var deltaT = now - this.then;
        this.updateHeight();
        if (deltaT > this.interval) {
            var deltaY = ~~(this.state.nextPosition - this.state.currentPosition) * this.state.friction;
            if (this.state.useSmoothScroll && Math.abs(deltaY) > .1) {
                var $container = this.refs.scrollContainer.getDOMNode()
                this.state.currentPosition += deltaY;
                this.state.scrollPercent = ~~(this.state.currentPosition / (parseInt($container.parentNode.style.height) - window.innerHeight) * 100)
                    //console.log(-this.state.currentPosition);
                TweenLite.set(this.refs.scrollContainer.getDOMNode(), {
                    y: -this.state.currentPosition
                });
                // console.log(this.state.nextPosition,this.state.currentPosition)
            } else {

                this.state.currentPosition = this.state.nextPosition;
                if(this.state.currentPosition !== 0){
                  TweenLite.set(this.refs.scrollContainer.getDOMNode(), {
                      y: -this.state.currentPosition
                  });
                }
            }
            this.then = now - (deltaT % this.interval)
            ParallaxActionCreator.reportYChange(-this.state.currentPosition);
        }
        requestAnimationFrame(this.animationLoop);

    },
    onScroll: function() {
        if (this.state.useSmoothScroll) {
            this.setState({
                nextPosition: window.scrollY,
                scrollPosition: window.scrollY
            })
        } else {
            this.setState({
                scrollPosition: window.scrollY
            })
            ParallaxActionCreator.reportYChange(-this.state.scrollPosition);
        }
    }
}
module.exports = SmoothScrollMixin;
