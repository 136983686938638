var AppDispatcher = require('../dispatcher/AppDispatcher');
var PeopleConstants = require('../constants/PeopleConstants');


module.exports = {

  showPersonDetails: function(id) {
    AppDispatcher.handleViewAction({
      type: PeopleConstants.SHOW_DETAILS,
      id: id
    });
  },

  hidePersonDetails: function(id) {
    AppDispatcher.handleViewAction({
      type: PeopleConstants.HIDE_DETAILS
    });
  }
  
};