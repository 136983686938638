/** @jsx React.DOM */
var React = require('react');
var HoverMixin = require('../mixins/HoverMixin');
var ClientItems = React.createClass({
    mixins: [HoverMixin],

    componentDidUpdate: function(prevProps, prevState) {

        if (this.state.hoverState === true) {
            TweenLite.set(this.refs.hover.getDOMNode(), {
                opacity: 1
            });
        }
        if (!prevProps.shouldCloseSoon && this.props.shouldCloseSoon) {
            this.setHoverFalseSoon();
        }
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        return (this.props.shouldCloseSoon !== nextProps.shouldCloseSoon || this.state.hoverState !== nextState.hoverState);
    },
    setHoverFalseSoon: function() {
        TweenLite.delayedCall(1, this.mySetHoverFalse);
        TweenLite.set(this.refs.hover.getDOMNode(), {
            opacity: 0.0
        });
    },
    mySetHoverFalse: function() {

        this.setHoverFalse();
    },
    mySetHover: function(e) {
        TweenLite.set(this.refs.hover.getDOMNode(), {
            opacity: 1
        });
        TweenLite.killTweensOf(this.mySetHoverFalse);
        this.props.reportHover(this.props.clientIndex);
        this.setHover();
    },
    //  <a href={this.props.link}><span className="work-btn">See Work</span></a>
    render: function() {
        var classString = "hover " + this.props.whichColumn;
        var hoverElements = <div ref="hover" ></div>
        if (this.state.hoverState === true) {
            hoverElements = <div ref="hover"  className={classString} onMouseLeave={this.setHoverFalseSoon}>
                    <div className="hoverImage"><img src={this.props.hoverImage} /></div>
                    <p>{this.props.paragraph}</p>
                  
                </div>;
        }
        return (
            <li onMouseEnter={this.mySetHover}>
            <div className="clientImage">
                <img src={this.props.image} />
                {hoverElements}
            </div>
        </li>
        );
    }
});

module.exports = ClientItems;
