/** @jsx React.DOM */
var React = require('react');    

var WorldwideHQ = React.createClass({
	componentsDidMount : function(){
	},


	render: function(){

		return(
			<div className="ww">
				<div className="ww-container">
					<h1>Worldwide &mdash; New York</h1>
					<div className="ww-address">
						<span>{this.props.wwData.address1}</span>
						<span>{this.props.wwData.address2}</span>
						<p>Tel: {this.props.wwData.phone}</p>
					</div>
					<div className="ww-contacts">
						<p>New Business:&nbsp;{this.props.wwData.emailnewbusiness}</p>
						<p>Press Relations:&nbsp;{this.props.wwData.emailpressrelations}</p>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = WorldwideHQ;