/** @jsx React.DOM */
 var React = require('react');    
var Config = require('../../../Config');
var Paragraph = React.createClass({
mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	 var html = {__html: Config.stripTags(this.props.data)};
      return (
      		<div className="toolbox-p">
            <p dangerouslySetInnerHTML={html} />
          </div>
        );
    }

});

module.exports = Paragraph;