var _ = require('lodash');
var Config = require('../../Config');
var DetailTransform = require('./DetailTransform');
var FeedTransform = function() {
    var setDisplayImageAndDisplayAs = function(node, filter) {
        var type = node.type;
        var DetailTransform = {};
        if (filter === "all" || filter === "cannes") {
                node.marqueeImage = getImageOfSize(node,"16x9");
        }
        if (type === filter || type === "bbdou"  || type === "bbdok" || type === "work" && (filter === "all" || filter === "cannes" || filter === Config.privateCannesString)) {
            node.displayAs = node.featured ? "3x2" : "1x1";
            node.homeImage = getImageOfSize(node, node.displayAs);

            setPreviewVideo(node);
        } else if (type === "knowledge") {
            node.displayAs = "1x2";
            node.homeImage = getImageOfSize(node, "1x2", "blue");
            node.hoverImage = getImageOfSize(node, "1x2", "blue");
        } else if (type === "news") {
            node.displayAs = "2x1";
            node.homeImage = getImageOfSize(node, "2x1", "red");
            node.hoverImage = getImageOfSize(node, "2x1");
        }
        node.image_3x1 = getImageOfSize(node, "3x1_small") ;
        if (filter === "all" || filter === "cannes") {
            if (type === "work") {
                node.homeImage = getImageOfSize(node, node.displayAs);
                setPreviewVideo(node);
            }
        }
        return node;
    };
    var setPreviewVideo = function(node) {
        try {
            var firstMedia = node[Config.lang].list_of_media[0]
            if (firstMedia && (firstMedia.type === "video" || firstMedia.type === "video_link")) {
                node.previewVideo = Config.CdnBaseURL + node[Config.lang].list_of_media[0].data.mp4_preview.url;
            }
        } catch (e) {}
    };
    var getImageOfSize = function(node, size, color) {
       var url;
        if (node[Config.lang].list_of_media && node[Config.lang].list_of_media[0] && node[Config.lang].list_of_media[0].data) {
            
            if (color) {
                url = node[Config.lang].list_of_media[0].data[size][color].url;
            } else {
                url = node[Config.lang].list_of_media[0].data[size].url;
            }
        }
        if (url) {
            
            return Config.CdnBaseURL + url;
        } else {
            return Config.placeholder[size];
        }
    };
    var generateTokensFromAwards = function(node){

        var tokens = {};
        var awards = node.awards || [];
        tokens = awards.map(function doIHaveACannesAward(result){
            var lc = result.toLowerCase();
            if(lc.indexOf("cannes 2015" !== -1)){
                var regexp = /((gold)|(silver)|(bronze)|(grand prix))/;
                var match = regexp.exec(lc);
               /* if(match && match[0] === "grand prix"){
                    match[0] = "grand-prix";
                }*/
                return match ? match[0] : "special";
            }
        });

        //console.log('tokens',tokens, node);
        return tokens;
    };
    var removeSpacesAndLowerCase = function(str){
        var retstr;
        if (!str) return '';
        retstr = str.replace(/\s+/g,'-');
        retstr = retstr.toLowerCase();
        return retstr;
    };
    var createWorkSlug = function(node){
        var slug = removeSpacesAndLowerCase(node.source);
         slug += "-"+ removeSpacesAndLowerCase(node.title);
         slug += "-"+removeSpacesAndLowerCase(node[Config.lang].campaign);
       
        return slug;
    }
    return {
        setDetailTransform: function(aDetailTransform){
            DetailTransform = aDetailTransform;
        },
        createGridObjects: function(nodes, filter) {
            if (!nodes) return [];
            var newnodes = nodes.map(function(node) {

                node.type = node.type || "tweet";
                if (node.type !== "tweet") {
                    node = setDisplayImageAndDisplayAs(node, filter);
                    node.carousel = DetailTransform.createCarousel(node[Config.lang].list_of_media);
                    
                } else {
                    node.handle = "@" + node.source;
                    node.tweet = node[Config.lang].text;
                    node.url = 'https://twitter.com/' + node.source + '/status/' + node.tweet_id;
                }
                if (node.type === "work") {
                   
                    node.awards = node[Config.lang].list_of_awards;
                    node.tokens = generateTokensFromAwards(node); 
                    node.source = (node[Config.lang].list_of_lead_offices[0]);
                    node.title = (node[Config.lang].brand || node[Config.lang].client);
                    node.slug = createWorkSlug(node);
                } else if (node.type === "knowledge") {
                    node.externalURL = node[Config.lang].redirect_link;
                    node.source = (node[Config.lang].source);
                    node.title = (node[Config.lang].title);

                } else if(node.type === "news"){

                    node.externalURL = node[Config.lang].redirect_link;
                    //console.log("IN NEWS",node);
                    
                    node.source =  node[Config.lang].publication;
                    node.title =  node[Config.lang].headline;
                }
                
                
                return node;
            });
            
            return newnodes;
        },
        sortByAwardFilter: function(_sort, _originalNodes) {
            var thingToReturn = {};
            var sortFilter = _sort + "Filters";
            var filteredNodes = _.filter(_originalNodes, function(node) {
                var lang = node[Config.lang];
                return lang.list_of_awards && _.includes(_.pluck(lang.list_of_tags,"name"), Config.awards.awardsTag);
            });
            //console.log(filteredNodes,"sbaf");
            thingToReturn.sortArray = [];
            for (var i in Config.awards[sortFilter]) {
                thingToReturn.sortArray[i] = {
                    "category": Config.awards[sortFilter][i]
                };
                thingToReturn.sortArray[i].items = _.filter(filteredNodes, function(node) {
                    var retval = _.find(node[Config.lang].list_of_awards, function(award) {
                        var matchesAward = award.indexOf(Config.awards[sortFilter][i]) !== -1;
                        if(matchesAward){
                            
                            if(_sort === "awards" && Config.awards[sortFilter][i] == "Glass"){
                                return award.indexOf("Grand Prix") === -1;
                            } else if(_sort === "category") {
                                return award.substr(award.indexOf(", ")+2) === Config.awards[sortFilter][i];
                            } else {
                                return matchesAward;
                            }

                        }
                    });
                    return retval;
                });
            }
            thingToReturn.sorted = true;
           
            return thingToReturn;
        },
        sortByClient: function(_originalNodes) {
            var filteredNodes = _.filter(_originalNodes, function(node) {
                var lang = node[Config.lang];
                var a = lang.list_of_awards !== undefined;
                var b = _.includes(_.pluck(lang.list_of_tags,"name"), Config.awards.awardsTag);
                var c = lang.client;
                
                return a && b && c;
            });
            filteredNodes = filteredNodes.map(function(node, i) {
                if (node[Config.lang].brand) {
                    node[Config.lang].clientLabel = node[Config.lang].brand;
                } else {
                    node[Config.lang].clientLabel = node[Config.lang].client;
                }
                return node;
            });
            return FeedTransform.sortByField("clientLabel", filteredNodes);
        },
        sortByAgency: function(_originalNodes) {
            var filteredNodes = _.filter(_originalNodes, function(node) {
                return _.includes( _.pluck(node[Config.lang].list_of_tags,"name"), Config.awards.awardsTag) && node[Config.lang].list_of_lead_offices[0] && node[Config.lang].list_of_lead_offices[0];
            });
            //console.log(filteredNodes,"in agency");
            return FeedTransform.sortByField('lead_office', filteredNodes);
        },
        sortByField: function(label, nodes) {
            
            var thingToReturn = {};
            thingToReturn.sortArray = [];
            listOfLabels = nodes.map(function(node){
                return node[Config.lang][label];
            });
            var listOfLabels = _.uniq(listOfLabels);
            
            listOfLabels.sort();

            listOfLabels.map(function(aLabel, i) {
                thingToReturn.sortArray[i] = {
                    'category': aLabel
                };
            
                thingToReturn.sortArray[i].items = _.filter(nodes, function(node) {
                    return _.includes(node[Config.lang][label], aLabel);
                });
            });
            thingToReturn.sorted = true;
            return thingToReturn;
        },
        sortByType: function(type, nodes) {
            thingToReturn = {};
            thingToReturn.sortArray = [];
            thingToReturn.sorted = true;
            
            thingToReturn.sortArray[0] = {'category':'News'};
            thingToReturn.sortArray[0].items = _.filter(nodes, {
                "type": type
            });
            return thingToReturn; 
        }
    };
}();
module.exports = FeedTransform;
