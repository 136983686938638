//------------- MIXIN --------------//
var ParallaxStore = require('../../stores/ParallaxStore');

var MobileHoverMixin = {

		_metaHover: false,
        componentDidMount: function() {
        	
        	if(Config.mobileAgent){

            	ParallaxStore.addChangeListener(this._onChange);
        	}
        },
        componentWillUnmount: function() {
            ParallaxStore.removeChangeListener(this._onChange);
        },
        _onChange: function(){
        	var top = this.getDOMNode().getBoundingClientRect().top;
        	var currentHover = (top > Config.viewportMidTop && top < Config.viewportMidBottom);
        	
        	if(currentHover !== this._metaHover){
        		this._metaHover = currentHover;
        		this.setState({
        			hoverState:this._metaHover
        		})
        	}
        }
    }
    //------------- /MIXIN --------------//
module.exports = MobileHoverMixin;
