/** @jsx React.DOM */

var React = require('react');    
var PrivateCannesAction = require('../../actions/PrivateCannesActionCreator');

var PrivateCannesLogin = React.createClass({

	submitForm: function(e){
      e.preventDefault();
      userInput = {
        password:  $('.password-input')[0].value
      };
      PrivateCannesAction.sendPass(userInput);
    },
    render: function(){
    	var error = <span></span>;
    	if(this.props.loggedIn === 0){
    		error = <span className="login-error" >Your password was incorrect. Please try again. </span>
    	}
      return(
		<div className="private-cannes nav-space">
            <div className="label">
              <h2>Cannes Lion 2015</h2>
              <h1>Password</h1>
            </div>
            <form className="private-cannes-form" onSubmit={this.submitForm} >
              <input className="password-input" type="password" name="password" ></input>
              <input className="private-submit" type="submit" value="Submit"></input>
            </form>
            {error}
          </div>
      	);
      },
});

module.exports = PrivateCannesLogin;