var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ =require("lodash");

var CHANGE_EVENT = 'change';
var name="LocationStore";
var _nodes = [];
var _data = {};
var _regionIndex = 5;
var _filtering = false;
var _searchTerm = '';
var LocationStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  contentIsLoaded: function(){
    return _nodes != [];
  },
  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

removeChangeListener: function(listener){
  this.removeListener(CHANGE_EVENT,listener)
},
isFiltering: function(){
  return _filtering;
},
_normalizeData: function(data){

  var countriesByRegion = [];
  regionsArray = [];
  var regionLookup = {};
  for(var i = 0;i<data.topContacts.length;i++){
    var Region = data.topContacts[i].Region;
    regionLookup[Region] = data.topContacts[i];
  }
  var regions = _.groupBy(data.agencies,'Region');
  _.forEach(regions,function(region,regionName){
    var regionArray = [];
    var countriesForOneRegion = _.groupBy(region,"Country");
    _.forEach(countriesForOneRegion,function(country,index){
      var countryObject = {
        name: index,
        agency:  country
      };
      regionArray.push(countryObject);
    });

    countriesByRegion.push({name:regionName,countries: regionArray,hq:regionLookup[regionName]});
  });

  return {global: regionLookup["Global"],regions: countriesByRegion};
},
  get: function() {
        var data = {};

    if(_nodes && _nodes.agencies){
      _nodes = this._normalizeData(_nodes);
    }
        //console.log("nodes after:",_nodes);
    if(_nodes.regions){
        data.regionIndex = _regionIndex;
        data.ww = _nodes.global;

        if(_filtering){
            var results = {
              countries : []
            };
            _.forEach(_nodes.regions, function(n){

                _.forEach(n.countries, function(o){
                    var country = { name:o.name,agency:[] };

                    _.forEach(o.agency, function(p){

                      if(p.name.toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1){

                          country.agency.push(p);
                      }

                    });
                    if(country.agency.length > 0){
                        results.countries.push(country);
                    }

                 });
            });
            data.regionData = results;
        }
        else{
          //console.log("_regionIndex",_regionIndex);
          data.regionData = _nodes.regions[_regionIndex];

       }
    }

    return data;
  },
  getName: function(){
    return name;
  },
  getRegionNames : function(){
     return  _.pluck(_nodes.regions, 'name');
  }
});


LocationStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;


  switch(action.type) {

    case "RECEIVE_LOCATION_NODES":

      _nodes = action.rawNodes.contents;
      LocationStore.emitChange();
      break;
      case "SELECT_REGION":
      _regionIndex = action.regionIndex;
      LocationStore.emitChange();
      break;
      case "FILTER_LOCATIONS":
      _searchTerm = action.locationTerm;
      _filtering = action.locationTerm.length > 0;
      LocationStore.emitChange();
      break;
    default:
      // do nothing
  }

});

module.exports = LocationStore;
