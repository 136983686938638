/** @jsx React.DOM */
 var React = require('react');    

var Headers = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
    render: function(){
      return(<h1>{this.props.data}</h1>);
      }

});

module.exports = Headers;