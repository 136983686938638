 /** @jsx React.DOM */
var React = require('react');    
var Config = require('../../Config');

//--------------- <Cannes> ----------------//
var Cannes = React.createClass({
      contextTypes: {
          router: React.PropTypes.func.isRequired
      },
     
      getInitialState : function (){
          return{
                classObject : "cannes-lion-container"
          };

      },
    render: function(){
        var cannesStyle = this.state.classObject;
        var displayStyle = {};
        var Awards = <div></div>;
        var mobileCanneLogo = '';
        var windowWidth = this.props.windowWidth < 640;
        var awardsColor = {
          bronze: "#B96E2B" ,
          silver: "#C0BEC2" ,
          gold: "#EAC750",
          grandPrix: "#E9E831",
          special: "#FFF"
        };
        var cannesTitle = {
          bronze: "Bronze" ,
          silver: "Silver" ,
          gold: "Gold",
          grandPrix: "Grand Prix",
          special: "Special Awards"
        };
        var awardsImage = {
          "bronze":    "/assets/images/cannes-bronze.svg",
          "silver":    "/assets/images/cannes-silver.svg",
          "gold":      "/assets/images/cannes-gold.svg",
          "grandPrix": "/assets/images/cannes-grandprix.svg",
          "special":   "/assets/images/cannes-special.svg"
        }
        
        if(this.props.data && this.props.data.cannes2015 && this.props.windowWidth >= Config.mobileBreak ){

          var list_of_awards = this.props.data.cannes2015[0][Config.lang].list_of_awards;

            mobileCanneLogo = '';
           Awards = list_of_awards.map(function(result,i){
              if(Math.floor(result.number) > 0 ){
                return(
                   <div key={i} className="award">
                      <span className="award-icon"><img src={awardsImage[result.name]} /></span>
                      <span style={{color: awardsColor[result.name]}}>{cannesTitle[result.name]}</span>
                      <span className="number">{result.number}</span>
                    </div>
                  );
              }
        });
          }else{
            mobileCanneLogo = <span className="cannes-lion-icon"></span>
          }
                      
        return (
              <div className={cannesStyle} ref='cannesSection' onClick={this.props.forceResizeCheck} style={displayStyle}>
                <div className="cannes-content-wrapper">
                  {mobileCanneLogo}
                  <div className="cannes-lion-text">
                    <div className="tag-line">
                      <h3>See BBDO highlights from</h3>
                    </div>
                    <div className="headline">
                      <h1>Cannes Lions 2015</h1>
                    </div>
                  </div>
                  <div className="cannes-lion-awards">
                      {Awards}
                  </div>
                  <div className="cannes-learn">
                    <span className="work-btn">See the Results</span>
                  </div>
                </div>
              </div>
        );
    }

});
//--------------- </Cannes> ----------------//

module.exports = Cannes;
