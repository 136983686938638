/** @jsx React.DOM */
 var React = require('react');    
 var Translator = require('../../utils/Translator.js');


var ReadMore = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	 var i18n = Translator.getInstance();
      return(
      	<div className="read-more" >
      		<span onClick={this.props.clickedReadMore}>{
			      // TRANSLATORS: Read more button
			        i18n.gettext('Read more')
     		}</span>
      	</div>
      	);
      }

});

module.exports = ReadMore;