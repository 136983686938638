 /** @jsx React.DOM */
var React = require('react');
var $ = require('jquery');
var Router = require('react-router');
var PCLogin = require('./PrivateCannesLogin');
var PrivateCannesContent = require('./PrivateCannesContent');
var PrivateCannesAction = require('../../actions/PrivateCannesActionCreator');
var AuthStore = require('../../stores/AuthStore');

var PrivateCannes = React.createClass({
    mixins: [Router.Navigation],
    getInitialState : function(){
        return {
          isLoggedin :  AuthStore.checkUserSession().status,
          sessionToken : AuthStore.checkUserSession().sessionID
        };
      },
      contextTypes: {
        router: React.PropTypes.func.isRequired
      },      
      componentWillMount: function(){
        document.body.style.backgroundColor = "#000000";
        AuthStore.addChangeListener(this._onChange);
        //AUTHSTORE.CHECKCOOKIE(COOKIE);
      },
      componentWillUnmount: function() {
        AuthStore.removeChangeListener(this._onChange);
        document.body.style.backgroundColor = "#ffffff";

     },

    render: function(){
  
      var privateCannesContent = <div></div>;
      if(this.state.isLoggedin === true){
          privateCannesContent = <PrivateCannesContent sessionKey={this.state.sessionToken} switchCannesStateFunc={this.props.switchCannesStateFunc} windowWidth = {this.props.windowWidth}/>
      }else{
        privateCannesContent = <PCLogin loggedIn={this.state.sessionToken} />;
      }

        return (
          privateCannesContent
        );
    },
     _onChange: function() {
    
        this.setState({
          isLoggedin :  AuthStore.checkUserSession().status,
          sessionToken : AuthStore.checkUserSession().sessionID
        });
      }
});

module.exports = PrivateCannes;