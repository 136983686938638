var _ = require('lodash');
var Config = require('../../Config')
var DetailTransform = function() {
    var toolMap = {
        "bullets": "list",
        "copy": "paragraph",
        "readMore": "readMore",
        "media": "extraImage",
        "scrolling": "parallaxImage",
        "subhead": "header",
        "quote": "quote",
        "embed": "embed",
        "link": "link",
        "pdf": "pdf"
    };
    var FeedTransform = {};
    var processVideoLink = function(url) {
        if (url.indexOf('vimeo') > -1) {
            var vimeoRegExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
            var matchVim = url.match(vimeoRegExp);
            var vimId = matchVim[3];
            var url = "https://player.vimeo.com/video/" + vimId + "?autoplay=1&badge=0";
            return url;
        } else {
            var youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var matchYoutube = url.match(youtubeRegExp);
            if (matchYoutube && matchYoutube[7].length === 11) {
                var youtubeId = matchYoutube[7];
                var url = "https://www.youtube.com/embed/" + youtubeId + '?autoplay=1&showinfo=0';
                return url;
            }
        }
    };
    var compileTags = function(node) {

        var tags = [];
        tags = (_.filter(node[Config.lang].list_of_tags, {
            "visible": true
        }));
        if (node.type === "work") {
            tagify(tags, node[Config.lang].client);
            tagify(tags, node[Config.lang].campaign);
            tagify(tags, node[Config.lang].list_of_lead_offices[0]);
            tagify(tags, node[Config.lang].brand)
        } else {

        }
        //console.log("NODE has",node[Config.lang].list_of_tags,tags)
        return tags;
    };
    var tagify = function(tags, str) {
        if (str) {
            tags.push({
                "name": str
            })
        }
    }
    var createMediaFromMediaObject = function(mediaObject) {
        var media = {};
        media.type = mediaObject.type || "image";
        if (media.type === "video") {
            media.videoURL = {
                "mp4": Config.CdnBaseURL + mediaObject.data.mp4.url,
                "webm": Config.CdnBaseURL + mediaObject.data.webm.url,
            }
        } else if (media.type === "video_link") {
            media.videoURL = {
                "embed": mediaObject.data.video_link.url ? processVideoLink(mediaObject.data.video_link.url) : ""
            }
        }
        var url;
        if (mediaObject.data) {
            url = mediaObject.data["16x9"].url;
        }
        if (url) {
            media.imageURL = Config.CdnBaseURL + url;
        } else {
            media.imageURL = Config.placeholder["16x9"];
        }
        if (mediaObject.lightbox) {
            media.hasLightbox = true;
            media.originalImgURL = Config.CdnBaseURL + mediaObject.original_data.url;
        } else {
            media.hasLightbox = false;
        }
        return media;
    }
    var createCarousel = function(anArrayOfMediaObjects, alt) {
            if (!anArrayOfMediaObjects) return [];
            var carousel = [];
            for (var i = 0; i < anArrayOfMediaObjects.length; i++) {
                var media = createMediaFromMediaObject(anArrayOfMediaObjects[i]);
                media.alt = alt + ", slide " + (i + 1) + " of " + anArrayOfMediaObjects.length;
                carousel.push(media);
            }

            return carousel;
        },
        createBrief = function(aLang) {
            var brief = {};
            brief.client = {
                "name": (aLang.brand || aLang.client)
            };
            brief.campaign = {
                "name": aLang.campaign || ""
            };
            brief.company = {
                "name": aLang.list_of_lead_offices[0]
            };
            return brief;
        },
        createContent = function(aListOfTools) {
            var content = [];
            if (!aListOfTools) {
                aListOfTools = [];
            }
            aListOfTools.map(function(result, i) {
                content[i] = {};
                content[i].type = toolMap[result.type];
                content[i].content = {};

                switch (content[i].type) {
                    case "extraImage":
                        // TODO
                        content[i].content = createMediaFromMediaObject(result.content.media_object);
                        content[i].content.description = result.content.caption;

                        break;
                    case "parallaxImage":
                        content[i].content.image = Config.CdnBaseURL + result.content.media_object.data["3x2"].url;
                        content[i].content.description = result.content.caption;
                        break;
                    case "readMore":
                        //TODO
                        break;
                    case "pdf":
                        content[i].content.url = Config.CdnBaseURL + result.content.data["pdf"].url;
                        break;
                    case "link":
                        content[i].content.url = result.content.link;
                        content[i].content.name = result.content.label;
                        //content[i].content.result = result;
                        break;
                    default:
                        content[i].content = result.content;
                        break;
                }
            })

            return content;
        },


        createClientData = function(nodes) {
            return _.map(nodes, function(node) {
                return {
                    id: node.id,
                    image: Config.CdnBaseURL + node.list_of_media[0].original_data.url,
                    name: node[Config.lang].name,
                    description: node[Config.lang].description
                }
            });
        },


        addBriefToContent = function(tools, summary) {
            tools.unshift({
                type: "paragraph",
                content: summary
            })

            return tools;
        };
    addAwardsToContent = function(tools, listOfAwards) {
        //console.log(listOfAwards);
        tools.push({
            type: "awardsList",
            content: listOfAwards
        });

        return tools;
    };
    addVideoLinkToContent = function(tools, url) {
        tools.unshift({
            type: "link",
            content: {
                url: url,
                name: "Download Video",
                shouldDownload: true
            }
        });

        return tools;
    };
    generateAlt = function(lang) {
        retString = "";
        retString += lang.client;
        if (lang.brand) {
            retString += " " + lang.brand;
        }
        retString += " – ";
        retString += lang.campaign;
        return (retString);
    };
    createTitleTag = function(node) {
        var retString;

        if (node.campaign && node.campaign !== null) {
            retString = node.campaign + " | " + Config.siteTitle;
        } else if (node.title && node.title !== null) {
            retString = node.title + " | " + Config.siteTitle;

        } else {
            retString = node.headline + " | " + Config.siteTitle;
        }

        return retString;
    };
    return {
        setFeedTransform: function(aFeedTransform) {
            FeedTransform = aFeedTransform;
        },
        createCarousel: createCarousel,
        createWorkDetail: function(node, isAuth) {
            var returnedTools;
            var returnVal = [];
            var tools = createContent(node[Config.lang].list_of_tools);

            returnedTools = addBriefToContent(tools, node[Config.lang].summary);
            if (isAuth && node[Config.lang].list_of_media[0].data.mp4.url) {
                returnedTools = addVideoLinkToContent(returnedTools, Config.CdnBaseURL + node[Config.lang].list_of_media[0].data.mp4.url);
            }
            returnedTools = addAwardsToContent(returnedTools, node[Config.lang].list_of_awards);

            if (node[Config.lang].list_of_media) {
                returnVal.push({
                    "type": "carousel",
                    "data": createCarousel(node[Config.lang].list_of_media, generateAlt(node[Config.lang]))
                });
            }
            returnVal.push({
                "type": "brief",
                "data": createBrief(node[Config.lang])
            });
            returnVal.push({
                "type": "content",
                "data": returnedTools
            });
            returnVal.push({
                "type": "tags",
                "data": compileTags(node)
            });
            returnVal.push({
                "type": "related",
                "data": FeedTransform.createGridObjects(node[Config.lang].list_of_related_posts)
            });
            returnVal.push({
                "type": "htmlTitle",
                "data": createTitleTag(node[Config.lang])
            });
            return returnVal;
        },
        createNewsOrKnowledgeDetail: function(node) {
            var returnVal = [];

            var color = node.type == "knowledge" ? "blue" : "red";
            var tools = createContent(node[Config.lang].list_of_tools);
            var detailHero = {};
            detailHero.source = node.type !== "bbdok" ? node[Config.lang].source : '';
            detailHero.headline = node.type == "knowledge" || node.type == "bbdok" ? node[Config.lang].title : node[Config.lang].headline;
            if (node[Config.lang].list_of_media && node[Config.lang].list_of_media.length > 0 && node[Config.lang].list_of_media[0].data) {
                detailHero.imageUrl = Config.CdnBaseURL + node[Config.lang].list_of_media[0].data["3x1_large"][color].url;
                detailHero.imageUrlMobile = Config.CdnBaseURL + node[Config.lang].list_of_media[0].data["3x2"][color].url;
            } else {
                //detailHero.imageUrlMobile = Config.CdnBaseURL + node[Config.lang].list_of_media[0].data["3x2"][color].url;
                detailHero.imageUrlMobile = Config.placeholder["3x2"];
                detailHero.imageUrl = Config.placeholder["3x1_large"];
            }


            if (node.type === "bbdou") {
                returnVal.push({
                    "type": "carousel",
                    "data": createCarousel(node[Config.lang].list_of_media, generateAlt(node[Config.lang]))
                });
            } else {
                returnVal.push({
                    type: "detailHero",
                    data: detailHero
                });
            }

            if (!node[Config.lang].list_of_authors) {
                node[Config.lang].list_of_authors = [''];
            }

            returnVal.push({
                type: "author",
                data: {
                    name: node[Config.lang].list_of_authors.join(', '),
                    company: node.type === 'bbdou' ? '' : node.type === 'bbdok' ? node[Config.lang].source || 'BBDO Knows' : node[Config.lang].list_of_lead_offices[0]
                }
            });



            tools = addBriefToContent(tools, node[Config.lang].summary);
            if (node.type === 'bbdou') {
                tools.unshift({
                    type: 'header',
                    content: node[Config.lang].title
                });
            }

            returnVal.push({
                "type": "content",
                "data": tools
            });
            returnVal.push({
                "type": "tags",
                "data": compileTags(node)
            });
            returnVal.push({
                "type": "related",
                "data": FeedTransform.createGridObjects(node[Config.lang].list_of_related_posts)
            });
            returnVal.push({
                "type": "htmlTitle",
                "data": createTitleTag(node[Config.lang])
            });
            return returnVal;
        },
        createAbout: function(node) {

            var returnVal = [];
            var tools = createContent(node[Config.lang].list_of_tools);
            var clientData = createClientData(node.list_of_clients)

            var image;
            try {
                image = Config.CdnBaseURL + node[Config.lang].list_of_media[0].data["3x1_large"].url;
            } catch (e) {
                image = Config.placeholder["3x1_large"];
            }

            returnVal.push({
                type: 'hero',
                data: image
            }, {
                type: 'content',
                data: tools
            });

            returnVal.push({
                type: "clientGrid",
                data: clientData
            })

            return returnVal;
        }
    };
}();
module.exports = DetailTransform;