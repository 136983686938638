 /** @jsx React.DOM */
 var React = require('react');
 var HoverMixin = require('../mixins/HoverMixin');
 var TileMixin = require('../mixins/TileMixin');
 var ModalVideoActionCreator = require('../../actions/ModalVideoActionCreator');
 var TileTokens = require("./TileTokens");
 //----------- <Grid><Video> ------------//
 var Video = React.createClass({
     mixins: [HoverMixin, TileMixin, require('../mixins/MobileHoverMixin')],
     getInitialState: function() {
         return {
             arWidth: 3,
             arHeight: 2,
             videoCanShow: false,
             mobileVideoStyle: {
                 position: 'absolute',
                 width: '100%',
                 height: '100%',
                 opacity: 0,
                 zIndex: 100000
             }
         };
     },
     actuallyFadeIn: function() {
         if (this.state.hoverState) {
             this.setState({
                 videoCanShow: true
             });
         }
     },
     openModal: function(n) {

         ModalVideoActionCreator.openModalVideo(this.props.data.carousel[0].videoURL, this.props.data.id, this.props.data.type);

     },
     gotoDetailPage: function(n){
        if(!this.props.data.externalURL){
        window.location = "/" + Config.lang + "/" + this.props.data.type.replace('bbdok', 'bbdo-knows').replace('bbdou', 'bbdo-u') + "/" + this.props.data.id;
    } else {
        window.open(this.props.data.externalURL);
    }
     },
     resetVideo: function() {
         TweenLite.to(this.refs.video.getDOMNode(), 0.5, {
             opacity: "0.0"
         });
     },
     componentDidUpdate: function(prevProps, prevState) {
         if (prevState.hoverState && !this.state.hoverState) {
             this.setState({
                 videoCanShow: false
             });
         } else if (!prevState.hoverState && this.state.hoverState && this.refs.video) {
             this.refs.video.getDOMNode().oncanplay = this.actuallyFadeIn;
         }
     },
     setHoverFalseSoon: function(e) {
         TweenLite.delayedCall(0.2, this.setHoverFalse);
        if(this.refs.video){
         TweenLite.to(this.refs.video.getDOMNode(), 0.2, {
             opacity: 0
         });
        }
     },
     render: function() {
         var imageStyle = this.getTileStyles();
         var sizeableStyle = this.getTileStyles();
         var TokenModule = '';
         var title = this.props.data.title || this.props.data[Config.lang].title;
         var source = this.props.data.source || this.props.data[Config.lang].source;
         var campaign = this.props.data[Config.lang].campaign;

         var outBound = <div></div>;
         sizeableStyle.backgroundColor = "white";
         appendedStyleObj = this.props.styleObj;
         appendedStyleObj.overflow = "hidden";
         imageStyle.width = sizeableStyle.width;
         imageStyle.height = this.getHeightWithActualRatio();
         imageStyle.position = 'absolute';
         imageStyle.top = 0;
         imageStyle.left = 0;
         var classString = "video item";
         var hasVideo = this.props.data.previewVideo !== undefined;
         
         var showVideo = !Config.mobileAgent && (Config.msieversion() !== 9) && this.props.windowWidth > Config.mobileBreak && this.state.hoverState;
         
         var videoTag = "";
         var paramObject = {
             id: this.props.data.id,
             showModal: true
         };
         var opacityValue = this.state.videoCanShow ? 1 : 0;
         var videoDivStyle = {
             opacity: opacityValue,
             height: sizeableStyle.height,
             transition: "opacity .2s",
             top: "0",
             overflow: "hidden",
             position: 'absolute'
         };
        var windowTarget = this.props.data.externalURL ? "_blank" : "_self";
            if(windowTarget === "_blank"){
                outBound = <span className="outbound-link-icon"></span>;
            }
         var videoStyle = {
             top: 0,
             width: sizeableStyle.width,
             height: this.getHeightWithActualRatio()
         }
         if (this.state.hoverState === true) {
             classString += ' title-overlay-active';
         }
         if (this.props.data.tokens) {
             TokenModule = <TileTokens tokenTypes={this.props.data.tokens} />;
         }
         if (showVideo && hasVideo) {
             videoTag = <div style={videoDivStyle}>
                <video style={videoStyle} loop="true" autoPlay="true" muted="muted" id="bgvid" src={this.props.data.previewVideo} ref="video"></video>
            </div>
         }

         var ret;
         ret = (<li className='gridItemTop' style={appendedStyleObj}>
            <div onClick={hasVideo ? this.openModal : this.gotoDetailPage} params={paramObject} onMouseEnter={this.setHover} onMouseLeave={showVideo ? this.setHoverFalseSoon : this.setHoverFalse} ref="item">
         
                <div ref="sizeable" style={sizeableStyle} className={classString} >       
                    <img style={imageStyle} src={this.props.data.homeImage}></img>
                    {videoTag}
                    {TokenModule}     
                    <div className="video-title-overlay">
                        <h3>{this.props.data.type === 'bbdok' 
                            && this.props.data[Config.lang].list_of_authors.length 
                            ? 'By ' + this.props.data[Config.lang].list_of_authors.join(', ') + '/' + source 
                            : (this.props.data.type === 'work' ? campaign : source)}</h3>
                         <h2>{title}</h2>
                         {outBound}
                    </div>
                </div>
            </div>
            </li>)
         return ret;
     }
 });
 //----------- <Grid></Video> ------------//
 module.exports = Video;
