/** @jsx React.DOM */
 var React = require('react');
 var Translator = require('../../utils/Translator.js');

var RelatedTags = React.createClass({
    mixins:[require('../mixins/AnimationMixin')],
    stripSpaces: function(str){
            return str.trim();
    },
    render: function(){
       var i18n = Translator.getInstance();
    	 var returnValue = <div/>;
    	 var tags = '';
         var self = this;
    	if(this.props.data && this.props.data.length > 0){
    		tag = this.props.data.map(function(result,i){
    			var link = "/" + Config.lang + "/search/" + self.stripSpaces(result.name);
    			return <a key={i} href={link}>{result.name}</a>;
    		});
    		returnValue = (
    			<div className="tags">
	           		<h3>{
                         // TRANSLATORS: "Filed under" on single article bottom
                        i18n.gettext('Filed under:')}</h3>
	           		<div className="tag-list">
	           		{tag}
	           		</div>
	           	</div>
          	);
    	}

      return (
		returnValue
      );
    }
});

module.exports = RelatedTags;
