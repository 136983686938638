 /** @jsx React.DOM */
var React = require('react');
var SearchActionCreator = require('../actions/SearchActionCreator');
var SearchStore = require('../stores/SearchStore');
var Grid = require('./Grid');
$ = require('jquery');
var Translator = require('../utils/Translator.js');
var SearchPage = React.createClass({
    contextTypes: {
        router: React.PropTypes.func
      },
    getInitialState : function (){
        return{
          recieved: false,
          tokens: [],
          data: []
        };
      },
    componentDidMount: function(){
    	SearchStore.clearTokens();
    	
        SearchStore.addTokenChangeListener(this._onTokenChange);
        SearchStore.addChangeListener(this._onChange);
      	if(this.context.router.getCurrentParams().tags){
      		 var tags = this.context.router.getCurrentParams().tags.split("+");
			if(tags && tags.length > 0){
     			tags.map(function(result){
     				//console.log("ADD ",result)
     				SearchActionCreator.addToken(result);
     			});
     		}
     	}
    },
    _onChange: function(){
        this.setState({
            recieved: true,
            data: SearchStore.getNodes()
        });
    },
    componentWillUnmount : function(){
        SearchStore.removeChangeListener(this._onTokenChange);   
                SearchStore.removeTokenChangeListener(this._onTokenChange);

    },
     _onTokenChange: function(e){
        
        this.setState({
            tokens: SearchStore.getTokens()
        });
    },
    componentDidUpdate: function(){
    	
    	var stateObj = {  };
		  history.pushState(stateObj, null,  "/" + Config.lang + "/search/" + this.state.tokens.join("+"));
    },

     render: function() {
        var i18n = Translator.getInstance();
     	  var tags = [];
        var divSearchStyle={ paddingTop:'70px' };
        var grid = <span></span>;

        if(this.state.recieved === true){
            if(this.state.data.length > 0) {
                grid = <Grid gridData={this.state.data} gridRelated={true} windowWidth={this.props.windowWidth} gridContentType={""}/>
            } else {
                grid = <div className="no-search-results" ><h1>{
                // TRANSLATORS: Search placeholder
                i18n.gettext('No results found. Please enter another search term.')
              }</h1></div>;
            }
        }
         return (
            
            <div style={divSearchStyle} className="nav-space search-page">
                 {grid}
            </div>
        );
     }
 });
 module.exports = SearchPage;
