/** @jsx React.DOM */
var React = require('react/addons');
var Config = require('../../../Config');

var TransitionGroup = React.addons.TransitionGroup;

var generalContentItems = {

    content: require('../toolboxModules/ToolBox'),
    footnotes: require('../toolboxModules/FootNotes'),
    ShareLinks: require('../generalModules/ShareLinks.js'),
    detailHero: require('../generalModules/HeroWithText'),
    author: require('../generalModules/AuthorDescription'),
    tags: require('../generalModules/RelatedTags.js'),
    brief: require('../generalModules/BriefHeader'),
    carousel: require('../CustomSlider'),
    clientLogo: require('../clientModules/ClientList'),
    hero: require('../aboutModules/HeroImage'),
    clientGrid: require('../aboutModules/ClientGrid')

};



var GeneralContent = React.createClass({

    render: function() {

        var returnValue = [];
        var object = {};
        var dataProp = this.props.data
        var elements = <div></div>
        var self = this;
        
        if (dataProp.content) {

            elements = dataProp.content.slice(0,4).map(function(result, i) {
                var tag = generalContentItems[result.type];
                var properties = {
                    windowWidth: self.props.windowWidth,
                    componentName:result.type,
                    data:result.data,
                    delay:.5 + (i*.1),
                    key:i,
                    style:{transition:'transform 1s',
                    WebkitTransition: 'transform 1s',color:'red'}
            
                 };
                
               return React.createElement(tag, properties);
            });
        }
        return (
            <div>{elements}</div>
        );
    }
});

module.exports = GeneralContent;

