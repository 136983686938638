var AppDispatcher = require('../dispatcher/AppDispatcher');
var ServerConstants = require('../constants/ServerConstants');
var PeopleTransform = require('../transforms/PeopleTransform');
var CareersTransform = require('../transforms/CareersTransform');
var DepartmentsTransform = require('../transforms/DepartmentsTransform');

var ServerActionCreators = {

    receiveAbout: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_ABOUT_NODES",
            rawNodes: rawNodes.data
        });
    },
    receiveLocations: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_LOCATION_NODES",
            rawNodes: rawNodes
        });
    },
    receiveAll: function(nodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_NODES",
            nodes: nodes
        });
    },

    receiveCannes: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_CANNES_NODES",
            rawNodes: rawNodes.data
        });
    },
    receiveDetails: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_DETAIL_NODES",
            rawNodes: rawNodes
        });
    },

    receiveClientDetails: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_CLIENT_NODES",
            rawNodes: rawNodes.data
        });
    },
    receiveSearchTags: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_SEARCH_TAGS_NODES",
            rawNodes: rawNodes.data
        });
    },
    receiveSearchNodes: function(rawNodes) {
        AppDispatcher.handleServerAction({
            type: "RECEIVE_SEARCH_NODES",
            rawNodes: rawNodes
        });
    },
    receivePeopleData: function(data) {
        AppDispatcher.handleServerAction({
            type: ServerConstants.RECEIVE_PEOPLE_DATA,
            data: PeopleTransform.createPeopleData(data)
        });
    },
    receiveContactData: function(data) {
        AppDispatcher.handleServerAction({
            type: 'RECEIVE_CONTACT_DATA',
            data: data
        });
    },

    receiveCareersData: function(data){
        AppDispatcher.handleServerAction({
            type: ServerConstants.RECEIVE_CAREERS_DATA,
            data: CareersTransform.createData(data)
        });
    },

    receiveDepartmentsData: function(data){
        AppDispatcher.handleServerAction({
            type: ServerConstants.RECEIVE_DEPARTMENTS_DATA,
            data: DepartmentsTransform.createData(data)
        });
    },

    receiveJobsData: function(data){
        AppDispatcher.handleServerAction({
            type: ServerConstants.RECEIVE_JOBS_DATA,
            data: JobsTransform.createData(data)
        });
    },
    
}
module.exports = ServerActionCreators;