   /** @jsx React.DOM */

var React = require('react');    
var BriefHeader = React.createClass({
  mixins:[require('../mixins/AnimationMixin')],
   	render: function(){
      var awardsList = <span />;
      if(this.props.data.awards){
        var awards = this.props.data.awards.map(function(result){
          return <li>{result}</li>;
        });
        awardsList = <div className="awards-in-brief">Awards:<ul>{awards}</ul></div>
      }
        return(
          <div className="content-block-header">
              <div className="section-head">
                  <span className="client">{this.props.data.client.name}</span>
                  <h2>{this.props.data.campaign.name}</h2>
                  <span className="office">{this.props.data.company.name}</span>
                 {awardsList}
              </div>
          </div>
      	);
    }
});

module.exports = BriefHeader;