var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var BBDOAPIUtils = require('../utils/BBDOAPIUtils.js');
var _ =require("lodash");

var CHANGE_EVENT = 'change';

var _nodes = [];
var _data = {};
var _menuState = false;
var _menuType = 'main';
var ShareStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },
  getMenuState : function(){
      return _menuState;

  },
  getMenuType : function(){
      return _menuType;
  }
});


ShareStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {

    case "SET_MENU_STATE":
      _menuState = !_menuState;
      ShareStore.emitChange();
      break;
    case "SET_MENU_TYPE":
      _menuType = action.menu;
      ShareStore.emitChange();
    default:
      // do nothing
  }

});

module.exports = ShareStore;