 /** @jsx React.DOM */
 var progressBar = require('./mixins/ProgressBarMixin.js');
 var ModalVideoActionCreator = require('../actions/ModalVideoActionCreator');
 var LightboxActionCreator = require('../actions/LightboxActionCreator');
var React = require('react');
 var Slider = require('react-slick');  
 var $ = require('jquery');

//----------- <Grid><CaseStudy> ------------//
var CustomSlider = React.createClass({
  _moveCount: 0,
  _lastY: 0,
  _count: 0,
  componentDidMount : function(){
		progressBar.init();
    $(this.refs.theSlider.getDOMNode()).on('swipe',this.negateOnTouchEndOnce);
	},
	handleProgressBarClick : function(e){ 
		progressBar.clickBar(e);
	},
  openModal: function(n){
    ModalVideoActionCreator.openModalVideo(this.props.data[n].videoURL);
  },

  openLightbox: function(n){
    if (this.props.data[n].hasLightbox){
      LightboxActionCreator.open(this.props.data[n].originalImgURL);
    }
  },
  openLightboxOnEnd: function(n, event){

    if(this._moveCount < 3 && event.button == 0){  
      this.openLightbox(n);
    } else {
      this._moveCount = 0;
    }
  },
  openModalOnEnd: function(n){

    if(this._moveCount < 3){  
      this.openModal(n);
    } else {
      this._moveCount = 0;
    }
  },
  startTrackingMouseMove: function(n){
    this._moveCount = 0;
  },
  cancelOpenModal:function(e){
    this._moveCount++;

  },
  touchMoveVideo: function(e){
    var self = this;
    self.cancelOpenModal();
    self.touchMove(e);
  },
  touchMove : function(e){
     //console.log('Y', event.touches[0].pageY, 'X', event.touches[0].pageX);
     var currentY = event.touches[0].clientY;
    // console.log(event)
       // if(currentY > this._lastY){
       //  console.log('Y', currentY, 'SCROLL', document.body.scrollTop, "this._COUNT", this._count);
       //  lastScroll = document.body.scrollTop - _lastY;
       //   document.body.scrollTop = lastScroll;
       // }else{
       //    console.log('Y', currentY, 'SCROLL', document.body.scrollTop, "this._COUNT", this._count);
       //    lastScroll = document.body.scrollTop + 2;
       //    document.body.scrollTop = lastScroll;
       // }
       // document.body.scrollTop += this._lastY - currentY;
       // this._lastY = currentY;
    if(this.props.data.length === 1){
      e.stopPropagation();
      // console.log(currentY);
 
    }

  },
  render: function(){
  	var slides;
    var settings = {
    		dots: true,
    		infinite: (this.props.data || []).length > 1 ? true : false,
    		draggable: (this.props.data || []).length > 1 ? true : false,
        speed: 500,
    		slidesToShow: 1,
    		slidesToScroll: 1,
        vertical: true,
        afterChange: function(index){ progressBar.init(index);   },
       
    };
  	var self = this;
  	slides= this.props.data.map(function(result, i){
      if(result.type === "video_link") { result.type = "video"; }
  		switch(result.type){
  			case "image":
  				return(
  					<div  key={i} 
                  className={ result.hasLightbox ? 'has-lightbox' : '' }
                  onTouchMove={self.touchMove}
                  onMouseDown={self.startTrackingMouseMove.bind(null,i)}
                  onMouseUp={self.openLightboxOnEnd.bind(null,i)} 
                  onMouseMove={self.cancelOpenModal}>
  						<div  className="image-slide-wrapper">
                <img src={result.imageURL} alt={result.alt}  />
              </div>
  					</div>
  				);
  			break;
  			case "video":
      
          return(   
            <div  key={i} 
                  onTouchEnd={self.openModalOnEnd.bind(null,i)} 
                  onTouchMove={self.touchMoveVideo} 
                  onMouseDown={self.startTrackingMouseMove.bind(null,i)} 
                  onMouseUp={self.openModalOnEnd.bind(null,i)} 
                  onMouseMove={self.cancelOpenModal}>
                    <div className="image-slide-wrapper slider-video">
                      <img src={result.imageURL} alt={result.alt}  />
                      <span   className="play-button" ></span>
                    </div>
            </div>
         );
  			break;
  		}
  	});
      return( 
      	<div className="media-block">
          <div></div>
      		<Slider ref="theSlider" {...settings}>
      		{slides}
		     </Slider>
		<div className="progress-contain">
			<div className="clickable" onClick={this.handleProgressBarClick}></div>
			<div className="progress-bar"></div>
		</div>
	</div>
       );
    }
});
//----------- <Grid></CaseStudy> ------------//


module.exports = CustomSlider;
