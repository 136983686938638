/** @jsx React.DOM */
var React = require('react');    
var CountryAgencyList = require('./CountryAgencyList');
var LocationMobileHQ = require('./LocationMobileHQ');
var _ =require("lodash");
var LocationList = React.createClass({
	getInitialState: function(){
			return {
				selectedIndex: 0,
				shouldExpand: 5,
				shouldCountryExpand: 'none'
			}
		},
		componentDidUpdate : function(prevProps, prevState) {
			if(this.props.regionIndex != prevProps.regionIndex) {
				this.setState({
					shouldExpand : this.props.regionIndex
				});
			}
		},
    componentDidMount: function() {
        window.addEventListener('scroll', this.onScroll, false);
    },
    componentWillUnmount: function() {
        window.removeEventListener('scroll', this.onScroll, false);
    },
    onAgencySelect: function(n){
			this.setState({
				selectedIndex : n
			});
		},
	onCountrySelect: function(n){
		this.setState({
			shouldCountryExpand : n
		});
	},
	handleClick : function(n, e){
		this.props.onUserSelect(n);

	},
	render: function(){
		var self = this;
		var data = <div></div>;
		var dataTwo = <div></div>;
		var dataThree = <div></div>;
		var self = this;
		var style = { };
	      if(this.props.hide){
	          style.display = "none";
	      }else{
	          style = {};
	      }
		data = this.props.locationData.countries.map(function(result, index){
			return(
				<CountryAgencyList isMobile={true} key={result.name} countryExpand={self.onCountrySelect} selectedCountry={this.state.shouldCountryExpand} selectedIndex={this.state.selectedIndex} onAgencySelect={self.onAgencySelect} agencyData={result.agency}  countries={result.name} />
			);
		}.bind(this));
		var divisibleByN = data.length / this.props.columns ;
		var nColumns = _.chunk(data, divisibleByN );
		var regionsArray = [
		{
			region:"North America",
			index:5
		},{
			region:"Latin America",
			index: 3
		},{
			region:"Europe",
			index: 2
		},{
			region:"Middle East & Africa",
			index: 4
		},{
			region:"Asia",
		 	index: 0
		},{
			region:"Australia & New Zealand",
			index:1
		}];
		var returnedElements = [];
		for(var i = 0;i<regionsArray.length;i++){
			if(!this.props.isSearch){
				returnedElements.push(<div  ref="some"  className={this.props.regionIndex=== regionsArray[i].index ?"mobile-regions mobile-active":"mobile-regions"}  onClick={this.handleClick.bind(null, regionsArray[i].index )}>{regionsArray[i].region}</div>);
				returnedElements.push(<div ref={this.state.shouldExpand===i?"colActive":" "} className={this.state.shouldExpand===regionsArray[i].index?"region-col region-col-active":"region-col"}> <div className={"location-col"}>{this.props.regionIndex === regionsArray[i].index ? nColumns : ""}</div> </div>);
			}
		}
		if(this.props.isSearch){
			returnedElements.push(<div className='location-col search-active' >{nColumns}</div>);
		}
		return(
			<div className="location-grid">
				<div className="" style={style}>
   					{returnedElements}
  				</div>
  			</div>
		);
	}
});

module.exports = LocationList;