 /** @jsx React.DOM */
var React = require('react');
var HoverMixin = require('../mixins/HoverMixin');
var TileMixin = require('../mixins/TileMixin');
  //----------- <Grid><Tweet> ------------//

        var Tweet = React.createClass({
mixins: [HoverMixin,TileMixin, require('../mixins/MobileHoverMixin')],
    getInitialState: function() {

        return {

            arWidth: 2,
            arHeight: 1,

        };
    },
            render: function(){
                        var sizeableStyle = {
                    width: this.calculateNBox(this.state.arWidth,this.props.tileUnit)/16 + "em",
                    height: this.calculateNBox(this.state.arHeight,this.props.tileUnit)/16 + "em",
        };
       
                return(
                    <li className='gridItemTop' style={this.props.styleObj}>
                    <a href={this.props.data.url} target='_blank'>
                    <div  ref="sizeable" style={sizeableStyle} className="item tweet tweetmobile" onMouseEnter={this.setHover} onMouseLeave={this.setHoverFalse} >
                    <span className="tweet-tag">{this.props.data.handle}</span>
                    <span className="tweet-body">{this.props.data.tweet}</span>
                    </div>
                    </a>
                    </li>
                    );
            }
        });
        //----------- <Grid></Tweet> ------------//

module.exports = Tweet; 