
//Determines the Path and then forwards that data to the Dispatch//

var AppDispatcher = require('../dispatcher/AppDispatcher');

module.exports = {

  setMenuState: function() {
    AppDispatcher.handleViewAction({
      type: "SET_MENU_STATE",
    });
  },
  setMenuType: function(menu) {
    AppDispatcher.handleViewAction({
      type: "SET_MENU_TYPE",
      menu: menu
    });
  }

  
};