/** @jsx React.DOM */
 var React = require('react/addons');

var TransitionGroup = React.addons.TransitionGroup;
var FootNotes = React.createClass({
    mixins:[require('../mixins/AnimationMixin')],
    render: function(){
    	var returnValue = null;
      var divStyle = null;
    		returnValue = (
    		  <section className="footnotes">
                    <ol>
                      <li>J Holler, V Tsiatsis C Mulligan, S/ Karnouskos, S Avesand, D. Boyle: From Machine-toMachine to the Internet of THings: Introduction to a New Age of Intelligence. Elsevier, 2014 ISBN 978-0-12-407684-6.</li>
                      <li>J Holler, V Tsiatsis C Mulligan, S/ Karnouskos, S Avesand, D. Boyle: From Machine-toMachine to the Internet of THings: Introduction to a New Age of Intelligence. Elsevier, 2014 ISBN 978-0-12-407684-6.</li>
                      <li>J Holler, V Tsiatsis C Mulligan, S/ Karnouskos, S Avesand, D. Boyle: From Machine-toMachine to the Internet of THings: Introduction to a New Age of Intelligence. Elsevier, 2014 ISBN 978-0-12-407684-6.</li>
                      <li>J Holler, V Tsiatsis C Mulligan, S/ Karnouskos, S Avesand, D. Boyle: From Machine-toMachine to the Internet of THings: Introduction to a New Age of Intelligence. Elsevier, 2014 ISBN 978-0-12-407684-6.</li>
                      <li>J Holler, V Tsiatsis C Mulligan, S/ Karnouskos, S Avesand, D. Boyle: From Machine-toMachine to the Internet of THings: Introduction to a New Age of Intelligence. Elsevier, 2014 ISBN 978-0-12-407684-6.</li>
                   </ol>
          	   </section>
          	);
      return (
		returnValue
      );
    }
});

module.exports = FootNotes;