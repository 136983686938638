var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require("object-assign");
var _ = require("lodash");

var CHANGE_EVENT = 'change';



var _isOpen = false;
var _imageURL;

var LightboxStore = assign({}, EventEmitter.prototype, {

  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(listener){
    this.removeListener(CHANGE_EVENT,listener)
  },

  isOpen: function(){
    return _isOpen;
  },
  getImage: function(){
    return _imageURL;
  }

});

LightboxStore.dispatchToken = AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.type) {
    
    case "OPEN_LIGHTBOX":
      _imageURL = action.imageURL;
      _isOpen = true;
      LightboxStore.emitChange();
      break;
    case "CLOSE_LIGHTBOX":
      _isOpen = false;
      LightboxStore.emitChange();

    default:
      // do nothing
  }

});

module.exports = LightboxStore;
