var Jed = require('jed');
var Config = require('../../Config');

var _translator;
var _currentLanguage;

var Translator = {
	getInstance: function(){
		if (!_translator || !_currentLanguage) {
			this.changeLanguage(Config.lang);
		}
		return _translator;
	},

	changeLanguage: function(language){
		if (!language) return;
		try {
			_currentLanguage = language;
			_translator = new Jed(Config.translationsData[language]);
		} catch (error) {
			console.log(error);
		}
	}
}


module.exports = Translator;