module.exports = {

	textToHtmlNewLines: function(value){
		return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
	},

	stripHTML: function(value){
		// Replace tags with spaces, than trim redundant spaces
   		return value.replace(/<\/?[^>]+(>|$)/g, ' ').replace(/\s\s+/g, ' ');
	}

};