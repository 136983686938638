/** @jsx React.DOM */
var React = require('react');
var MenuActionCreator = require('../actions/MenuActionCreator');
var NavigateMixin = require('./mixins/NavigateMixin');
var HoverMixin = require('./mixins/HoverMixin');
var Cannes = require('./Cannes');
var Router = require('react-router');
var ParallaxStore = require('../stores/ParallaxStore');
var Search = require('./generalModules/Search');
var TokenContainer = require('./generalModules/TokenContainer');
var CannesNavMenu = require('./CannesPageModules/CannesNavMenu');
var Config = require('../../Config');
var $ = require('jquery');
var Link = Router.Link;
var Translator = require('../utils/Translator.js');

 var DesktopMenu = React.createClass({
     contextTypes: {
         router: React.PropTypes.func.isRequired
     },
     mixins: [NavigateMixin, HoverMixin],
     getInitialState: function() {
         return ({
             scrollPosition: 0
         });
     },
     componentDidMount: function() {
         ParallaxStore.addChangeListener(this._onChange);
     },
     componentWillUnmount: function() {
         ParallaxStore.removeChangeListener(this._onChange);
     },
     _onChange: function() {

         var newY = window.scrollY;
         if (newY !== this.state.scrollPosition) {
             this.setState({
                 scrollPosition: newY
             });
         }
     },
     showMenuContents: function() {
         if (this.props.windowWidth > 640) {
             var nav = this.refs.mainNav.getDOMNode();
             $(nav).find("ul").css("display", "block");
             TweenLite.to($(nav).find("ul"), 0.5, {
                 opacity: "1"
             });
             TweenLite.to($(nav).find(".logo-block"), 0.2, {
                 opacity: "1"
             });
             $('.search').find('span').removeAttr('style');
             $('.search').css("overflow", "visible");
             $('.header-wrap').css("overflow", "visible");


         }
     },
     showMenu: function() {
         if (this.props.windowWidth > 640) {
             var nav = this.refs.mainNav.getDOMNode();
             TweenLite.delayedCall(0.2, this.showMenuContents);
             TweenLite.to(nav, 0.3, {
                 height: "60px"
             });
             TweenLite.to($('.search'), 0.3, {
                 height: "60px"
             });
         }
     },
     getNav: function() {
         if (!this.nav) {
             this.nav = this.refs.mainNav.getDOMNode();
         }
         return this.nav;
     },
     getLangMenu: function(){
        if (Config.availableLanguages.length <= 1) return null;    

        var items = [];
        var langs = Config.availableLanguages;

        for (var i = 0 ; i < langs.length; i++){
            if (langs[i] == Config.lang) continue;
            items.push(<a key={i} href={"/" + langs[i]}>{langs[i]}</a>);
        }

        if (this.props.windowWidth > Config.mobileBreak) {
            return (
                <div className="dropdown">
                    <button>{Config.lang}<span className="caret"></span></button>
                    <div className="dropdown-menu">
                       {items}
                    </div>
                </div>
            );
        } else {
            return [];
        }
     },
     hideMenuContents: function() {
         if (this.props.windowWidth > Config.mobileBreak) {
             $(this.getNav()).find("ul").css("display", "none");
             $('.search').find('span').css("backgroundImage", "none");
         }
     },
     hideMenu: function() {
         if (this.props.windowWidth > Config.mobileBreak) {
            $('.header-wrap').css("overflow", "hidden");
            $('.search').css('overflow', 'hidden');
             TweenLite.killDelayedCallsTo(this.showMenuContents);
             var nav = this.refs.mainNav.getDOMNode();

             TweenLite.to(nav, 0.2, {
                 height: "10px",
                 onComplete: this.hideMenuContents
             });
             TweenLite.to($(nav).find(".logo-block"), 0.2, {
                 opacity: "0"
             });
             TweenLite.to($(nav).find("ul"), 0.2, {
                 opacity: "0"
             });
             TweenLite.to($('.search'), 0.2, {
                 height: "10px"
             });
         }
     },
     handleClick: function(filter) {
         MenuActionCreator.selectFilter(filter);
     },
     handleMouseEnter: function(e) {
         var nav = document.getElementById("mainNav");
         //if( this.props.cannesState >0 ) {
         this.showMenu();
         //}
     },
     handleMouseLeave: function(e) {
         var nav = document.getElementById("mainNav");
         if (this.state.scrollPosition >= 5) {
             this.hideMenu();
         }
     },
     componentDidUpdate: function(prevProps, prevState) {
         if (this.state.scrollPosition !== prevState.scrollPosition) {
             if (this.state.scrollPosition < prevState.scrollPosition || prevState.scrollPosition <= 0) {
                 this.showMenu();
             } else {
                 this.hideMenu();
             }
         }
     },
     render: function() {
         var i18n = Translator.getInstance();
         var cannesSize = "small";
         var cannesVisible = false;
         var CannesNav = <div></div>;
         var CannesFilterMenu = <div></div>;
         var cannes = <div></div>;
         var TokenContain = <div></div>;

         var subPage =  this.context.router.getCurrentPath().split("/")[2];
        if(subPage === "search"){
            TokenContain = <TokenContainer />
        }

         if (this.props.windowWidth > 640) {
             cannes = <Cannes cannesName="MenuCannes" cannesVisible={cannesVisible} cannesSize={cannesSize} />;
         }
         if (this.props.cannesNavMenuVisible === true) {
             CannesNav = <CannesNavMenu shouldFilterNews={subPage!==Config.privateCannesString} mobile={false} delay={1} />;
         }
         return (<div className="header-wrap">
                <div className="header" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} ref="mainNav" >
                    <div className="logo-block">
                        <Link to="main" params={{lang: Config.lang}}>
                            <h1>
                                <img className="desktop-logo" src="/assets/images/BBDO_logo_white.svg" alt="BBDO WorldWide" />
                                <img className="mobile-logo"  src="/assets/images/EnergyBBDO.svg" alt="BBDO WorldWide" />
                            </h1>
                        </Link>
                    </div>
                    <div className="main-nav">
                        <ul>
                            
                            <li><Link to="work" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('The Work')
                            }</Link></li>
                            <li><Link to="news" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('News')
                            }</Link></li>
                            {/*<li><Link to="knowledge" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('Knowledge')
                            }</Link></li>*/}
                            <li><Link to="about" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('About')
                            }</Link></li>
                            {/*<li><Link to="people" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('People')
                            }</Link></li>*/}
                            {/*<li><Link to="careers" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('Careers')
                            }</Link></li>*/}
                            <li><Link to="contact" params={{lang: Config.lang}}>{
                                // TRANSLATORS: Main navigation item
                                i18n.gettext('Contact')
                            }</Link></li>
                            
                        </ul>
                        <div className="side-nav">
                            {this.getLangMenu()}
                        </div>
                    </div>
                    <Search prevPath={this.props.prevPath} windowWidth={this.props.windowWidth} />
                </div>
                {TokenContain}
                {CannesNav}
                </div>);
     }
 });
 module.exports = DesktopMenu;