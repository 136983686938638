 /** @jsx React.DOM */
 var React = require('react');
 var SearchActionCreator = require('../../actions/SearchActionCreator');
 var SearchStore = require('../../stores/SearchStore');
 var $ = require('jquery');
 var mobileTokenContainer = React.createClass({
     getInitialState: function() {
         return {
             tokens: this.props.initialTags || []
         };
     },
     componentDidMount: function() {
         SearchStore.addTokenChangeListener(this._onTokenChange);
     },
     componentWillUnmount: function() {
         SearchStore.removeTokenChangeListener(this._onTokenChange);
         this.removeAll();
     },
     _onTokenChange: function() {
         this.setState({
             tokens: SearchStore.getTokens()
         });
     },
     componentDidUpdate: function(prevProps, prevState) {
         if (prevState.tokens !== this.state.tokens && this.state.tokens !== 0) {
             this.setState({
                 filterOpen: true
             });
         }
            this.adjustMobileHeader();
                  
     },
     adjustMobileHeader: function() {
         var mobileHeader = document.getElementsByClassName('mobile-header')[0];
         var headerTop;
         var bool = true;
         if (mobileHeader && this.state.filterOpen) {
             headerTop = document.getElementsByClassName('mobile-header')[0].getBoundingClientRect().top;
             var paddingTop = 65 + (this.state.tokens.length * 85) + 'px';
             $('.search-page').css('padding-top',paddingTop);
             bool = true;
        
         } else if(mobileHeader && !this.state.filterOpen) {
              headerTop = document.getElementsByClassName('mobile-header')[0].getBoundingClientRect().top;
             var paddingTop = 100 + 'px';
             $('.search-page').css('padding-top',paddingTop);
             bool = false;
         }
     },
     removeMe: function(token) {
        
         SearchActionCreator.removeToken(token);
         if($('.search-input').is(":focus")=== false){

            $('.search-input')[0].focus();
         }
     },
     removeAll: function() {
         tokens = this.state.tokens;
         if (tokens.length > 0) {
             for (var i = tokens.length - 1; i >= 0; i--) {
                 this.removeMe(tokens[i]);
             }
         }
     },
     filterClick: function(e) {
         if (this.state.filterOpen) {
             this.setState({
                 filterOpen: false
             });
         } else {
             if (this.state.tokens.length > 0) {
                 this.setState({
                     filterOpen: true
                 });
             }
         }
     },
     render: function() {
         var tokens = '';
         var dynamicClass = "token-wrap";
         var self = this;
         if (this.state.tokens.length > 0) {
             tokens = this.state.tokens.map(function(result) {
                 return (<li onClick={self.removeMe.bind(null,result)}><span>{result}</span><span className="close"></span></li>);
             });
         }
         var divHeight = "35px";
         var dynamicStyle = {
             height: divHeight
         };
         if (this.state.filterOpen === true) {
             dynamicClass += " token-wrap-active";
             dynamicStyle = {
                 height: this.state.tokens.length * 85 + "px"
             };
         }
         return (<div className="token-container-mobile">
                <div className={dynamicClass} style={dynamicStyle} >
                  <div className="token-filter" onClick={this.filterClick} >
                      <span className="arrow-right"></span><span>Search terms: </span>
                  </div>
                    <ul>
                      {tokens}
                    </ul>
                </div>
            </div>);
     }
 });
 module.exports = mobileTokenContainer;
