 /** @jsx React.DOM */

var React = require('react');
var MobileList = require('./MobileMenuModules/MobileList');
var ShareActionCreator = require('../actions/ShareActionCreator');

 var MobileMenu = React.createClass({
 		getInitialState: function() {
		return {
    	closeMenu: false
    };
  },
 	closeMobileMenu : function(){
 		ShareActionCreator.setMenuState();
 	},
 	componentDidUpdate: function (prevProps, prevState){
 		var self = this;
 		/*if(prevProps.menuState !== this.props.menuState){
 			if(this.props.menuState === true){
 			var lastY;
 			var lastX;
 			var debounce;
 			var count = 0;
				$(document).bind('touchmove', function (e){
   				 	var currentY = event.touches[0].clientY;
   				 	var currentX = event.touches[0].clientX;
    				if (currentY !== lastY){
       			 // moved vertically
        			e.preventDefault();
    			}
    			if(currentX !== lastX){
    				count++;
    				window.clearTimeout(debounce);
    				debounce = window.setTimeout(function(){ count = 0; }, 6);
    				
    				if(count === 800){
    					self.closeMobileMenu();
    					count = 0;
    				}
    			}
    			lastY = currentY;
    			lastX = currentX;
    			
			});
 			}else{
 					$(document).unbind('touchmove');
 			}
 		}*/
 	},
    getLangMenu: function(){  
        if (Config.availableLanguages.length <= 1) return null;    


        var items = [];
        var langs = Config.availableLanguages;

        for (var i = 0 ; i < langs.length; i++){
     
                langs[i] == Config.lang 
                ?  items.push(<a key={i} className="active" href={"/" + langs[i]}>{langs[i]}</a>)
                : items.push(<a key={i} href={"/" + langs[i]}>{langs[i]}</a>)            
        }

        
            return (
                <div className="language-menu">
                   {items}
                </div>
            );
      
     },
 	render: function(){

 		var menuList = <MobileList menuType={this.props.menuType} closeMenu={this.closeMobileMenu} currentMenuState={this.props.menuState} />
 		var menuClass = "mobile-nav";
 		if(this.props.menuState === true){
 			menuClass += " active-menu";
 		}
 		if(this.props.menuType === "share"){
 			menuClass += " share-menu-active"
 		}
 		return(
 					
		<section className={menuClass}>
			<div>
				{menuList}
			</div>
            
            
            <div className="controls">
                {this.getLangMenu()}
                <span ref="closeMenu" onClick={this.closeMobileMenu} className="mobile-nav-close"></span>
            </div>
		</section>
 		);
 	}


 });

 module.exports = MobileMenu;