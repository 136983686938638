var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var FeedTransform = require('../transforms/Transforms').FeedTransform;
var Interleaver = require('../transforms/Interleaver');

var _ = require("lodash");
var CHANGE_EVENT = 'change';
var TOKEN_CHANGE_EVENT = 'token_change';
var BBDOAPIUtils = require('../utils/BBDOAPIUtils.js');
var _selected = -1;
var _filter = "all";
var _loading = false;
var _noNodes = false;
var name="FeedStore";
var _offset = 0;
var _sort = "";
var _originalNodes = [];
var _sortedNodes = [];
var _tokens = [];
var AuthStore = require('./AuthStore');
var _quiet = false;
var Config = require('../../Config');
var FeedStore = assign({}, EventEmitter.prototype, {
    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },
    getName: function(){
      return name;
    },
    emitTokenChange: function(){
        this.emit(TOKEN_CHANGE_EVENT);
    },
    getNodes: function() {

        if (_originalNodes.length === 0 && !_loading && !_noNodes) {
            this.getNewNodes();
        }
        return _sort ? _sortedNodes : _originalNodes;
    },
    sortNodes: function() {
        _sortedNodes = [];
        if (_sort === "awards" || _sort === "category") {
            _sortedNodes = FeedTransform.sortByAwardFilter(_sort, _originalNodes);
        } else if (_sort === "client") {
            _sortedNodes = FeedTransform.sortByClient(_originalNodes);

        } else if (_sort === "agency") {
            _sortedNodes = FeedTransform.sortByAgency(_originalNodes);
        } else if (_sort === "news") {
            _sortedNodes = FeedTransform.sortByType("news", _originalNodes);
        }
    },
    getMoreAvailable: function() {

        return Interleaver.moreAvailable();
    },
    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener: function(listener) {
        this.removeListener(CHANGE_EVENT, listener)
    },
     addTokenChangeListener: function(callback) {
        this.on(TOKEN_CHANGE_EVENT, callback);
    },
    removeTokenChangeListener: function(listener) {
        this.removeListener(TOKEN_CHANGE_EVENT, listener)
    },
    get: function(id) {
        return _originalNodes[id];
    },
    getSort: function() {
        return _sort;
    },
    getInterleavedFeed: function() {
        var retval;
        retval = this.getNodes();
        return retval;
    },
    getNewNodes: function() {
        _originalNodes = [];
        _noNodes = false;
        var urlRequest = Config.apiBaseURL + Config.apiFeeds + Interleaver.constructRequest(_filter,0, Config.viewportWidth < Config.mobileBreak ? 'mobile' : 'col6', AuthStore.checkUserSession().sessionID);//TODO: add mobile.
       

        BBDOAPIUtils.getAllNodes(urlRequest);
        _loading = true;
    },
    getSelected: function() {
        return _selected;
    },
    loadMore: function() {
        var urlRequest = Config.apiBaseURL + Config.apiFeeds + Interleaver.constructRequest(_filter,_offset,Config.viewportWidth < Config.mobileBreak ? 'mobile' : 'col6', AuthStore.checkUserSession().sessionID);
      
        BBDOAPIUtils.getAllNodes(urlRequest);
    },
    contentIsLoaded: function(){
      return !_loading;
    }
});
FeedStore.dispatchToken = AppDispatcher.register(function(payload) {
    var action = payload.action;
    switch (action.type) {
        case "RECEIVE_NODES":
            // all sets will be interleaved?

            var newInterleaves = Interleaver.interleave(action.nodes.data,_offset,_filter,Config.viewportWidth < Config.mobileBreak ? 'mobile' : 'col6');

            _originalNodes = _originalNodes.concat(newInterleaves);

            _originalNodes = _.uniq(_originalNodes,'id');

            _originalNodes = FeedTransform.createGridObjects(_originalNodes,_filter);

            _loading = false;
            _noNodes = _originalNodes.length > 0 ? false : true;

            if (_sort !== "") {
                FeedStore.sortNodes();
            }
            FeedStore.emitChange();
            break;
        case "SELECT_FILTER":

            _filter = action.gridType;

            _offset = 0;
            _originalNodes = [];
            _noNodes = false;
            _loading = false;
            Interleaver.reset();
/*            if (_sort !== "") {
                FeedStore.sortNodes();
            }*/
            _sort = "";
            FeedStore.emitChange();
            break;
        case "LOAD_MORE_CONTENT":
            _offset += 1;

            FeedStore.loadMore();
            break;
        case "SELECT_SORT":
            _sort = action.sortType;
            FeedStore.sortNodes();
            FeedStore.emitChange();
            break;
        default:
            // do nothing
    }
});
module.exports = FeedStore;
